import React, { useEffect, useRef } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import { Switch } from 'antd';
import antMessage from '../../../utils/extend/AntdNotification';
import systemConfig from '../../../config/systematic';
import QrCodeTypeEdit from './QrCodeTypeEdit';

export default function QrCode(props: any) {

    const ref = useRef<any>();

    /** api服务id */
    const apiId = 'qrCodeType';

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

 
   

    //表头
    const columnsHead = [
        {
            title: '二维码类型',
            dataIndex: 'qrCodeTypeName',
            // render: (text: any, record: any) =>
            //     <div>{record.voiceId}</div>
        },
        {
            title: '二维码地址',
            dataIndex: 'qrCodeUrl',
            // render: (text: any, record: any) =>
            //     <div>{record.voiceId}</div>
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '二维码类型',
        apiId,
        columns,
        // showImage: true,
        // showUploadFile: true,
        showStates: true,
        pagination: true,
        EditComponent: QrCodeTypeEdit
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
        </>
    )
}