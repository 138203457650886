import { RouteObject } from 'react-router-dom';
import CateringOrder from '../components/external/cateringOrder/CateringOrder';
import { getPageUrl } from './index';
import SalesDetail from '../components/external/financeCastReport/SalesDetail';
import PaymentMethodCount from '../components/external/financeCastReport/PaymentMethodCount';
import MakeDept from '../components/external/financeCastReport/MakeDept';
import RoomPlatformStatistics from '../components/external/financeCastReport/RoomPlatformStatistics';
import PaymentMethod from '../components/basic/general/PaymentMethod';
import PayDetails from '../components/external/cateringOrder/PayDetails';
import BillQuery from '../components/external/financeCastReport/BillQuery';
/**
 * 获取路由地址
 * @param licenseList 
 * @returns 
 */
const routes_cateringOrder = async (licenseList: Array<any>) => {
    const res: RouteObject[] = [
        {
            path: await getPageUrl(licenseList, 'cateringOrder'),
            element: <CateringOrder></CateringOrder>
        },
        {
            path: await getPageUrl(licenseList, 'payDetails'),
            element: <PayDetails></PayDetails>
        },
        {
            path: await getPageUrl(licenseList, 'paymentMethod'),
            element: <PaymentMethod></PaymentMethod>
        },
        {
            path: await getPageUrl(licenseList, 'salesDetail'),
            element: <SalesDetail ></SalesDetail >
        },
        {
            path: await getPageUrl(licenseList, 'paymentMethodCount'),
            element: <PaymentMethodCount ></PaymentMethodCount >
        },
        {
            path: await getPageUrl(licenseList, 'makeDept'),
            element: <MakeDept ></MakeDept >
        },
        {
            path: await getPageUrl(licenseList, 'billQuery'),
            element: <BillQuery ></BillQuery >
        },
        {
            path: await getPageUrl(licenseList, 'roomPlatformStatistics'),
            element: <RoomPlatformStatistics ></RoomPlatformStatistics >
        },
    ]
    return res;
};

export default routes_cateringOrder;