import React, { useEffect, useRef, useState } from 'react';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import BranchStoreMenuWarehouseSpecSpecialEdit from './BranchStoreMenuWarehouseSpecSpecialEdit';
import { Button, Popconfirm, Space } from 'antd';
import SetInventory from './inventory/SetInventory';
import systemConfig from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';

export default function BranchStoreMenuWarehouseSpecSpecial(props: any) {

    const ref = useRef<any>();

    const [inventoryOpen, setInventoryOpen] = useState<boolean>(false);

    const [inventoryData, setInventoryData] = useState<any>();
    
    const { closeModal, data } = props;

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    /** api服务id */
    const apiId = 'branchStoreMenuWarehouseSpecSpecial';

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /**库存一键设置 */
    const handleInventory = (apiData: any) => {
        const { apiId, id, columnName, value } = apiData;
        if (!id) return false

        fetchApi.PUT(wmService, {
            apiId,
            apiVariable: id,
            apiExtend: "updateOne",
            apiData: {
                columnName,
                value
            }
        }).then((res) => {
            ref.current.initData()
        }).catch((err) => {

        })
    }
    //表头
    const columnsHead = [
        {
            title: '规格',
            dataIndex: 'branchStoreMenuWarehouseSpec',
            render: (text: any, record: any) =>
                <div>{record.branchStoreMenuWarehouseSpec.branchStoreMenuWarehouseSpecName}</div>
        },
        {
            title: data.foodType === 'setMeal' ? '套餐菜式' : '规格特殊属性',
            dataIndex: 'branchStoreMenuWarehouseSpecSpecialName',
        },
        {
            title: '库存',
            dataIndex: 'inventory',
            render: (text: any, record: any) => {
                return <div>{text?.quantity}</div>
            }

        },
        {
            title: '库存设置',
            dataIndex: 'price',
            render: (text: any, record: any) => {
                return <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => handleSetInventory(record)}>{record.inventory ? '修改' : "设置"}库存</Button>
                    {record.inventory ?
                        <>
                            <Popconfirm title="确定要库存清零吗？" onConfirm={() => handleInventory({ apiId: "menuSpeSpecInventory", id: record.inventory?.id, columnName: "quantity", value: 0 })} okText="确定" cancelText="取消">
                                <Button size={"small"} danger>清零</Button>
                            </Popconfirm>
                            <Popconfirm title="确定要将该库存设置为99999吗？" onConfirm={() => handleInventory({ apiId: "menuSpeSpecInventory", id: record.inventory?.id, columnName: "quantity", value: 99999 })} okText="确定" cancelText="取消">
                                <Button size={"small"} danger>最大</Button>
                            </Popconfirm>
                        </> : null

                    }
                </Space>
            }

        },
        {
            title: data.foodType === 'setMeal' ? '价格' : '加价',
            dataIndex: 'price',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];
    //打开库存设置
    const handleSetInventory = (record: any) => {
        let type = record.inventory ? "EDIT" : "NEW";
        let id = record.inventory?.id;
        let exId = record.id

        if (record.isSpecial) return
        setInventoryOpen(true)
        setInventoryData({ apiId: "menuSpeSpecInventory", type, id, exId, exType: "branchStoreMenuWarehouseSpecSpecialId" })

    }
    //关闭库存设置
    const onCloseSetInventory = (status: boolean) => {
        setInventoryOpen(false)
        if (status) {
            ref.current.initData()
        }
    }
    const columns = columnsheadId
        .concat(columnsHead as []);

    const handleOk = (e: any) => {
        closeModal(false);
    };

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: data.foodType === 'setMeal' ? '套餐菜式' : '门店仓库规格特殊属性',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: BranchStoreMenuWarehouseSpecSpecialEdit,
        sendPageData: {
            branchStoreMenuWarehouseSpecId: data.branchStoreMenuWarehouseSpecId,
            foodType: data.foodType
        }
    };

    return (
        <>
            <AntDraggableModal
                title={data.foodType === 'setMeal' ? '套餐菜式' : '门店仓库规格特殊属性'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}            
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={() => closeModal(true)}
                closable={false}
                maskClosable={false}
                width={900}
            >
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            </AntDraggableModal>
            {
                inventoryOpen
                    ? (<SetInventory
                        closeModal={onCloseSetInventory} data={inventoryData} />)
                    : null
            }
        </>
    )
}