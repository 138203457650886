import { Col, Form, Input, InputNumber, Row, Select, Switch, TimePicker } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import dayjs from 'dayjs';
import antMessage from '../../../utils/extend/AntdNotification';
import objectHandle from '../../../utils/baseLib/objectHandle';

export default function MenuSpecSpecialEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    /** 折扣类型数据 */
    const [discountTypeData, setDiscountTypeData] = useState<any>();

    /** 选择折扣类型数据 */
    const [selectDiscountTypeData, setSelectDiscountTypeData] = useState<any>();

    /**周期数据 */
    const [weekData, setWeekData] = useState<any>();

    /**折扣数据 */
    const [disCountData, setDisCountData] = useState<any>();

    const [initialweekData, setInitialWeekData] = useState<any>([]);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;

            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    setSelectDiscountTypeData(res.data.discountType);
                    if (res.data.discountType === 'price') {
                        form.setFieldsValue({ price: res.data.discount });
                    }

                    const weeks = res.data.weeks;
                    let weeksRows;
                    if (objectHandle.isEmpty(weeks) === false) {
                        weeksRows = weeks.split('@');
                    }
                    setInitialWeekData(weeksRows);
                    form.setFieldsValue({
                        weeks: weeksRows
                    })
                }
            })
        }
        const fetchData = async () => {

            form.setFieldsValue({ menuId: data.sendPageData.menuId });
            form.setFieldsValue({ menuSpecId: data.sendPageData.menuSpecId });
            form.setFieldsValue({ menuSpecName: data.sendPageData.menuSpecName });

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'discountType',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setDiscountTypeData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: "week",
            }).then(res => {
                if (res.success) {
                    setWeekData(res.data);
                }
            })

            fetchApi.GET(wmService, {
                apiId: "disCount",
                apiExtend: 'showList',
            }).then(res => {
                if (res.success) {
                    // setDisCountData(res.data);
                    // console.log(res.data);

                    let newData: Array<any> = [];
                    for (var i = 0; i < res.data.length; i++) {
                        newData.push({
                            value: res.data[i].disCount,
                            label: res.data[i].disCountName,
                        })
                    }
                    setDisCountData(newData);
                }
            })
        }
        fetchData();
    }, [])

    const getDiscountTypeData = (discountTypeId: string) => {
        setSelectDiscountTypeData(discountTypeId);
        form.setFieldsValue({ discount: '' });
    }

    const changeDate = (e: any, key: any) => {
        if (key === 'startTime' && form.getFieldsValue()['endTime'] !== undefined) {
            if (dayjs(form.getFieldsValue()['endTime']).format("HH:mm") < dayjs(e).format("HH:mm")) {
                antMessage('error', '时间设置有误', '结束时间小于开始时间,请重新选择.');
                form.resetFields(['startTime']);
            }
        }

        if (key === 'endTime') {
            if (dayjs(form.getFieldsValue()['startTime']).format("HH:mm") > dayjs(e).format("HH:mm")) {
                antMessage('error', '时间设置有误', '开始时间大于结束时间,请重新选择.');
                form.resetFields(['endTime']);
            }
        }
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuPeriodDiscount',
        dateColumnName: ['startTime', 'endTime'],
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '时段'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={600}
            >
                <Form
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="menuId"
                                name="menuId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="menuSpecId"
                                name="menuSpecId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="menuSpecName"
                                name="menuSpecName">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="周期"
                                name="weeks"
                                rules={[{ required: true, message: '请选择周期!' }]}
                                initialValue={initialweekData}
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择周期"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(weekData, "name")}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="开始时间"
                                name="startTime"
                                rules={[{
                                    required: true,
                                    message: '请选择开始时间',
                                }]}>
                                <TimePicker
                                    onChange={(e) => changeDate(e, 'startTime')}
                                    format="HH:mm" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="结束时间"
                                name="endTime"
                                rules={[{
                                    required: true,
                                    message: '请选择结束时间',
                                }]}>
                                <TimePicker
                                    onChange={(e) => changeDate(e, 'endTime')}
                                    format="HH:mm" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="折扣类型"
                                name="discountType"
                                rules={[{ required: true, message: '请选择折扣类型' }]}
                            >
                                <Select
                                    placeholder="折扣类型"
                                    optionFilterProp="children"
                                    onSelect={getDiscountTypeData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                >
                                    {getList.generalEx(discountTypeData, {
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={selectDiscountTypeData === 'price' ? 12 : 0}>
                            <Form.Item
                                label={"折后价"}
                                name="price"
                                rules={[{ required: selectDiscountTypeData === 'price' ? true : false, message: '请输入折后价!' }]}>
                                <InputNumber min={0} max={data.sendPageData.price} addonBefore={'¥'} />
                            </Form.Item>
                        </Col>

                        <Col span={selectDiscountTypeData === 'discount' ? 12 : 0}>
                            <Form.Item
                                label={"折扣"}
                                name="discount"
                                rules={[{ required: selectDiscountTypeData === 'discount' ? true : false, message: '请输入折扣!' }]}>
                                <Select
                                    placeholder="折扣"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    options={disCountData}
                                >
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}