import { Col, Form, Input, InputNumber, Radio, RadioChangeEvent, Row, Select } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';




export default function BranchStoreFloorEdit(props: any) {
  const [form] = Form.useForm(); //form 
  const { data } = props;

  const ref = useRef<any>();
  const handleOk = (e: any) => {
    ref.current.handleOk();
  };
  const handleCancel = (e: any) => {


    ref.current.handleCancel();
  };
  /** 后端数据wmService */
  let wmService: any = "";
  let extendProps: WEUExtendPropsType = {
    ...props,
    form,
    apiId: 'branchStoreConfiguration'
  };
 
  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wmService = ref.current.wmService;
    }
    const fetchData = async () => {

    
    }
    fetchData()
  }, [])

  return (
    <>
      <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
      <AntDraggableModal
        title={(data.type === 'NEW' ? '新增' : '编辑') + '门店配置'}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
      >
        <Form
          form={form}
          name="Edit"
          onFinish={handleOk}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}
        >
          <Row >
            <Col span={12}>
              <Form.Item
                label="id"
                name="id">
                <Input disabled />
              </Form.Item>

            </Col>



            <Col span={24}>
              <Form.Item
                label="显示库存数"
                name="lessStockNum">
                <InputNumber min={0} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </AntDraggableModal>

    </>
  )
}


