import { Button, Input, Select, Tooltip } from 'antd';
import { useEffect, useRef, useState } from 'react';
import fetchApi from '../../utils/api/fetchApi';
import getList from '../universal/getList';
import dayjs from 'dayjs';
import WebUniversal, { WUExtendPropsType, initDataType } from './WebUniversalNewEx'

//组件 WebGlobalData
const WebGlobalData = (props: any) => {
    const ref = useRef<any>();
    /** 服务id */
    const apiId = 'globalData';
    const [cacheId, setCacheId] = useState('');
    const [queryId, setQueryId] = useState('');
    const [globalDataRow, setGlobalDataRow] = useState();
    /** 后端数据wmService */
    let wmService: any = '';

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'globalData',
                    apiExtend: 'list'
                }).then(res => {
                    if (res.success) {
                        setGlobalDataRow(res.data);
                    }
                })
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /** 查看不同id的全局变量 */
    const idChange = (e: any) => {
        setCacheId(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { id: e }
        }
        ref.current.initData(currentData);
    };

    //表头
    const columns = [{
        title: '序号',
        dataIndex: 'id',
        render: (text: any, record: any, index: any) => (
            index + 1
        )
    },
    {
        title: '缓存',
        dataIndex: 'id',
        render: (text: any, record: any) => {
            /** 缓存 */
            const cache = JSON.stringify(record);
            return <Tooltip placement="topLeft" title={cache}>{
                typeof cache !== "undefined" ?
                    (cache.length >= 70 ? `${cache.substring(0, 70)}......` : cache)
                    : ''
            }</Tooltip>
        }
    },
    {
        title: '更新',
        dataIndex: '_globalDataOptions',
        render: (text: any) => {
            const { createdAt, updatedAt } = text;
            if (updatedAt) {
                let createdAtStr = '';
                const updatedAtStr = dayjs(updatedAt).format('YYYY-MM-DD HH:mm:ss');
                if (createdAt) {
                    createdAtStr = `创建时间：${dayjs(createdAt).format('YYYY-MM-DD HH:mm:ss')} 更新时间：${updatedAtStr}`;
                }
                return <Tooltip placement="topLeft" title={createdAtStr}>
                    {updatedAtStr}
                </Tooltip>
            }
        }
    },
    {
        title: '查询',
        dataIndex: '_globalDataOptions',
        render: (text: any) => {
            const { queryedAt } = text;
            if (queryedAt) {
                return dayjs(queryedAt).format('YYYY-MM-DD HH:mm:ss');
            }
        }
    },
    {
        title: '次数',
        dataIndex: '_globalDataOptions',
        render: (text: any) => {
            const { queryCount } = text;
            return queryCount;
        }
    },
    ];

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '缓存',
        apiId,
        columns,
        showAdd: false,
        showOperation: false,
        showQuery: true,
        pagination: true
    };

    /** 查询 */
    const queryHandle = () => {
        if (typeof cacheId !== 'undefined' && cacheId !== '' && typeof queryId !== 'undefined' && queryId !== '') {
            console.log(cacheId);
            console.log(queryId);

        }
    };
    return (
        <>
            {/* <div style={{
                display: "flex",
                justifyContent: "flex-start"
            }}> */}
            <Select
                showSearch
                defaultValue=""
                onChange={idChange}
                placeholder="缓存id"
                optionFilterProp="children"
                style={{ width: '200px' }}
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(globalDataRow, undefined, undefined, true)}
            </Select>
            {/* &nbsp;
                <Input
                    style={{ width: '400px' }}
                    placeholder='缓存查询id'
                    onChange={(e) => {
                        setQueryId(e.target.value);
                    }}
                ></Input>
                &nbsp;
                <Button onClick={queryHandle}>查询</Button> */}
            {/* </div> */}
            <span>   </span>
            <WebUniversal props={extendProps} ref={ref} />
        </>
    )
}

export default WebGlobalData;