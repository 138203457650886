import { useEffect, useRef, useState } from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import MenuHeadEdit from './MenuHeadEdit';
import { Button, Space, Switch } from 'antd';
import BranchStoreList from '../../generalComponents/BranchStoreList';
import WarehouseToMenu from '../menu/WarehouseToMenu';
import ShowMenu from '../menu/ShowMenu';
import antMessage from '../../../utils/extend/AntdNotification'
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import DownloadExcel from '../../report/DownloadExcel';

export default function MenuHead(props: any) {

    const ref = useRef<any>();

    // const [form] = Form.useForm();

    // const [menuHeadData, setMenuHeadData] = useState<any>();

    /** api服务id */
    const apiId = 'menuHead';

    /** 更改一项数据 */
    // let handleChangeOne: any;

    //选择转移菜式
    const [menuData, setMenuData] = useState<any>();

    //转移菜式
    const [warehouseToMenuVisible, setWarehouseToMenuVisible] = useState(false);

    //菜式窗口
    const [showMenuVisible, setShowMenuVisible] = useState(false);

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }

        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //改变数据
    const handleChange = async (id: any, columnName: string, isValue: boolean) => {
        const bodys = {
            apiId: "menuHead",
            apiVariable: id,
            apiExtend: "updateSingle",
            apiData: {
                columnName,
                isValue
            }
        }

        fetchApi.PUT(wmService, bodys).then(res => {
            antMessage('success', '编辑成功', 'success');
            initData();
        }).catch(err => {
            antMessage('error', '删编辑失败', err.message);
        })
    };

    const initData = async () => {
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                branchStoreId: localStorage.getItem("currentBranchStoreId")
            },
        }
        ref.current.initData(currentData);
    }

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '菜谱名称',
            dataIndex: 'menuHeadName',
        },

        sessionStorage.getItem('isAdmin') === 'true' ?
            {
                title: '添加菜式',
                dataIndex: 'addToMenu',
                key: 'addToMenu',
                render: (text: any, record: any) => {
                    return <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => handleWarehouseToMenu(record)}>添加菜式</Button>
                    </Space>
                }
            } : {},

        {
            title: '查看菜式',
            dataIndex: 'showMenu',
            key: 'showMenu',
            render: (text: any, record: any) => {
                return <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => handleShowMenu(record)}>查看菜式</Button>
                </Space>
            }
        },
        {
            title: '导出文档',
            dataIndex: 'downloadExcel',
            key: 'downloadExcel',
            render: (text: any, record: any) => {
                return <Space size="small">
                    <Button onClick={() => HandleDownloadExcel(record)}>导出文档</Button>
                </Space>
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
        {
            title: '微信查看',
            dataIndex: 'isWxCheck',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                    onChange={(e) => handleChange(record.id, 'isWxCheck', e)}
                />
            )
        }
    ];

    //关闭穿梭框页面
    const onCloseWarehouseToMenu = async (status: boolean) => {
        setWarehouseToMenuVisible(false);
        // if (status) {
        //     await initData();
        // }
    }

    //打开添加菜式
    const handleWarehouseToMenu = (e: any) => {
        // console.log(e);

        sendData.key = 'branchStoreMenuWarehouseToMenu';
        sendData.menuHeadId = e.id;
        sendData.menuHeadName = e.menuHeadName
        sendData.brandId = e.branchStore.brand.id;
        sendData.brandName = e.branchStore.brand.brandName;
        sendData.branchStoreId = e.branchStore.id;
        sendData.branchStoreName = e.branchStore.branchStoreName;
        setMenuData(sendData);
        setWarehouseToMenuVisible(true);
    };

    //打开查看菜式
    const handleShowMenu = (e: any) => {

        sendData.menuHeadId = e.id;
        sendData.menuHeadName = e.menuHeadName
        sendData.brandId = e.branchStore.brand.id;
        sendData.brandName = e.branchStore.brand.brandName;
        sendData.branchStoreId = e.branchStore.id;
        sendData.branchStoreName = e.branchStore.branchStoreName;
        setMenuData(sendData);
        setShowMenuVisible(true);
    };

    //生成下载文档
    const HandleDownloadExcel = async (e: any) => {
        // console.log(e);
        let res = await fetchApi.GET(wmService, {
            apiId: "menuHead",
            apiExtend: 'downloadData',
            apiData: {
                menuHeadId: e.id,
            }
        })
        if (res.success) {
            await DownloadExcel(res.data, "menuHead", { menuHeadName: e.menuHeadName, branchStoreName: e.branchStore.brand.brandName + e.branchStore.branchStoreName });
        }
    };

    const onRefresh = (item: any) => {
        const { branchStoreId } = item;
        const currentData: initDataType = {
            apiId,
            sendPageData: { branchStoreId }
        }
        ref.current.initData(currentData);
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜谱头',
        apiId: 'menuHead',
        showStates: true,
        showDefault: true,
        columns,
        pagination: true,
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
        extendIdName: "branchStoreId",
        EditComponent: MenuHeadEdit,
    };

    //传关参数
    let sendData = {
        type: 'EDIT',
        key: '',
        menuHeadId: '',
        menuHeadName: '',
        brandId: '',
        brandName: '',
        branchStoreId: '',
        branchStoreName: ''
    }

    return (
        <>
            <BranchStoreList onRefresh={onRefresh}></BranchStoreList>
            <br></br>
            <WebUniversalNewEx props={extendProps} ref={ref} />

            {warehouseToMenuVisible
                ? (<WarehouseToMenu
                    closeModal={onCloseWarehouseToMenu} data={menuData} />)
                : null}

            {showMenuVisible
                ? (<ShowMenu
                    closeModal={setShowMenuVisible} data={menuData} />)
                : null}

        </>
    )
}