import { Form, Input, InputNumber, Select, Switch } from 'antd';
import { useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import system from "../../../config/systematic";
import fetchApi from '../../../utils/api/fetchApi';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

const { TextArea } = Input;

export default function FeastTeamMenuWarehouseEdit(props: any) {

    /**单位数据 */
    const [productUnitData, setProductUnitData] = useState<any>();

    /**地区数据 */
    // const [areaData, setAreaData] = useState<any>();

    /**地区的状态 */
    // const [areaIdDisable, setAreaDisable] = useState<any>(false);

    /**福食菜式类别数据 */
    const [feastTeamMenuWarehouseCategoryData, setFeastTeamMenuWarehouseCategoryData] = useState<any>();

    /**烹调方法数据 */
    const [cookMethodData, setCookMethodData] = useState<any>();
    

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };
    
    /** 后端数据Service */
    let wmService = system.wmService;

    /**数据 */
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            // wmService = ref.current.wmService;
            // ref.current.row.then(async (res: any) => {
            //     if (typeof res === 'undefined') { return }
            //     if (res.success === true) {
            //         const menuWarehouseTypeId = res.data.menuWarehouseTypeId;
            //         //console.log(res.data);

            //         fetchApi.GET(wmService, {
            //             apiId: 'menuWarehouseType',
            //             apiVariable: menuWarehouseTypeId
            //         }).then(res => {
            //             if (res.success) {
            //                 //console.log(res.data)
            //                 //form.setFieldsValue({ menuWarehouseTypeId: res.data.id });
            //             }
            //         })
            //     }
            // })
        }

        const fetchData = async () => {

            // if (data.type !== 'NEW') {
            //     setAreaDisable(true);
            // }

            fetchApi.GET(wmService, {
                apiId: "productUnit",
                apiExtend: 'showList'
            }).then(res => {
                setProductUnitData(res.data);
            });

            
            fetchApi.GET(wmService, {
                apiId: "feastTeamMenuWarehouseCategory",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setFeastTeamMenuWarehouseCategoryData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: "cookMethod",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setCookMethodData(res.data);
                }
            });

            // fetchApi.GET(wmService, {
            //     apiId: "area",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         setAreaData(res.data);
            //     }
            // });

            // fetchApi.GET(wmService, {
            //     apiId: "feastTeamMenuWarehouse",
            //     apiExtend: 'getAreaId'
            // }).then(res => {
            //     if (res.success) {
            //         if (res.data.key !== 'manager') {
            //             setAreaDisable(true);
            //             form.setFieldsValue({ areaId: res.data.area.id });
            //         }
            //     }
            // });
        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'feastTeamMenuWarehouse'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '福食菜式仓库'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "price": 999, "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Form.Item
                        label="id"
                        name="id">
                        <Input disabled={true} />
                    </Form.Item>

                    <Form.Item
                        label="菜名"
                        name="foodName"
                        rules={[{ required: true, message: '请输入菜名!' }]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="福食菜式类别"
                        name="feastTeamMenuWarehouseCategoryId"
                        rules={[{ required: true, message: '福食菜式类别!' }]}>
                        <Select
                            showSearch
                            placeholder="福食菜式类别"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(feastTeamMenuWarehouseCategoryData, 'feastTeamMenuWarehouseCategoryName')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="单位"
                        name="productUnitId"
                        rules={[{ required: true, message: '请输入单位!' }]}>
                        <Select
                            showSearch
                            placeholder="单位"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(productUnitData, 'productUnitName')}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label="烹调方法"
                        name="cookMethodId"
                        rules={[{ required: true, message: '烹调方法!' }]}>
                        <Select
                            showSearch
                            placeholder="烹调方法"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(cookMethodData, 'cookMethodName')}
                        </Select>
                    </Form.Item>

                    {/* <Form.Item
                        label="所属区域"
                        name="areaId"
                        rules={[{ required: true, message: '请选择所属区域!' }]}>
                        <Select
                            showSearch
                            disabled={areaIdDisable}
                            placeholder="所属区域"
                            optionFilterProp="children"
                            filterOption={(input, option: any) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                            {getList.general(areaData, 'areaName')}
                        </Select>
                    </Form.Item> */}

                    <Form.Item
                        label="备注"
                        name="memo">
                        <TextArea rows={6} />
                    </Form.Item>

                    <Form.Item
                        label="排序"
                        name="sortBy">
                        <InputNumber min={1} />
                    </Form.Item>

                    <Form.Item
                        label="状态"
                        name="isUse"
                        valuePropName="checked"
                        initialValue={true}>
                        <Switch checked={true}
                            checkedChildren="启用"
                            unCheckedChildren="关闭" />
                    </Form.Item>

                </Form>
            </AntDraggableModal>
        </>
    )
}