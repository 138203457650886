import systemConfig from '../../../config/systematic';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification'
import DownloadExcel from '../../report/DownloadExcel';

const { RangePicker } = DatePicker;

/**出品部门 */
export default function MakeDept(props: any) {
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [form] = Form.useForm();

    const [rows, setRows] = useState<any>([]);
    const [moonStatusData, setMoonStatusData] = useState<any>();
    /**票据类型 */
    const [moonTypeData, setMoonTypeData] = useState<any>();
    /** 售出分店*/
    const [salebranchStoreDate, setSalebranchStoreDate] = useState<any>();
    /**兑换分店 */
    const [branchStoreData, setbranchStoreData] = useState<any>()
    useEffect(() => {
        const fetchData = async () => {

            fetchApi.GET(
                wmService,
                {
                    apiId: "moonType",
                    apiExtend: 'showList'
                }
            ).then(res => {
                if (res.success) {

                    setMoonTypeData(res.data);

                }
            })
            fetchApi.GET(
                wmService,
                {
                    apiId: "branchStore",
                    apiExtend: 'showList'
                }
            ).then(res => {
                if (res.success) {
                    setSalebranchStoreDate(res.data)
                    setbranchStoreData(res.data);
                }
            })
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'moonStatus',
                    apiExtend: 'showList'
                }).then(res => {

                    if (res.success) {
                        setMoonStatusData(res.data);
                    }
                })
            // 
        }
        fetchData()
    }, [])
    /** 表头内容 */
    let columnsHead: any = [
        {
            title: '票据类型',
            dataIndex: 'moonType',
            key: "moonType",
            width: 20,
            header: "票据类型",
        },
        {
            title: '折扣',
            dataIndex: 'disCountName',
            key: "disCountName",
            width: 10,
            header: "折扣",
        },
        {
            title: '票号',
            dataIndex: 'ticketNo',
            key: "ticketNo",
            width: 15,
            header: "票号",
        },
        {
            title: "售出分店",
            dataIndex: "saleBranchStoreName",
            key: "saleBranchStoreName",
            width: 20,
            header: "售出分店",
        },
        {
            title: '售出时间',
            dataIndex: 'time',
            key: "time",
            width: 20,
            header: "售出时间",
            // render: (text: any, record: any) => (text ? moment(text.createdAt).format("YYYY-MM-DD HH:mm:ss") : "")

        },
        {
            title: "兑换分店",
            dataIndex: "exchangeBranchStoreName",
            key: "exchangeBranchStoreName",
            width: 20,
            header: "兑换分店",
        },

        {
            title: '票据状态',
            dataIndex: 'moonStatus',
            key: "moonStatus",
            width: 10,
            header: "票据状态",
        },


    ]
    const onFinish = () => {


        form.validateFields()
            .then(async (values: any) => {
                fetchApi.GET(wmService, {
                    apiId: "moon",
                    apiExtend: 'billQuery',
                    apiData: {
                        ...values,
                        // branchStoreId
                    }
                }).then((res) => {
                    if (res.success) {
                        setRows(res.data)
                    }
                }).catch((err) => {
                    antMessage("error", '错误', err);
                })



            })
            .catch((err) => {

                antMessage('warning', '无法通过数据校验', '请输入所需数据！');
            })





    }


    return (
        <>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Row>
                    <Col span={6}>
                        <Form.Item
                            label="票据状态"
                            name="moonStatus"
                        >
                            <Select
                                showSearch
                                allowClear
                                // onChange={moonTypeChange}
                                placeholder="票据状态"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.general(moonStatusData)}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label="票据类型"
                            name="moonTypeIds"
                        >
                            <Select
                                showSearch
                                // onChange={moonTypeChange}
                                mode='multiple'
                                placeholder="票据类型"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.general(moonTypeData, "moonType")}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label="售出分店"
                            name="salebranchStoreId"
                        >
                            <Select
                                showSearch
                                allowClear
                                // onChange={salebranchStoreChange}
                                placeholder="售出分店"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.general(salebranchStoreDate, "branchStoreName")}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={6}>
                        <Form.Item
                            label="兑换分店"
                            name="exchangebranchStoreId"

                        >

                            <Select
                                showSearch
                                allowClear
                                // onChange={exchangebranchStoreChange}
                                placeholder="兑换分店"
                                optionFilterProp="children"
                                filterOption={(input, option: any) =>
                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                {getList.general(branchStoreData, "branchStoreName")}
                            </Select>
                        </Form.Item>
                    </Col>




                    <Col span={6}>
                        <Form.Item

                            label="日期"
                            name="chooseTime"
                        >
                            <RangePicker

                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </Col>











                    <Col span={4}>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    统计
                                </Button >
                                <Button onClick={() => DownloadExcel(rows, "billQuery", {columnsHead  })}>下载EXCEL文件</Button>

                                {/* purContrast */}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <div style={{ marginTop: "20px" }}>
                <Table
                    scroll={{ x: "100%", y: 950 }}
                    pagination={false}
                    rowKey={(record: any, index: any) => {
                        return index
                    }}
                    columns={columnsHead}
                    dataSource={rows}

                >
                </Table>

            </div>
        </>
    )
}
