import CarteringOrderEdit from './CarteringOrderEdit';
import WebUniversal, { WUExtendPropsType, initDataType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Button, Col, DatePicker, Dropdown, Form, MenuProps, Row, Select, Space, Tooltip } from 'antd';
import getList from '../../universal/getList';
import { useContext, useEffect, useRef, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import moment from 'moment';
import dayjs from 'dayjs';
import CastOrderDrawe from '../cashier/CastOrderDrawe';
import BranchStoreList from '../../generalComponents/BranchStoreList';
import PayDetails from './PayDetails';
import systematic from '../../../config/systematic';
import OrderBack from './OrderBack';
import { DownOutlined } from '@ant-design/icons';
import RefundDetails from './RefundDetails';
import antMessage from '../../../utils/extend/AntdNotification'
import RefundOrder from './RefundOrder';
import DrawerDetailList from '../cashier/DrawerDetailList';
import systemConfig from '../../../config/systematic';
import { SocketContext } from '../../../App';   
//组件 
const WebComponents = (props: any) => {
    /** api服务id */
    const apiId = 'cateringOrder';

    const ref = useRef<any>();

    const [singlePrintData, setSinglePrintData] = useState<any>();
    const [singlePrintOpen, setSinglePrintOpen] = useState<boolean>(false);
    const [castOrderDraweData, setCastOrderDraweData] = useState<any>();
    const [castOrderDraweOpen, setCastOrderDraweOpen] = useState<boolean>(false);
    const [cateringOrderModeId, setCateringOrderModeId] = useState<any>();

    const [caOrderStatus, setCaOrderStatus] = useState<any>();

    const [paymentMethodOpen, setPaymentMethodOpen] = useState<any>();
    const [paymentMethodData, setPaymentMethodData] = useState<any>();


    const [refundOrderOpen, setRefundOrderOpen] = useState<any>();
    const [refundOrderData, setRefundOrderData] = useState<any>();

    const [orderBackOpen, setOrderBackOpen] = useState<any>();
    const [orderBackData, setOrderBackData] = useState<any>();
    // 
    const [orderRefundDetailsOpen, setOrderRefundDetailsOpen] = useState<any>();
    const [orderRefundDetailsData, setOrderRefundDetailsData] = useState<any>();
    /** 后端数据defaultService */
    let defaultService: any = systematic.wmService;
    const [cateringOrderModeData, setCateringOrderModeData] = useState<any>();

    const [caOrderStatusData, setCaOrderStatusData] = useState<any>();
    const useContextData = useContext(SocketContext);
    const {  contextData } = useContextData;
    
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [orderDate, setOrderDate] = useState<any>();
    const [sbId, setSbId] = useState<any>();

    const [sbData, setSbData] = useState<any>();

    const [roomData, setRoomData] = useState<any>();

    const [roomIds, setRoomIds] = useState<Array<any>>();

    const [dataStatusTypeId,setDataStatusTypeId]=useState<any>(1);
    const [dataTypeData,setDataTypeData]=useState<Array<any>>();
    const [form] = Form.useForm();
    useEffect(() => {
        if (ref.current) {
            
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.defaultService;
        }
        //同步获取数据 async - await
        const fetchData = async () => {
            fetchApi.GET(defaultService,
                {
                    apiId: 'generalData',
                    apiVariable: 'cateringOrderMode@caOrderStatus@dataStatusType',
                    apiExtend: 'showLists'
                }).then(res => {
                    if (res.success) {
                        const { cateringOrderMode, caOrderStatus,dataStatusType } = res.data
                        
                        let newDataCateringOrderMode = [{ id: 9999, name: "所有类型" }, ...cateringOrderMode]
                        let newCaOrderStatus = [{ id: 9999, name: "所有订单" }, ...caOrderStatus]

                        setCateringOrderModeData(newDataCateringOrderMode);
                        setCaOrderStatusData(newCaOrderStatus);
                        setDataTypeData(dataStatusType)
                    }
                })

            // setCaOrderStatusData
        };
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /**
        * 点击触发
        * @param param0 
        */
    const onManageItemsClick = (record: any, { key }: any) => {

        let keyJson = JSON.parse(key);
        const { id, orderId } = keyJson;
        const { closingDate, payId } = record;


        switch (id) {
            //发起退款
            case "refund":
                if (closingDate && moment().format("YYYY-MM-DD") !== moment(closingDate).format("YYYY-MM-DD")) {
                    antMessage("error", '无法发起退款');
                    return false
                }
                // if(caOrderStatus===996) {
                //   
                //     return false
                // }
                openOrderBack(record)
                break;
            //退款详情
            case "refundDetails":
                openRefundDetails(orderId)
                break;
            //支付查询
            case "orderQuery":
                //执行查询操作
                if (!payId) {
                    antMessage("error", '无法发起查询');
                    return false
                }
                fetchApi.GET(wmService, {
                    apiId: "payOrder",
                    apiExtend: "orderQuery",
                    apiData: {
                        payId
                    }
                }).then(res => {
                    if (res.success) {

                        const { trade_state_desc } = res.data.data
                        antMessage('success', '查询成功', trade_state_desc);
                    } else {
                        antMessage('error', '错误，查询失败');
                    }
                }).catch(err => {
                    console.log("err", err);

                    antMessage('error', '错误，查询失败', err.message + ' | status：' + err.errors.status);
                })

                // openRefundDetails(orderId)
                break;
            //整单退款
            // case "refundOrder":
            //     // if(caOrderStatus===996) {
            //     //     antMessage("warning", '该订单交易关闭');
            //     //     return false
            //     // }
            //     openRefundOrder(record)
            //     break
        }

    };
    /** 管理菜单项 */
    const manageItems = (orderId: string) => {
        const items: MenuProps['items'] = [
            {
                label: "发起退款",
                key: '{"orderId":"' + orderId + '","id":"refund"}',
            },
            // {
            //     label: '发起退款(整单)',
            //     key: '{"orderId":"' + orderId + '","id":"refundOrder"}'
            // },
            {
                label: '退款详情',
                key: '{"orderId":"' + orderId + '","id":"refundDetails"}'
            },
            {
                label: '支付查询',
                key: '{"orderId":"' + orderId + '","id":"orderQuery"}'
            },



        ];
        return items;
    };

    //表头
    const columnsHead = [
        {
            title: '分店',
            dataIndex: 'branchStore',
            render: (text: any) => (text.branchStoreName ? `${text.brandName}${text.branchStoreName}` : "")
        },
        {
            title: '房号',
            dataIndex: 'room',
            render: (text: any) => {
                return <div>{text.roomNum}</div>
            }
        },
        {
            title: '下单',
            dataIndex: 'orderDate',
            render: (text: any) => {
                return <>{moment(text).format("YYYY-MM-DD HH:mm:ss")}</>
            }
        },
        {
            title: '结账',
            dataIndex: 'closingDate',
            render: (text: any) => {
                return <>{text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : ""}</>
            }
        },
        {
            title: '市别',
            dataIndex: 'sb',
            render: (text: any) => {
                return <div>{text.sbName}</div>
            }
        },
        {
            title: '取餐号',
            dataIndex: 'pickupCode',
        },
        {
            title: '号码',
            dataIndex: 'callerId',
        },
        {
            title: '微信',
            dataIndex: 'nickName',
        },
        {
            title: '用户',
            dataIndex: 'userName',
        },
        {
            title: '人数',
            dataIndex: 'headCount',
        },
      
        // {
        //     title: '整单备注',
        //     dataIndex: 'memo',
        // },
        {
            title: '实(应)付',
            dataIndex: 'realMoney',
            render: (text: any, record: any) => {
                let price = (record.caOrderStatus === 999 || record.caOrderStatus === 996) ? text : (record.caOrderStatus === 998 ? record.shouldMoney : 0)
                return <div>{price}</div>
            }
        },
        {
            title: '线上支付',
            dataIndex: 'orderPayOnline',
            render: (text: any, record: any) => {
                let isPay = record.isPay;
                let caOrderStatus = record.caOrderStatus;
                let payId = record.payId;
                return isPay && (caOrderStatus === 999 || caOrderStatus === 996) && payId ? <Space size="small">
                    <Dropdown menu={{ items: manageItems(record.id), onClick: (key) => onManageItemsClick(record, key) }} trigger={["hover"]}>
                        <Space>
                            <Button size={"small"} type="dashed" danger >线上支付</Button>
                            <DownOutlined />
                        </Space>
                    </Dropdown>
                </Space > : null
            }

        },
        {
            title: "支付方式",
            dataIndex: "paymentMethod",
            render: (text: any, record: any) => {
                return <Button size="small" type="primary" disabled={record.caOrderStatus === 999 ? false : true} onClick={() => openPaymentMethod(record.id)}>支付方式</Button>
            }
        },
        {
            title: '打印',
            dataIndex: 'headCount',
            render: (text: any, record: any) => {
                return <Button size="small" type="primary" onClick={() => handleSinglePrint(record)}>打印</Button>
            }
        },
        // {
        //     title: '复制',
        //     dataIndex: 'copy',
        //     render: (text: any, record: any) => {
        //         return <Button size="small" type="primary" onClick={() => copy(record.id)}>复制</Button>
        //     }
        // },
        {
            title: '反结账',
            dataIndex: 'headCount',
            render: (text: any, record: any) => {
                let time = record.closingDate ? record.closingDate : record.orderDate
                let endTimeStr = moment(time).format("YYYY-MM-DD") + " " + record.sb.endTime;
                let endTime = moment(endTimeStr);
                let orderTime = moment();
                //比较是否同一天
                let isSameDate = false;
                if (endTime.format("YYYY-MM-DD") === orderTime.format("YYYY-MM-DD")) isSameDate = true;
                //下单时间是否在该天市别的结束时间之前，如果是，则可考虑执行反结账，暂不支持反结账
                // let isReverseClosing = orderTime.isBefore(endTime);
                return <Button disabled={(record.caOrderStatus === 999 && isSameDate) || record.caOrderStatus === -998 || record.isReverseClosing ? false : true} size="small" type="primary" onClick={() => handleCastOrderDrawe(record)}>反结账</Button>
                // return <Button  size="small" type="primary" onClick={() => handleCastOrderDrawe(record)}>反结账</Button>

            }
        },
        {
            title: '订单状态',
            dataIndex: 'caOrderStatusName',
        },
        // {
        //     title: '复制',
        //     dataIndex: 'headCount',
        //     render: (text: any, record: any) => {
            
        //         //比较是否同一天
               
        //         //下单时间是否在该天市别的结束时间之前，如果是，则可考虑执行反结账，暂不支持反结账
        //         // let isReverseClosing = orderTime.isBefore(endTime);
        //         return <Button  onClick={() => copy(record.id)}>复制</Button>
        //         // return <Button  size="small" type="primary" onClick={() => handleCastOrderDrawe(record)}>反结账</Button>

        //     }
        // },
    ];
    const copy = (id: string) => {


        fetchApi.POST(defaultService, {
            apiId: "cateringOrder",
            apiExtend: "copy",
            apiData: {
                id,
            }
        }).then((res) => {
            const currentData: initDataType = {
                apiId,
                sendPageData: { cateringOrderModeId, orderDate, sbId, roomIds, branchStoreId: contextData?.currentBranchStoreId }
            }
            ref.current.initData(currentData)

        }).catch((err) => {
            console.log("eerr", err);

        })
    }

    /** 子表头内容 */
    const expandableColumnsHead: Array<any> = [
        {
            title: "品名",
            dataIndex: "menuName",
        },
        // {
        //     title: "id",
        //     dataIndex: "id",
        //     render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        //         (typeof text !== "undefined" && text !== null) ?
        //             (text.length >= 10 ? text.substring(0, 10) + '......' : text)
        //             : ''
        //     }</Tooltip>
        // },

        {
            title: "规格",
            dataIndex: "menuSpecName"
        },
        {
            title: "数量",
            dataIndex: "amount",
        },
        {
            title: "特殊规格",
            dataIndex: "menuSpecSpeName"
        },
        {
            title: "加料",
            dataIndex: "condimentWarehouseName"
        },
        {
            title: "原价",
            dataIndex: "advancePrice",
            render: (text: any, record: any) => {
                return <>{(text)}</>
            }
        },
        {
            title: "折扣",
            dataIndex: "discount",
            render: (text: any, record: any) => {
                return <>{`${text * 10}折`}</>
            }
        },
        {
            title: "折后价",
            dataIndex: "totalPrice",
            render: (text: any, record: any) => {
                return <>{(record.advancePrice * record.discount).toFixed(2)}</>
            }
        },
        {
            title: "实付",
            dataIndex: "purchasePrice",
        },
        {
            title: "备注",
            dataIndex: "memo",
        },
        {
            title: '号码',
            dataIndex: 'callerId',
        },
        {
            title: "微信",
            dataIndex: "nickName",
        },
        {
            title: "用户",
            dataIndex: "userName",
        },
        {
            title: '加菜时间',
            dataIndex: 'sysDate',
            render: (text: any) => {
                return <>{moment(text.createdAt).format("YYYY-MM-DD HH:mm:ss")}</>
            }
        },
        {
            title: '接单时间',
            dataIndex: 'operateTime',
            render: (text: any) => {
                return <>{text ? moment(text).format("YYYY-MM-DD HH:mm:ss") : ""}</>
            }
        },
        {
            title: "菜品状态",
            dataIndex: "caMenuStatusName",
        },

    ]

    /** 子表头 */
    const expandableColumns = expandableColumnsHead;

    const columns = columnsheadId
        .concat(columnsHead as []);
     
    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '餐饮订单',
        apiId,
        columns,
        showStates: true,
        showAdd: false,
        mountDataOptions: {
            "userMount": {
                "isDept": true
            }
        },

        // showEdit: false,
        pagination: true,
        showPageOrderBy: "cateringOrder.orderDate@DESC",
        //子表格
        isExpandable: true,
        expandableColumns,
        expandableDataName: 'cateringOrderDetails',
        EditComponent: CarteringOrderEdit,
        sendPageData: { cateringOrderModeId,dataStatusType:dataStatusTypeId, sbId, orderDate, roomIds, branchStoreId: contextData?.currentBranchStoreId }
    };


    //打开支付详情
    const openPaymentMethod = (id: string) => {
        if (!id) return false;
        setPaymentMethodOpen(true)
        setPaymentMethodData({ id, isModel: true, isAdd: 1, isDelete: 1, isEdit: 1,dataStatusType:dataStatusTypeId })
    }

    //关闭支付详情
    const onClosePaymentMethod = async (status: boolean) => {
        setPaymentMethodOpen(false);
        if (status) {
            const currentData: initDataType = {
                apiId,
                sendPageData: { caOrderStatus, cateringOrderModeId, orderDate, sbId,dataStatusType:dataStatusTypeId, roomIds, branchStoreId:  contextData?.currentBranchStoreId }
            }
            ref.current.initData(currentData)
        }
    }


    //打开发起退款详情(菜)
    const openOrderBack = (record: any) => {

        const { id } = record
        if (!id) return false;
        setOrderBackOpen(true)
        setOrderBackData({ orderId: id, roomNum: record.room.roomNum })
    }

    //关闭发起退款详情
    const onCloseOrderBack = async (status: boolean) => {
        setOrderBackOpen(false);
        if (status) {
            const currentData: initDataType = {
                apiId,
                sendPageData: { caOrderStatus, cateringOrderModeId, orderDate, sbId, roomIds, dataStatusType:dataStatusTypeId,branchStoreId:  contextData?.currentBranchStoreId }
            }
            ref.current.initData(currentData)
        }
    }



    //打开发起退款详情(整单)
    const openRefundOrder = (record: any) => {

        const { id, realMoney, refundAmount } = record;
        if (!id) return false;
        setRefundOrderOpen(true)
        setRefundOrderData({ orderId: id, realMoney, refundAmount })
    }

    //关闭发起退款详情
    const onCloseRefundOrder = async (status: boolean) => {
        setRefundOrderOpen(false);
        if (status) {
            const currentData: initDataType = {
                apiId,
                sendPageData: { caOrderStatus, cateringOrderModeId, orderDate, sbId,dataStatusType:dataStatusTypeId, roomIds, branchStoreId:  contextData?.currentBranchStoreId }
            }
            ref.current.initData(currentData)
        }
    }


    //打开退款详情
    const openRefundDetails = (id: string) => {
        if (!id) return false;
        setOrderRefundDetailsOpen(true)
        setOrderRefundDetailsData({ orderId: id })
    }
    //关闭退款详情
    const onCloseRefundDetails = async (status: boolean) => {
        setOrderRefundDetailsOpen(false);
        if (status) {
            const currentData: initDataType = {
                apiId,
                sendPageData: { caOrderStatus, cateringOrderModeId, orderDate, sbId,dataStatusType:dataStatusTypeId, roomIds, branchStoreId:  contextData?.currentBranchStoreId }
            }
            ref.current.initData(currentData)
        }
    }
    /**打开整单打印 */
    const handleSinglePrint = (item: any) => {

        const { id, caOrderStatus } = item
        if (!id) return false

        setSinglePrintOpen(true)
        setSinglePrintData({ id, isIo: true, caOrderStatus, caMenuStatus: [2, 3, 999], dataStatusType:dataStatusTypeId,type: "noPending", roomNum: item.room.roomNum })
    }
    //关闭整单打印
    const onCloseSinglePrint = async (status: boolean) => {
        setSinglePrintOpen(false);
        if (status) {
            // setCateringOrderModeData(e);
            const currentData: initDataType = {
                apiId,
                sendPageData: { caOrderStatus, cateringOrderModeId, orderDate, sbId, roomIds, dataStatusType:dataStatusTypeId,branchStoreId: contextData?.currentBranchStoreId }
            }
            ref.current.initData(currentData)
        }
    }
    /** 查看不同模式的订单 */
    const cateringOrderModeChange = (e: any) => {
        setCateringOrderModeId(e)
        // setCateringOrderModeData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { caOrderStatus, cateringOrderModeId: e, orderDate, sbId, roomIds,dataStatusType:dataStatusTypeId, branchStoreId: contextData?.currentBranchStoreId }
        }
        ref.current.initData(currentData);
    };

    /** 查看不同状态的订单 */
    const cateringOrderStatusChange = (e: any) => {
        setCaOrderStatus(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { caOrderStatus: e, cateringOrderModeId, orderDate, sbId, roomIds,dataStatusType:dataStatusTypeId, branchStoreId: contextData?.currentBranchStoreId }
        }
        ref.current.initData(currentData);
    };

    //根据日期回显数据
    const handleDate = (e: any) => {
        let orderDateEx;
        if (e) {
            orderDateEx = dayjs(e).format("YYYY-MM-DD")
            setOrderDate(orderDateEx)

        }else{
            setOrderDate(null) 
        }
        
        const currentData: initDataType = {
            apiId,
            sendPageData: { caOrderStatus, cateringOrderModeId, orderDate: orderDateEx, sbId, roomIds,dataStatusType:dataStatusTypeId, branchStoreId: contextData?.currentBranchStoreId }
        }

        ref.current.initData(currentData);
    }
    /** 查看不同市别的订单 */
    const sbChange = (e: any) => {
        setSbId(e)

        // setCateringOrderModeData(e);
        const currentData: initDataType = {
            apiId,
            sendPageData: { caOrderStatus, cateringOrderModeId, orderDate, sbId: e, dataStatusType:dataStatusTypeId,roomIds, branchStoreId: contextData?.currentBranchStoreId }
        }
        ref.current.initData(currentData);
    };
    const roomChange = (e: any, option: any) => {
        setRoomIds(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { caOrderStatus, cateringOrderModeId, orderDate, sbId, roomIds: e, dataStatusType:dataStatusTypeId,branchStoreId: contextData?.currentBranchStoreId }
        }
        ref.current.initData(currentData);

    }

    /**打开收银订单详情 */
    const handleCastOrderDrawe = (data: any) => {

        setCastOrderDraweOpen(true)
        // setCastOrderDraweData({})
        setCastOrderDraweData({ ...data, mode: "reverseClosing", type: "EDIT",isIo:true,isReverseClosing:true })

    }

    //关闭收银订单详情
    const onCloseCastOrderDraw = async (status: boolean) => {
        setCastOrderDraweOpen(false);
        if (status) {
            const currentData: initDataType = {
                apiId,
                sendPageData: { caOrderStatus, cateringOrderModeId, orderDate, sbId, roomIds,dataStatusType:dataStatusTypeId, branchStoreId: contextData?.currentBranchStoreId }
            }
            ref.current.initData(currentData)
        }
    }
    const dataStatusTypeChange = (e: any, option: any) => {
        setDataStatusTypeId(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { caOrderStatus, cateringOrderModeId, orderDate, sbId, roomIds: e,dataStatusType:e, branchStoreId: contextData?.currentBranchStoreId }
        }
        ref.current.initData(currentData);

    }
   

    const onRefresh = (item: any) => {
        
        const {branchStoreId}=item
        setSbId(null)
        setRoomIds([])
        const currentData: initDataType = {
            apiId,
            sendPageData: { caOrderStatus, dataStatusType:dataStatusTypeId,cateringOrderModeId, orderDate, sbId: null, roomIds: [], branchStoreId }
        }

        ref.current.initData(currentData)
    }
    return (
        <>

            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div style={{ width: "70%" }}>
                    <Form form={form} initialValues={{dataStatusType:1}}>
                        <Row>
                            <Col span={5}>

                                <Form.Item
                                    name="bookingDate"
                                >
                                    <DatePicker
                                        allowClear
                                        // defaultValue={moment(data.bookingDate)}
                                        format="YYYY-MM-DD"
                                        onChange={handleDate}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={5}>
                                <Form.Item
                                    name="sbId"
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        defaultValue="所有市别"
                                        onChange={sbChange}
                                        placeholder="市别"
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {getList.general(sbData, "sbName")}
                                    </Select>
                                </Form.Item>
                            </Col>


                            <Col span={5}>

                                <Form.Item
                                    name="cateringOrderModeId"
                                >
                                    <Select
                                        // showSearch
                                        allowClear
                                        defaultValue="所有模式"
                                        onChange={cateringOrderModeChange}
                                        placeholder="订单模式"
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {getList.general(cateringOrderModeData)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={5}>

                                <Form.Item
                                    name="roomIds"
                                >
                                    <Select
                                        allowClear
                                        // style={{ width: "300px" }}
                                        onChange={roomChange}
                                        // orderTypeData
                                        mode="multiple"
                                        showSearch
                                        placeholder="请选择房间!"
                                        optionFilterProp="children"
                                        onSearch={(e) => getList.search('room', setRoomData, e, {
                                            andWhereObj: {
                                                branchStoreId: contextData?.currentBranchStoreId, extendObj: {
                                                    menuHeadsIdNum: -1,
                                                    printersIdNum: -1,
                                                }
                                            }
                                        })}
                                        filterOption={(input, option: any) => {
                                            return true
                                        }}
                                    >
                                        {getList.general(roomData, 'roomNum')}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item
                                    name="caOrderStatus"
                                >
                                    <Select
                                        allowClear
                                        defaultValue="所有订单"
                                        onChange={cateringOrderStatusChange}
                                        placeholder="订单状态"
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {getList.general(caOrderStatusData)}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item
                                    name="dataStatusType"
                                >
                                    <Select
                                        defaultValue="数据状态"
                                        onChange={dataStatusTypeChange}
                                        placeholder="数据状态"
                                        optionFilterProp="children"
                                        filterOption={(input, option: any) =>
                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                        {getList.general(dataTypeData)}
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>


                </div>
                <div style={{ width: "30%" , display: "flex", justifyContent: "space-between"}}>
                    <BranchStoreList form={form} onRefresh={onRefresh} setRoomData={setRoomData} setSbData={setSbData} ></BranchStoreList>
                </div>
            </div>


            <WebUniversal props={extendProps} ref={ref} />

            {/**整单打印 */}
            {/* {singlePrintOpen ? (<SinglePrint data={singlePrintData} closeModal={onCloseSinglePrint}
            />)
                : null} */}

            {singlePrintOpen ? (<DrawerDetailList data={singlePrintData} closeModal={onCloseSinglePrint}
            />)
                : null}

            {/**收银订单详情 */}
            {
                castOrderDraweOpen ? (<CastOrderDrawe data={castOrderDraweData} closeModal={onCloseCastOrderDraw}
                />)
                    : null
            }
            {/**订单支付详情 */}
            {
                paymentMethodOpen ? (<PayDetails data={paymentMethodData} closeModal={onClosePaymentMethod}
                />)
                    : null
            }

            {/**发起退款详情 */}
            {
                orderBackOpen ? (<OrderBack data={orderBackData} closeModal={onCloseOrderBack}
                />)
                    : null
            }

            {/**退款详情 */}
            {
                orderRefundDetailsOpen ? (<RefundDetails data={orderRefundDetailsData} closeModal={onCloseRefundDetails}
                />)
                    : null
            }
            {/**发起退款(整单) */}
            {
                refundOrderOpen ? (<RefundOrder data={refundOrderData} closeModal={onCloseRefundOrder}
                />)
                    : null
            }

        </>
    )
}

export default WebComponents;
