import React, { useRef } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import BranchStoreConfigurationEdit from './BranchStoreConfigurationEdit';

export default function BranchStoreFloor(props: any) {
  const { branchStoreId, closeModal } = props;
  const ref = useRef<any>();
  const handleOk = (e: any) => {
    closeModal(true);
  };
  //表头
  const columnsHead = [

    {
      title: '分店',
      dataIndex: 'branchStore',
      render: (text: any) => (text ? text.branchStoreName : "")
    },
    {
      title: '显示库存数',
      dataIndex: 'lessStockNum',
    },
  ];
  const columns = columnsheadId
    .concat(columnsHead as []);
  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '门店配置',
    apiId: 'branchStoreConfiguration',
    showStates: true,
    sendPageData: { branchStoreId },
    columns,
    pagination: true,
    EditComponent: BranchStoreConfigurationEdit,
  };
  return (
    <div>
      <AntDraggableModal
        open={true}
        okText='确定'
        cancelButtonProps={{ style: { display: "none" } }}
        onOk={handleOk}
        closable={false}
        maskClosable={false}
        width={800}>
        <WebUniversalNewEx props={extendProps} ref={ref}></WebUniversalNewEx>
      </AntDraggableModal>
    </div>
  )
}
