import { useRef, useState } from 'react'
import BranchStoreEdit from './BranchStoreEdit';
import WebUniversal, { WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import { Button } from 'antd';
import PaymentList from './PaymentList';
import BranchStoreFloor from './BranchStoreFloor';
import DiningMethod from './DiningMethod';
import ParkingLot from './ParkingLot';
import BranchStoreUploadFile from './BranchStoreUploadFile';
import BranchStoreConfiguration from './BranchStoreConfiguration';

export default function BranchStore(props: any) {
    /** api服务id */
    const apiId = 'branchStore';
    const [modalPaymentList, setModalPaymentList] = useState(false);

    const [modalBranchStoreFloor, setModalBranchStoreFloor] = useState(false);
    const [modalDiningMethod, setModalDiningMethod] = useState(false);

    const [modalParkingLot, setModalParkingLot] = useState(false);

  const [modalBranchStoreConfiguration,setModalBranchStoreConfiguration]=useState<boolean>(false)
    const [branchStoreId, setBranchStoreId] = useState<string>();
    const ref = useRef<any>();

    //表头
    const columnsHead = [
        {
            title: '名称',
            dataIndex: 'branchStoreName',
        },
        {
            title: '区域',
            dataIndex: 'area',
            render: (text: any, record: any) => (text ? text.areaName : "")
        },
        {
            title: '品牌',
            dataIndex: 'brand',
            render: (text: any) => (text ? text.brandName : "")
        },

        {
            title: '联系人',
            dataIndex: 'contacts',
        },
        {
            title: '联系电话',
            dataIndex: 'contactCall',
        },

        {
            title: '楼层',
            dataIndex: '',
            render: (text: any, record: any) => {
                return (<Button onClick={() => handleFloor(record)} type="primary" size="small">楼层</Button>)
            }
        },
        {
            title: '支付方式',
            dataIndex: '',
            render: (text: any, record: any) => {
                return (<Button onClick={() => handlePaymentList(record)} type="primary" size="small">支付方式</Button>)
            }
        },
        {
            title: '就餐方式',
            dataIndex: '',
            render: (text: any, record: any) => {
                return (<Button onClick={() => handleDiningMethod(record)} type="primary" size="small">就餐方式</Button>)
            }
        },
        {
            title: '停车场',
            dataIndex: '',
            render: (text: any, record: any) => {
                return (<Button onClick={() => handleParkingLot(record)} type="primary" size="small">停车场</Button>)
            }
        },
        {
            title: '配置',
            dataIndex: '',
            render: (text: any, record: any) => {
                return (<Button onClick={() => handleBranchStoreConfiguration(record)} type="primary" size="small">配置</Button>)
            }
        }
    ];

    /**查看分店支付方式 */
    const handlePaymentList = (record: any) => {
        setBranchStoreId(record.id)
        setModalPaymentList(true)

    }

    /**查看分店楼层 */
    const handleFloor = (record: any) => {
        setBranchStoreId(record.id)
        setModalBranchStoreFloor(true)

    }

    /**查看分店就餐方式 */
    const handleDiningMethod = (record: any) => {
        setBranchStoreId(record.id)
        setModalDiningMethod(true)

    }

    /**查看停车场 */
    const handleParkingLot = (record: any) => {
        setBranchStoreId(record.id)
        setModalParkingLot(true)

    }
       /**查看门店配置 */
       const handleBranchStoreConfiguration = (record: any) => {
        setBranchStoreId(record.id)
        setModalBranchStoreConfiguration(true)

    }
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '分店',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        showImage: true,
        showUploadFile: true,
        arrayFilesName: 'branchStoreFiles',
        UploadFileComponent: BranchStoreUploadFile,
        EditComponent: BranchStoreEdit
    };
    /** 子组件回调函数，关闭分店支付方式 */
    const onCloseModalEm = (status: boolean) => {
        setModalPaymentList(false);
        if (status) {
            ref.current.initData()
        }
    };
    /** 子组件回调函数，关闭分店楼层 */
    const onCloseBranchStoreFloor = (status: boolean) => {
        setModalBranchStoreFloor(false);
        if (status) {
            ref.current.initData()
        }
    };
    /** 子组件回调函数，关闭就餐方式 */
    const onCloseDiningMethod = (status: boolean) => {
        setModalDiningMethod(false);
        if (status) {
            ref.current.initData()
        }
    };
    /** 子组件回调函数，关闭停车场 */
    const onCloseParkingLot = (status: boolean) => {
        setModalParkingLot(false);
        if (status) {
            ref.current.initData()
        }
    };
      /** 子组件回调函数，关闭停车场 */
      const onCloseBranchStoreConfiguration = (status: boolean) => {
        setModalBranchStoreConfiguration(false);
        if (status) {
            ref.current.initData()
        }
    };
    return (
        <>
            <WebUniversal ref={ref} props={extendProps} />

            {modalPaymentList ? (<PaymentList
                closeModal={onCloseModalEm}
                branchStoreId={branchStoreId}
                isModel={true}
            ></PaymentList>) : null}

            {modalBranchStoreFloor ? (<BranchStoreFloor
                closeModal={onCloseBranchStoreFloor}
                branchStoreId={branchStoreId}
                isModel={true}
            ></BranchStoreFloor>) : null}

            {modalDiningMethod ? (<DiningMethod
                closeModal={onCloseDiningMethod}
                branchStoreId={branchStoreId}
                isModel={true}
            ></DiningMethod>) : null}

            {modalParkingLot ? (<ParkingLot
                closeModal={onCloseParkingLot}
                branchStoreId={branchStoreId}
                isModel={true}
            ></ParkingLot>) : null}

            {modalBranchStoreConfiguration ? (<BranchStoreConfiguration
                closeModal={onCloseBranchStoreConfiguration}
                branchStoreId={branchStoreId}
            ></BranchStoreConfiguration>) : null}
        </>
    )
}
