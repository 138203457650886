import React, { useEffect, useState,useContext } from 'react'
import fetchApi from '../../../utils/api/fetchApi'
import JSZip from 'jszip';
import { toPng } from 'html-to-image';
import system from '../../../config/systematic';
import { Button, Card, ColorPicker, List, QRCode, Space } from 'antd';
import { SocketContext } from '../../../App';
export default function RoomQrCode() {
    /**后端数据vmService */
    const wmService = system.wmService;

    const [rows, setRows] = useState<Array<any>>([]);
    const [color, setColor] = useState<any>("#000000");
    const [bgColor, setBgColor] = useState<any>("#ffffff");
    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;
    useEffect(() => {

        const fetchData = async () => {

            initData()


        }
        fetchData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    const initData = () => {
        fetchApi.GET(wmService, {
            apiId: "room",
            apiExtend: 'showList',
            apiData: {
                branchStoreId:contextData ? contextData?.currentBranchStoreId : null,
                mountDataOptions: {
                    userMount: {
                        isDept: true,
                    }
                },
                extendObj: {
                    menuHeadsIdNum: -1,
                    printersIdNum: -1,
                    sitDownDining: 1
                }
            }
        }).then(res => {
            if (res.success) {

                setRows(res.data)
            }
        })
    }
    const downloadQRCode = (id: string) => {
        const canvas = document.getElementById(id)?.querySelector<HTMLCanvasElement>('canvas');
        if (canvas) {
            const url = canvas.toDataURL();
            const a = document.createElement('a');
            a.download = `${id}.png`;
            a.href = url;
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    };
    const insertAndCompressQrCodes = async () => {
        if (rows.length === 0) return false
        const zip = new JSZip();
        const folderName: string = `二维码`;
        let folder = zip.folder(folderName)!
        const imageFormat = 'png';
        for (let i = 0; i < rows.length; i++) {
            let qrCodeId = rows[i].qrCodeId;
            if (qrCodeId) {
                const qrCodeDataItem = rows[i];
                const qrCodeElement = document.getElementById(`${qrCodeDataItem.roomNum}`);
                const imageDataUrl = await toPng(qrCodeElement!); // 替换为实际的图片格式转换方法
                folder.file(`${rows[i].roomNum}.${imageFormat}`, imageDataUrl.split(',')[1], { base64: true });
            }
        }
        zip.generateAsync({ type: 'blob' }).then((content) => {
            // 下载压缩文件
            const downloadLink = document.createElement('a');
            downloadLink.href = URL.createObjectURL(content);
            downloadLink.download = '二维码.zip';
            downloadLink.click();
        });
    };
    /**生成二维码 */
    const createQRCode = (item: any) => {
        const { id, roomName, roomNum, roomType } = item;
        const { branchStoreId } = roomType;
        let qrData = {
            roomName,
            roomNum,
            branchStoreId,
            roomId: id
        }
        fetchApi.POST(wmService, {
            apiId: "qrCode",
            apiExtend: 'createQrCode',
            apiData: {
                branchStoreId,
                queryId: id,
                qrCodeTypeId: "room",
                qrData: JSON.stringify(qrData)
            }

        }).then(res => {
            if (res.success) {
                initData()
            }
        }).catch((err) => {
            console.log(err);

        })

    }

    /**批量生成二维码 */
    const batchQRCode = () => {
        let insertData: Array<any> = [];
        rows.forEach((item) => {
            if (!item.qrCodeId) {
                let queryId = item.id;
                let branchStoreId = item.roomType.branchStoreId;
                let roomName = item.roomName;
                let roomNum = item.roomNum;

                let qrData = {
                    roomName,
                    roomNum,
                    branchStoreId,
                    roomId: queryId
                }

                insertData.push({
                    queryId,
                    branchStoreId,
                    qrData: JSON.stringify(qrData)
                })
            }

        })

        if (insertData.length === 0) return false
        fetchApi.POST(wmService, {
            apiId: "qrCode",
            apiExtend: 'batchCreateQrCode',
            apiData: {
                qrCodeTypeId: "room",
                data: insertData
            }

        }).then(res => {
            if (res.success) {
                initData()
            }
        }).catch((err) => {
            console.log("err", err);

        })

    }
    const onChangeBgColor=(color:any,a:any)=>{
        setBgColor(color.toHexString())
        
    }
    const onChangeColor=(color:any,a:any)=>{
        setColor(color.toHexString())
        
        
    }
    return (
        <>
            <Space>
                <Button onClick={insertAndCompressQrCodes}>一键下载</Button>
                <Button onClick={batchQRCode}>批量生成二维码</Button>
                <span>背景颜色: </span>
                <ColorPicker disabledAlpha  onChange={onChangeBgColor} />
                <span>字体颜色:</span>
                <ColorPicker disabledAlpha   onChange={onChangeColor} />
            </Space>

            <List
                dataSource={rows}
                grid={{ gutter: 20, column: 4 }}
                renderItem={(item) => {
                    return <List.Item>
                        <Card
                            actions={[
                                <Button disabled={item.qrCodeId ? false : true} onClick={() => downloadQRCode(item.roomNum)} color="default" variant="text">
                                    下载
                                </Button>,
                                <Button disabled={item.qrCodeId ? true : false} color="default" variant="text" onClick={() => createQRCode(item)}>生成二维码</Button>
                            ]}
                        >
                            <div id={item.roomNum}>
                                <QRCode color={color} bgColor={bgColor} size={300} style={{ textAlign: "center", margin: "0 auto" }} value={item.qrCode ? `${item.qrCode?.qrCodeUrl}qrCodeId=${item.qrCodeId}` : ''}></QRCode>
                            </div>
                            <div style={{ textAlign: "center", margin: "0 auto" }}>房号:{item.roomNum}</div>
                        </Card>
                    </List.Item>
                }}
            />
        </>

    )
}
