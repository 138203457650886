import { Button, Space, Switch } from 'antd';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import QueuingSettingEdit from './QueuingSettingEdit';
import CallWay from './CallWay';
import { useState } from 'react';

export default function QueuingSetting(props: any) {

    // 叫号方式窗口
    const [callWayVisible, setCallWayVisible] = useState(false);

    // 叫号方式数据
    const [callWayData, setCallWayData] = useState<any>();

    //  设置叫号方式
    const HandleCallWay = (record: any) => {
        sendData.queuingSettingId = record.id;
        setCallWayData(sendData);
        setCallWayVisible(true);
    }

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '排队规则',
            dataIndex: 'rules',
        },
        {
            title: '叫号规则',
            dataIndex: 'callRules',
        },
        {
            title: '叫号方式',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => HandleCallWay(record)}>叫号方式</Button>
                    </Space>
                )
            }
        },
        {
            title: '微信拿号',
            dataIndex: 'isWx',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) => handleChange(record.id, 'isWx', e)}
                />
            )
        },
        {
            title: '跨市别拿号',
            dataIndex: 'isMoreDay',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) => handleChange(record.id, 'isMoreDay', e)}
                />
            )
        },
        {
            title: '提前拿号时间',
            dataIndex: 'earlyTime',
            render: (text: any, record: any) => {
                return (
                    <div>{record.earlyTime + "分钟"}</div>
                )
            }
        },
    ];

    //传关参数
    let sendData = {
        type: 'EDIT',
        queuingSettingId: '',
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '排队设置',
        apiId: 'queuingSetting',
        showStates: true,
        showQuery: false,
        columns,
        pagination: true,
        EditComponent: QueuingSettingEdit,
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>

            {callWayVisible
                ? (<CallWay
                    closeModal={setCallWayVisible} data={callWayData} />)
                : null}
        </>

    )
}