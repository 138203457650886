import { Button, Checkbox, Col, Drawer, Form, Input, InputNumber, Popconfirm, Popover, QRCode, Radio, Row, Select, Space, Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi'
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import getList from '../../universal/getList';
import moment from 'moment';
import SetAccounts from '../cateringOrder/SetAccounts';
import CateringOrderPrint from '../cateringOrder/CateringOrderPrint';

import MoveRoom from './MoveRoom';
import SingleDiscount from './SingleDiscount';
import "./CastOrderDrawe.css"
import arrayHandle from '../../../utils/baseLib/arrayHandle';
import DrawerDetailList from './DrawerDetailList';

/**收银订单详情 */
export default function CastOrderDrawe(props: any) {

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    let { data, closeModal } = props;

    let formName = "cateringOrderDetails";
    const [form] = Form.useForm();
    const formListData = Form.useWatch(formName, form) as any;

    const [isRemoveVegetablesNew, setIsRemoveVegetablesNew] = useState<boolean>(true);

    const [orderData, setOrderData] = useState<any>()



    const [cateringOrderPrintData, setCateringOrderPrintData] = useState<any>();
    const [cateringOrderPrintOpen, setCateringOrderPrintOpen] = useState<boolean>(false);
    const [singlePrintData, setSinglePrintData] = useState<any>();
    const [singlePrintOpen, setSinglePrintOpen] = useState<boolean>(false);

    const [setAccountData, setSetAccountsData] = useState<any>();
    const [setAccountsOpen, setSetAccountsOpen] = useState<boolean>(false);

    const [moveRoomData, setMoveRoomData] = useState<any>();
    const [moveRoomOpen, setMoveRoomOpen] = useState<boolean>(false);

    const [singleDiscountOpen, setSingleDiscountOpen] = useState<boolean>(false);
    const [singleDiscountDate, setSingleDiscountDate] = useState<any>();


    const [isTea, setIsTea] = useState<boolean>(false);

    const [deleteData, setDeleteData] = useState<Array<any>>([]);


    //初始化时使用
    /**菜品数据 */
    const [menuSpeData, setMenuSpeData] = useState<any>();

    /**菜品特殊规格数据 */
    const [menuSpecSpecialData, setMenuSpecSpecialData] = useState<any>();

    /**菜品加料数据 */
    const [menuCondimentsData, setMenuCondimentsData] = useState<any>();


    /**选中的数据 */
    const [isCheckData, setIsCheckData] = useState<Array<any>>([]);

    const [saveLoading, setSaveLoading] = useState<boolean>(false)


    useEffect(() => {

        if (data.type === "EDIT") {
            initData()
        }

        const fetchData = async () => {

        }
        fetchData()
        window.addEventListener('keydown', (e) => CastShortcutKey(e)) // 添加全局事件
        return () => {
            window.removeEventListener('keydown', (e) => CastShortcutKey(e)); // 销毁
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const CastShortcutKey = (e: any) => {

        switch (e.key) {
            /**新增菜品 */
            case "F2":
                customizeAdd(bb)

                break;

            /**打印 */
            case "F5":
                handleSinglePrint(data.id)
                break;
            /**移台 */
            case "F6":
                handleMoveRoom(data)
                break;
            /**预结 */
            case "F7":
                handleCateringOrderPrint(data.id);
                break;
            // /**保存 */
            // case "F8":

            //     onSave()
            //     break;


            /**整单打折 */
            case "F9":
                handleSingleDiscount(data.id)
                break;

            /**结账 */
            case "F11":
                handleSetAccount(data.id)
                break;
        }
    }
    //初始化订单数据
    const initData = async (id?: string) => {

        fetchApi.GET(wmService, {
            apiId: "cateringOrder",
            apiExtend: "showSingleExEx",
            apiVariable: data.id ? data.id : id,
            apiData: {
                noCancel: true
            }

        }).then((res) => {
            setDeleteData([]);
            data.id = res.data.id;
            data.type = "EDIT";
            if (res.success) {
                setOrderData({
                    // diningId:res.data.diningId,
                    orderPaymentModeId: res.data.orderPaymentModeId
                })


                let menuSpeObj: any = {};
                /** 特殊规格*/
                let coSpecSpecialsObj: any = {};
                let coSpecSpecialsIdObj: any = {};
                /**加料 */
                let coCondimentsObj: any = {};

                /**选中加料id */
                let menuCondimentIds: any = {};

                //自定义formList数据初始状态
                let newData: Array<any> = [];
                let data = res.data[formName];

                let isTeaEx = false;
                let totalDiscountPrice = 0;
                let totalGiftPrice = 0;
                for (var i = 0; i < data.length; i++) {
                    let coMonStatus = data[i].coMonStatus;
                    if (data[i].menuSpec.menu.branchStoreMenuWarehouse.foodType === "tea") isTeaEx = true
                    menuSpeObj[i] = [{
                        id: data[i].menuSpecId,
                        value: data[i].menuSpecId,
                        menuHeadName: data[i].menuHeadName,
                        label: `${data[i].menuName} ${data[i].menuSpecName}`,
                        name: `${data[i].menuName} ${data[i].menuSpecName}`
                    }
                    ]

                    //特殊规格
                    let newSpecSpecials: Array<string> = [];
                    let speSpePrice = 0;
                    let newSpecSpecialsData = data[i].coSpecSpecials.map((item: any) => {
                        speSpePrice += item.price;
                        newSpecSpecials.push(item.menuSpecSpecialId)
                        return {
                            id: item.menuSpecSpecialId,
                            value: item.menuSpecSpecialId,
                            label: item.menuSpecSpecialName,
                            name: item.menuSpecSpecialName,

                        }
                    })

                    coSpecSpecialsObj[i] = newSpecSpecialsData;
                    coSpecSpecialsIdObj[i] = newSpecSpecials;

                    /**加料 */
                    let newCondimentsId: Array<string> = [];
                    let coPrice = 0;
                    let newCondimentsData = data[i].coCondiments.map((item: any) => {
                        coPrice += item.price;
                        newCondimentsId.push(item.menuCondimentId)

                        let name = item.condimentWarehouseName;
                        return {
                            value: item.menuCondimentId,
                            id: item.menuCondimentId,
                            name,
                            label: name,

                        }
                    })


                    coCondimentsObj[i] = newCondimentsData;
                    menuCondimentIds[i] = newCondimentsId
                    let discountPrice = data[i].discount * data[i].advancePrice;
                    if (coMonStatus === 1) totalDiscountPrice += discountPrice;
                    if (coMonStatus === 2) totalGiftPrice += discountPrice;

                    newData.push({
                        isCal: true,
                        ...data[i],
                        isMandatory: data[i].menuSpec.isMandatory,
                        coPrice,
                        originAmount: data[i].amount,
                        speSpePrice,
                        spePrice: data[i].price,
                        discount: data[i].discount * 100,
                        isChecked: false,
                        discountPrice: discountPrice.toFixed(2),
                        isReturnInventory: true,
                        caMenuStatusName: data[i].caMenuStatusName,
                        menuCondimentIds: menuCondimentIds[i],
                        menuSpecSpecialsId: coSpecSpecialsIdObj[i],
                        state: "origin",
                    })
                }

                let caOrderStatus = res.data.caOrderStatus;
                let forecastPrice = 0;
                let giftPrice = 0;
                switch (caOrderStatus) {
                    case 998:
                        forecastPrice = res.data.shouldMoney;
                        giftPrice = res.data.giftPrice;
                        break;
                    case 999:
                        forecastPrice = res.data.realMoney;
                        giftPrice = res.data.giftPrice;
                        break;
                    default:
                        forecastPrice = totalDiscountPrice;
                        giftPrice = totalGiftPrice;
                        break;
                }
                if (caOrderStatus === 998 || caOrderStatus === 999) {

                } else {

                }

                setIsTea(isTeaEx)

                /**菜品 */
                setMenuSpeData(menuSpeObj)

                /**特殊规格 */
                setMenuSpecSpecialData(coSpecSpecialsObj)

                /**加料 */
                setMenuCondimentsData(coCondimentsObj)

                setIsCheckData(newData)
                /**备注 */
                form.setFieldsValue({
                    // ...res.data, 
                    id: res.data.id,
                    payCodeUrl: res.data.payCodeUrl,
                    roomNum: res.data.room.roomNum,
                    sbName: res.data.sb.sbName,
                    suCateringOrderId: res.data.suCateringOrderId,
                    state: "origin",
                    createdAt: moment(res.data.sysDate.createdAt).format("YYYY-MM-DD HH:mm:ss"),
                    customerName: res.data.customerName,
                    headCount: res.data.headCount,
                    ["cateringOrderDetails"]: newData,
                    caOrderStatusName: res.data.caOrderStatusName,
                    caOrderStatus,
                    transferPrice: 0,
                    forecastPrice,
                    giftPrice,

                }
                )
                // if (isCateringOrderPrint) handleCateringOrderPrint(res.data.id, { isTea: isTeaEx,caOrderStatus })
            }

        }).catch((err) => {
            console.log("错误", err);
            if (err.errors) {
                const { status } = err.errors;
                if (status === 601) closeModal(true)
            }
        })


    }

    /**取消预结 */
    const reversePreknot = (id: string) => {

        if (!id) return false;
        let caOrderStatus = form.getFieldValue("caOrderStatus");
        if (caOrderStatus !== 998) return false
        fetchApi.POST(wmService, {
            apiId: "cateringOrder",
            apiExtend: "reversePreknot",
            apiData: {
                id
            }
        }).then((res) => {
            initData()
            antMessage("success", '取消预结成功', "成功", { duration: 0.5 });
        }).catch((err) => {
            antMessage('error', '取消预结失败', err.message);
        })
    }
    /**打开订单预结页面 */
    const handleCateringOrderPrint = (id: string, apiData?: any) => {
        if (!id) return false
        let caOrderStatus = form.getFieldValue("caOrderStatus");
        if (!caOrderStatus) caOrderStatus = apiData.caOrderStatus

        if (caOrderStatus === 2 || caOrderStatus === 998 || caOrderStatus === -998 || caOrderStatus === 997) {

        } else {
            antMessage("error", '无法打开订单预结页面,该订单处于已下单/已取消/已完成状态', "错误")
            return false
        }


        /**锁定帐单 防止楼面跟收银同时操作*/
        /**如果本身就是预结状态的就不用管 */
        // if (caOrderStatus !== 998) {
        //     console.log("没执行");

        //     fetchApi.POST(wmService, {
        //         apiId: "cateringOrder",
        //         apiExtend: 'modifiedOrderState',
        //         apiData:{
        //              id,
        //              caOrderStatus:998
        //         }
        //     }).then(res=>{
        //          console.log("res",res);

        //     })
        // }
        setCateringOrderPrintOpen(true)
        setCateringOrderPrintData({ id, isIo: data.isIo, isTea,isReverseClosing:true, ...apiData, orderPaymentModeId: orderData.orderPaymentModeId })
    }
    //关闭订单预结页面
    const onCloseCateringOrderPrint = async (status: boolean) => {
        setCateringOrderPrintOpen(false);
        await initData()
        if (status) {
            closeModal(true)
        }
    }

    /**进行反结账操作 */
    const reverseClosing = (id: string) => {
        if (!id) return false;
        let caOrderStatus = form.getFieldValue("caOrderStatus");
        if (caOrderStatus !== 999) return false;
        fetchApi.POST(wmService, {
            apiId: "cateringOrder",
            apiExtend: "reverseClosing",
            apiData: {
                id
            }
        }).then(async (res) => {

            if (res.success) {
                antMessage("success", '反结账成功', "成功", { duration: 0.5 });
                await initData()
            }

        }).catch((err) => {

            antMessage('error', '反结账失败', err.message, { duration: 0.5 });
            console.log("err", err);

        })


    }

    /**打开整单打印 */
    const handleSinglePrint = (id: string) => {
        if (!id) return false
        let caOrderStatus = form.getFieldValue("caOrderStatus")
        let roomNum = form.getFieldValue("roomNum")

        setSinglePrintOpen(true)
        setSinglePrintData({ id, caOrderStatus, caMenuStatus: [2, 3, 999], type: "noPending", roomNum, orderPaymentModeId: orderData.orderPaymentModeId })
    }
    //关闭整单打印
    const onCloseSinglePrint = async (status: boolean) => {
        setSinglePrintOpen(false);
        await initData()

    }
    /**打开结账页面 */
    const handleSetAccount = (id: string) => {
        let caOrderStatus = form.getFieldValue("caOrderStatus")
        if (caOrderStatus !== 998) {
            antMessage("error", '无法打开结账页面,该订单不处于预结状态', "错误")
            return false;
        }
        if (!id) return false
        setSetAccountsOpen(true)
        setSetAccountsData({ id, tempRoomId: data.tempRoomId })
    }
    //关闭结账页面
    const onCloseSetAccount = async (status: boolean) => {
        setSetAccountsOpen(false);
        initData()
        if (status) {
            closeModal(true)
        }
    }

    //移台
    const handleMoveRoom = (data: any) => {

        if (!data.id || !data.tempRoomId || !data.timeTypeId) return;
        let roomNum = form.getFieldValue("roomNum")
        const { roomStatus } = data;
        setMoveRoomOpen(true)
        setMoveRoomData({ timeTypeId: data.timeTypeId, id: data.id, roomStatus, orTempRoomId: data.tempRoomId, roomNum, isCheckedId: data.isCheckedId, isRemoveVegetablesNew })

    }
    //关闭移台页面
    const onCloseMoveRoom = async (status: boolean, isRet?: boolean) => {
        setMoveRoomOpen(false);
        initData()
        if (status) {
            closeModal(true)
        }
    }


    const onChangeCustomerName = () => {
        form.setFieldsValue({ ...form.getFieldsValue(), state: "update" })
    }

    /**更改人数 */
    const onChangeHeadCount = () => {

        form.setFieldsValue({ ...form.getFieldsValue(), state: "update" })
    }

    /**选择规格菜品 */
    const getSpeAndMemoAndCom = (e: any, option: any, name: any) => {
        //获取该菜的单价并重新计算价格并重新赋予特殊规格等
        fetchApi.GET(wmService, {
            apiId: "menuSpec",
            apiExtend: "getSpeAndMemoAndCom",
            apiVariable: e
        }).then((res) => {

            let isMandatory = res.data.isMandatory;
            let data = res.data.menu;
            //重构特殊规格数据
            const newMenuSpecSpecials = data.menuSpecs.map((item: any) => ({
                label: item.menuSpecName,
                value: item.id,
                id: item.id,
                options: item.menuSpecSpecials.map((item1: any) => {
                    let quantity = item1.branchStoreMenuWarehouseSpecSpecial?.inventory ? item1.branchStoreMenuWarehouseSpecSpecial.inventory.quantity : 99999
                    return {
                        id: item1.id,
                        name: item1.menuSpecSpecialName,
                        speSpePrice: res.data.price,
                        price: item1.price,
                        label: <div>{item1.menuSpecSpecialName} 价格:{item1.price} 库存:{quantity}</div>,
                        value: item1.id,
                        disabled: quantity === 0 ? true : false
                    }
                })
            }));
            //重构加料数据
            const newMenuCondiments = data.menuCondiments.map((item: any) => {
                let quantity = item.condimentWarehouse.inventory ? item.condimentWarehouse.inventory.quantity : 99999;
                let price = item.price + item.condimentWarehouse.price;
                let name = item.condimentWarehouse.condimentWarehouseName;
                return {
                    value: item.id,
                    id: item.id,
                    price: price,
                    speSpePrice: res.data.price,
                    name,
                    label: <div>{name} 价格:{price} 库存:{quantity}</div>,
                    quantity,
                    disabled: quantity === 0 ? true : false
                }
            })



            //赋予特殊规格数据
            setMenuSpecSpecialData({ ...menuSpecSpecialData, [name]: newMenuSpecSpecials })
            //赋予加料数据
            setMenuCondimentsData({ ...menuCondimentsData, [name]: newMenuCondiments })

            form.setFieldValue([formName, name, "minDiscount"], res.data.minDiscount)
            form.setFieldValue([formName, name, "amount"], 1)






            //重置数据
            form.setFieldValue([formName, name, "menuCondimentIds"], []);
            form.setFieldValue([formName, name, "menuSpecSpecialsId"], []);

            form.setFieldValue([formName, name, "coPrice"], 0)
            form.setFieldValue([formName, name, "speSpePrice"], 0)

            //赋予数据
            const newData = form.getFieldValue([formName, name])

            let { amount, discount, speSpePrice, coPrice } = newData;
            discount = discount ? discount : 100;
            amount = amount ? amount : 0;
            speSpePrice = speSpePrice ? speSpePrice : 0;
            coPrice = coPrice ? coPrice : 0;

            let advancePrice = amount * (res.data.price + speSpePrice + coPrice);
            let discountPrice = advancePrice * (discount / 100);

            let isPrint = res.data.menu.branchStoreMenuWarehouse?.isPrint

            form.setFieldValue([formName, name, "discount"], discount)
            form.setFieldValue([formName, name, "spePrice"], res.data.price)
            form.setFieldValue([formName, name, "isPrint"], isPrint)
            form.setFieldValue([formName, name, "isMandatory"], isMandatory)




            form.setFieldValue([formName, name, "discountPrice"], discountPrice.toFixed(2))
            form.setFieldValue([formName, name, "advancePrice"], advancePrice.toFixed(2))









        }).catch((err) => {
            console.log("err", err);

            // setMenuSpecSpecialData({
            //     ...menuSpecSpecialData,
            //     [name]: []
            // })
        })


    }
    //remove 删除
    const removeList = async (remove: Function, num: number, key: number) => {
        //即将删除的数据
        let removeData = form.getFieldsValue()[formName!][num];
        if (removeData && removeData.id) {
            // setListDeleteData([...listDeleteData, { ...removeData, state: "delete" }])
            setDeleteData(
                [...deleteData, { ...removeData, state: "delete" }]
            )
        }
        remove(num)
        let lData = arrayHandle.deleteValue(isCheckData, "id", removeData.id)
        delete menuSpeData[num]
        // delete isCheckData[num]
        delete menuSpecSpecialData[num]
        delete menuCondimentsData[num];
        const newMenuSpeData: any = {};
        const newMenuSpecSpecialData: any = {};

        const newMenuCondimentsData: any = {};

        let newIndex1 = 0;
        let newIndex2 = 0;
        let newIndex3 = 0;


        for (const key in menuSpeData) {
            newMenuSpeData[newIndex1] = menuSpeData[key];
            newIndex1++;
        }

        for (const key in menuSpecSpecialData) {
            newMenuSpecSpecialData[newIndex3] = menuSpecSpecialData[key];
            newIndex3++;
        }

        for (const key in menuCondimentsData) {
            newMenuCondimentsData[newIndex2] = menuCondimentsData[key];
            newIndex2++;
        }


        // let newMenuSpeData=menuSpeData.
        setMenuSpeData(newMenuSpeData)
        setMenuSpecSpecialData(newMenuSpecSpecialData)
        setMenuCondimentsData(newMenuCondimentsData)
        setIsCheckData(lData)



    }

    const customizeAdd = (add: any) => {

        add()
        let name: number = 0;
        if (form.getFieldsValue()[formName]) {
            name = form.getFieldsValue()[formName].length - 1
        }


        // console.log("dasdasdas",form.getFieldsValue()[formName].length - 1);

        form.setFieldValue([formName, name], { state: "insert" })
    }
    const formatPercentage = (value: any) => {

        return `${Number(value).toFixed(0)}%`;
    };

    const parsePercentage = (value: any) => {

        return parseFloat(value.replace('%', ''));
    };
    //改变折扣
    const changeDiscount = (e: any, name: any) => {

        let data = form.getFieldValue([formName, name])

        let discountPrice: any = 0;
        let discount = e / 100;
        // console.log();
        let { state, speSpePrice, spePrice, coPrice, advancePrice, amount, isMandatory } = data;
        let amountEx = amount ? isMandatory ? 1 : amount : 0;

        if (state === "insert") {
            let advancePrice = spePrice * amount + (((speSpePrice ? speSpePrice : 0) + (coPrice ? coPrice : 0)) * amountEx);
            discountPrice = advancePrice * discount;

            form.setFieldValue([formName, name, "discountPrice"], discountPrice.toFixed(2))
            form.setFieldValue([formName, name, "advancePrice"], advancePrice.toFixed(2))

        } else {

            discountPrice = ((Number(e) / 100) * Number(advancePrice))

            form.setFieldValue([formName, name], { ...form.getFieldsValue()[formName!][name], discountPrice: discountPrice.toFixed(2), state: "update" })
        }



    }

    //改变数量
    const changeNum = (e: any, name: any) => {

        let data = form.getFieldValue([formName, name])

        let advancePriceEx = 0;
        let discountPriceEx = 0;
        if (e) {
            let { discount, speSpePrice, spePrice, coPrice, state, isMandatory } = data;

            discount = discount ? discount / 100 : 1;
            speSpePrice = speSpePrice ? speSpePrice : 0;
            coPrice = coPrice ? coPrice : 0;
            let amountEx = e ? isMandatory ? 1 : e : 0
            advancePriceEx = e * spePrice + ((speSpePrice + coPrice) * amountEx);

            discountPriceEx = advancePriceEx * discount;
            if (state === "insert") {

            } else {
                form.setFieldValue([formName, name, "state"], "update")
                // form.setFieldValue([formName, name, "isChangeNum"], true)
            }
        }




        form.setFieldValue([formName, name, "discountPrice"], discountPriceEx.toFixed(2))
        form.setFieldValue([formName, name, "advancePrice"], advancePriceEx.toFixed(2))



    }


    //改变特殊规格
    const onChangeSpeSpe = (e: any, option: any, name: any) => {
        const newData = form.getFieldValue([formName, name])
        let { amount, discount, coPrice, spePrice, isMandatory } = newData;


        let advancePrice = 0;
        let discountPrice = 0;
        amount = amount ? amount : 0;
        let amountEx = amount ? isMandatory ? 1 : amount : 0
        discount = discount ? discount / 100 : 1
        if (option.length === 0) {
            form.setFieldValue([formName, name, "speSpePrice"], 0)
            advancePrice = spePrice * amount + ((coPrice ? coPrice : 0) * amountEx);
            discountPrice = advancePrice * discount;
            form.setFieldValue([formName, name, "discountPrice"], discountPrice.toFixed(2))
            form.setFieldValue([formName, name, "advancePrice"], advancePrice.toFixed(2))
        } else {
            let speSpePrice = 0;
            for (var i = 0; i < option.length; i++) {
                speSpePrice += option[i].price;
            }

            advancePrice = spePrice * amount + ((speSpePrice + (coPrice ? coPrice : 0)) * amountEx);
            discountPrice = advancePrice * discount;


            //    let discountPrice=advancePrice*(discount/100);
            form.setFieldValue([formName, name, "speSpePrice"], speSpePrice)
            form.setFieldValue([formName, name, "discountPrice"], discountPrice.toFixed(2))
            form.setFieldValue([formName, name, "advancePrice"], advancePrice.toFixed(2))
        }





    }

    //改变加料
    const onChangeCondiments = (e: any, option: any, name: any) => {
        const newData = form.getFieldValue([formName, name])
        let { amount, discount, speSpePrice, spePrice, isMandatory } = newData
        let advancePrice = 0;
        let discountPrice = 0;
        amount = amount ? amount : 0
        discount = discount ? discount / 100 : 1;
        let amountEx = amount ? isMandatory ? 1 : amount : 0
        if (option.length === 0) {
            form.setFieldValue([formName, name, "coPrice"], 0)
            advancePrice = spePrice * amount + ((speSpePrice ? speSpePrice : 0) * amountEx)
            discountPrice = advancePrice * discount;
            form.setFieldValue([formName, name, "discountPrice"], discountPrice.toFixed(2))
            form.setFieldValue([formName, name, "advancePrice"], advancePrice.toFixed(2))
        } else {
            let coPrice = 0;

            for (var i = 0; i < option.length; i++) {
                coPrice += option[i].price;

            }
            advancePrice = spePrice * amount + ((coPrice + (speSpePrice ? speSpePrice : 0)) * amountEx);
            discountPrice = advancePrice * discount;

            form.setFieldValue([formName, name, "coPrice"], coPrice)
            form.setFieldValue([formName, name, "discountPrice"], discountPrice.toFixed(2))
            form.setFieldValue([formName, name, "advancePrice"], advancePrice.toFixed(2))
        }

    }


    //改变是否打印
    const changePrint = (e: any, name: any) => {
        let dataEx = form.getFieldValue([formName, name])
        const { id } = dataEx;
        if (id) form.setFieldValue([formName, name], { ...dataEx, isPrint: e, state: "update" })
    }


    // //改变是否改变数量
    // const changeisChangeNum= (e: any, name: any) => {
    //     let dataEx = form.getFieldValue([formName, name])
    //     const { id } = dataEx;
    //     if (id) form.setFieldValue([formName, name], { ...dataEx, state: "update" })
    // }



    const onSave = () => {

        setSaveLoading(true)
        form.validateFields()
            .then(async (values: any) => {
                let dataExEx = values.cateringOrderDetails;
                switch (data.type) {
                    case "EDIT":
                        const { caOrderStatus } = values;
                        if (caOrderStatus === 998 || caOrderStatus === 999 || caOrderStatus === -999) {
                            antMessage("warning", '失败', "无法进行操作,当前订单已完成/已取消/预结账");
                            setSaveLoading(false)
                            return false
                        }
                        let upData: Array<any> = [];

                        let inserData: Array<any> = [];
                        dataExEx.forEach((item: any) => {
                            if (item.state === "insert" && item.menuSpecId) {
                                inserData.push(item)
                            }

                            if (item.state === "update" && item.menuSpecId) {

                                upData.push(item)
                            }
                        })
                        // console.log("即将更新", upData);
                        // console.log("即将删除", deleteData);
                        // console.log("即将新增", inserData);

                        //更新订单头操作

                        try {
                            if (values.state === "update") {
                                let dataEx = await fetchApi.POST(wmService, {
                                    apiId: "cateringOrder",
                                    apiExtend: "update",
                                    apiVariable: data.id,
                                    apiData: {
                                        headCount: values.headCount,
                                        customerName: values.customerName
                                    }
                                })
                                if (dataEx.success) {

                                    antMessage("success", '更新订单成功', "成功", { duration: 0.5 });
                                }
                            }


                        } catch (err: any) {
                            antMessage('error', '更新订单错误', err.message);
                        }

                        //执行新增操作
                        if (inserData.length > 0) {
                            try {

                                let dataEx = await fetchApi.POST(wmService, {
                                    apiId: "cateringOrder",
                                    apiExtend: "orderAddMenu",
                                    apiData: {
                                        id: data.id,
                                        // roomId:currentOrder.roomId,
                                        menuDetail: inserData
                                    }
                                })
                                if (dataEx.success) {

                                    antMessage("success", '新增成功', "成功", { duration: 0.5 });
                                }
                            } catch (err: any) {
                                antMessage('error', '新增错误', err.message);
                            }
                        }
                        //执行更新操作

                        if (upData.length > 0) {
                            try {
                                let dataEx = await fetchApi.POST(wmService, {
                                    apiId: "cateringOrder",
                                    apiExtend: "orderUpdateMenu",
                                    apiData: {
                                        id: data.id,
                                        menuDetail: upData
                                    }
                                })


                                if (dataEx.success) {
                                    antMessage("success", '更新成功', "成功", { duration: 0.5 });
                                }
                            } catch (err: any) {
                                antMessage('error', '更新错误', err.message);
                                console.log("err", err);

                            }
                        }
                        //执行删除操作
                        if (deleteData.length > 0) {

                            try {
                                let dataEx = await fetchApi.POST(wmService, {
                                    apiId: "cateringOrder",
                                    apiExtend: "orderDelMenu",
                                    apiData: {
                                        id: data.id,
                                        menuDetail: deleteData
                                    }
                                })
                                if (dataEx.success) {
                                    setDeleteData([])
                                    antMessage("success", '删除成功', "成功", { duration: 0.5 });
                                }
                            } catch (err: any) {
                                antMessage('error', '删除错误', err.message);
                            }

                        }


                        //初始化数据
                        await initData()

                        break;

                    case "NEW":
               
                         
                        fetchApi.POST(wmService, {
                            apiId: "cateringOrder",
                            apiExtend: "createOrder",
                            apiData: {
                                menuData: dataExEx,
                                ...data
                            }
                        }).then(async (res) => {
                            if (res.success) {

                                await initData(res.data.orderId)
                                antMessage("success", '新增成功', "成功", { duration: 0.5 });
                            }
                        }).catch((err) => {
                            console.log("err", err);

                            antMessage('error', '更新错误', err.message,);
                        })


                        break;
                }

                setSaveLoading(false);

            }).catch((err) => {
                antMessage("error", err)
                setSaveLoading(false)
            })

    }


    /**打折 */
    const handleSingleDiscount = (id: string, isForce?: boolean) => {
        if (!id) return false
        let caOrderStatus = form.getFieldValue("caOrderStatus")
        if (caOrderStatus === 998 || caOrderStatus === 999 || caOrderStatus === -999) {
            antMessage("warning", '失败', "无法进行操作,当前订单已完成/已取消/预结账");
            return true
        }
        setSingleDiscountOpen(true)
        setSingleDiscountDate({ id, isForce })

    }


    /**分单 */
    const openOrder = (id: string) => {
        if (!id) return false
        let data = form.getFieldsValue()
        const { caOrderStatus, cateringOrderDetails } = data;

        if (caOrderStatus === 998 || caOrderStatus === 999 || caOrderStatus === -999 || caOrderStatus === 997) {
            antMessage("warning", '失败', "无法进行操作,当前订单已完成/已取消/预结账/已分单");
            return false
        }
        if (cateringOrderDetails.length > 0) {
            if (cateringOrderDetails.length === 1) {
                antMessage("warning", '失败', "菜品数据只剩一个，不可进行拆单操作");
                return false
            }
            let isCheckedData = cateringOrderDetails.filter((item: any) => item.isChecked && item.id)
            if (isCheckedData.length === 0) {
                antMessage("warning", '失败', "不存在勾选数据");
                return false
            }

            fetchApi.POST(wmService, {
                apiId: "cateringOrder",
                apiExtend: "openOrder",
                apiData: {
                    id: data.id,
                    isCheckedData: isCheckedData
                }
            }).then(async (res) => {
                //初始化数据
                await initData()


            }).catch((err) => {
                antMessage("error", '失败', err);
            })

        } else {

            antMessage("warning", '失败', "无菜品数据，无法进行操作");
            return false
        }


    }


    /**移菜(将里面的菜移到另外一张空闲的台，并生成订单) */
    const removeVegetables = (id: string) => {
        if (!id) return false
        let dataEx = form.getFieldsValue();
        const { caOrderStatus, suCateringOrderId, cateringOrderDetails } = dataEx;
        let cateringOrderDetailsEx = cateringOrderDetails.filter((item: any) => item.state !== "insert");

        if (suCateringOrderId) {
            antMessage("warning", '失败', "无法进行操作,该订单存在父单,不允许分菜");
            return false
        }
        if (caOrderStatus !== 2) {
            antMessage("warning", '失败', "无法进行操作,当前订单不处于接单状态");
            return false
        }


        if (isCheckData.length > 0) {
            if (isCheckData.length === 1) {
                antMessage("warning", '失败', "菜品数据只剩一个，不可进行拆单操作");
                return false
            }
            let isCheckedId: Array<string> = [];
            isCheckData.forEach((item: any) => {
                if (item.isChecked) isCheckedId.push(item.id)
            });
            if (isCheckedId.length === 0) {
                antMessage("warning", '失败', "不存在勾选数据");
                return false
            }
            if (cateringOrderDetailsEx.length === isCheckedId.length) {
                antMessage("warning", '失败', "不允许移全部菜，若要移全部菜，请进行移台操作");
                return false
            }

            handleMoveRoom({ ...data, roomStatus: -1, isCheckedId, isRemoveVegetablesNew })
        } else {
            antMessage("warning", '失败', "无菜品数据，无法进行操作");
            return false
        }


        //   fetchApi.POST(wmService,{
        //     apiId:"cateringOrder",
        //     apiExtend:"openOrder",
        //     apiData:{
        //         id:data.id,
        //         isCheckedData:isCheckedData
        //     }
        //   }).then(async (res)=>{
        //        //初始化数据
        //        await initData()
        //        console.log("res",res);

        //   }).catch((err)=>{
        //     antMessage("error", '失败', err);
        //   })


    }



    const closeSingleDiscount = (status: boolean) => {
        setSingleDiscountOpen(false)
        initData()
        if (status) {
            closeModal(true)
        }
    }
    const we = (e: any, name: any) => {

        let checked = e.target.checked;
        let dataEx = form.getFieldValue([formName, name]);
        const { caMenuStatus } = dataEx
        if (caMenuStatus === 1 || caMenuStatus === -999) {
            antMessage("warning", '失败', "此菜品处于未接单/已拒绝状态,无法进行操作", { duration: 0.5 });
            return false
        }
        // let arr: Array<any> = [];
        let newData = { ...dataEx, isChecked: checked };

        // arr.push(newData)
        let lData: Array<any> = [];

        lData = arrayHandle.merge([...isCheckData], [newData]) as Array<any>;

        setIsCheckData(lData)
        let transferPrice = 0;
        lData.forEach((item: any) => {
            if (item.isChecked) transferPrice += Number(item.discountPrice)

        })
        form.setFieldValue("transferPrice", transferPrice)
        form.setFieldValue([formName, name], newData)
    }
    const recoverOrder = (id: string) => {
        if (!id) return false
        let dataEx = form.getFieldsValue();


        const { caOrderStatus, suCateringOrderId } = dataEx;
        if (caOrderStatus !== 2) {
            antMessage("warning", '失败', "无法进行操作,当前订单不处于接单状态", { duration: 0.5 });
            return false
        }
        if (!suCateringOrderId) {
            antMessage("warning", '失败', "无法进行操作,当前订单不存在父单", { duration: 0.5 });
            return false
        }
        if (isCheckData.length > 0) {

            let isCheckedId: Array<string> = [];
            isCheckData.forEach((item: any) => {
                if (item.isChecked) isCheckedId.push(item.id)
            });
            if (isCheckedId.length === 0) {
                antMessage("warning", '失败', "不存在勾选数据", { duration: 0.5 });
                return false
            }


            fetchApi.POST(wmService, {
                apiId: "cateringOrder",
                apiExtend: "recoverOrder",
                apiData: {
                    id,
                    suCateringOrderId,
                    isCheckedId,
                    tempRoomId: data.tempRoomId
                }
            }).then(async (res) => {
                if (res.success) {
                    antMessage("success", '成功', "恢复订单成功", { duration: 0.5 });
                    await initData()
                }
            })
                .catch((err) => {
                    antMessage("warning", '失败', err, { duration: 0.5 });
                })
        } else {
            antMessage("warning", '失败', "无菜品数据，无法进行操作", { duration: 0.5 });
            return false
        }


    }
    // const we1 = (e: any, name: any) => {

    //     let checked = e.target.checked;
    //     let dataEx = form.getFieldValue([formName, name]);

    //     // let arr: Array<any> = [];
    //     let newData = { ...dataEx, isReturnInventory: checked };

    //     // arr.push(newData)

    //     let lData: Array<any> = [];
    //     lData = arrayHandle.merge([...isCheckData], [newData]) as Array<any>;

    //     setIsCheckData(lData)
    //     form.setFieldValue([formName, name], newData)

    // }

    //赠送菜
    const giftDish = (id: string, coMonStatus: number) => {
        if (!id) return false
        let dataEx = form.getFieldsValue();
        const { caOrderStatus } = dataEx;


        if (caOrderStatus === 2 || caOrderStatus === -998) {

        } else {
            antMessage("warning", '失败', "无法进行操作,当前订单不处于接单/反结账状态", { duration: 0.5 });
            return false
        }


        if (isCheckData.length > 0) {

            let isCheckedId: Array<string> = [];
            isCheckData.forEach((item: any) => {
                if (item.isChecked) isCheckedId.push(item.id)
            });

            if (isCheckedId.length === 0) {
                antMessage("warning", '失败', "不存在勾选数据", { duration: 0.5 });
                return false
            }
            fetchApi.POST(wmService, {
                apiId: "cateringOrder",
                apiExtend: "singleGift",
                apiData: {
                    orderId: id,
                    isCheckedId,
                    coMonStatus
                }
            }).then(async (res) => {
                //初始化数据
                await initData()


            }).catch((err) => {
                antMessage("error", '失败', err, { duration: 0.5 });
            })
        } else {
            antMessage("warning", '失败', "无菜品数据，无法进行操作", { duration: 0.5 });
            return false
        }

    }


    //服务费
    const gitfService = (id: string, isDerateServiceCharge: boolean) => {
        if (!id) return false
        let dataEx = form.getFieldsValue();
        const { caOrderStatus } = dataEx;
        if (caOrderStatus === 2 || caOrderStatus === -998) {

        } else {
            antMessage("warning", '失败', "无法进行操作,当前订单不处于接单/反结账状态", { duration: 0.5 });
            return false
        }


        if (isCheckData.length > 0) {

            let isCheckedId: Array<string> = [];
            isCheckData.forEach((item: any) => {
                if (item.isChecked) isCheckedId.push(item.id)
            });

            if (isCheckedId.length === 0) {
                antMessage("warning", '失败', "不存在勾选数据", { duration: 0.5 });
                return false
            }
            fetchApi.POST(wmService, {
                apiId: "cateringOrder",
                apiExtend: "gitfService",
                apiData: {
                    orderId: id,
                    isCheckedId,
                    isDerateServiceCharge
                }
            }).then(async (res) => {
                //初始化数据
                await initData()


            }).catch((err) => {
                antMessage("error", '失败', err);
            })
        } else {
            antMessage("warning", '失败', "无菜品数据，无法进行操作", { duration: 0.5 });
            return false
        }

    }
    //免收
    // const freeCharge = (id: string, coMonStatus: number) => {
    //     if (!id) return false
    //     let dataEx = form.getFieldsValue();
    //     const { caOrderStatus } = dataEx;
    //     if (caOrderStatus === 2 || caOrderStatus === -998) {

    //     } else {
    //         antMessage("warning", '失败', "无法进行操作,当前订单不处于接单/反结账状态", { duration: 0.5 });
    //         return false
    //     }


    //     if (isCheckData.length > 0) {

    //         let isCheckedId: Array<string> = [];
    //         isCheckData.forEach((item: any) => {
    //             if (item.isChecked) isCheckedId.push(item.id)
    //         });

    //         if (isCheckedId.length === 0) {
    //             antMessage("warning", '失败', "不存在勾选数据", { duration: 0.5 });
    //             return false
    //         }
    //         fetchApi.POST(wmService, {
    //             apiId: "cateringOrder",
    //             apiExtend: "freeCharge",
    //             apiData: {
    //                 orderId: id,
    //                 isCheckedId,
    //                 coMonStatus
    //             }
    //         }).then(async (res) => {
    //             //初始化数据
    //             await initData()


    //         }).catch((err) => {
    //             antMessage("error", '失败', err);
    //         })
    //     } else {
    //         antMessage("warning", '失败', "无菜品数据，无法进行操作", { duration: 0.5 });
    //         return false
    //     }


    // }
    let bb: any = '';
    // console.log("formListData",formListData);


    return (
        <>
            <Drawer closable={false} onClose={() => closeModal(true)} width={"90%"}
                placement="left"
                open={true}
                title={
                    <Space>
                        {/* <Popconfirm placement={"bottom"} title="确定要保存吗？" onConfirm={onSave} okText="确定" cancelText="取消"> */}
                        <Button loading={saveLoading} size='middle' onClick={onSave}>保存</Button>
                        {/* </Popconfirm> */}
                        {/* <Popconfirm placement={"bottom"} title="确定要保存吗？" onConfirm={onSave} okText="确定" cancelText="取消">
                            <Button >保存</Button>
                        </Popconfirm> */}
                        {data.mode !== "reverseClosing" ?
                            <>

                                <Popconfirm placement={"bottom"} title="确定要取消预结吗？" onConfirm={() => reversePreknot(data.id)} okText="确定" cancelText="取消">
                                    <Button >取消预结</Button>
                                </Popconfirm>
                            </>
                            : null}



                        {isTea ? <Button onClick={() => handleCateringOrderPrint(data.id)}>预结订单</Button> :
                            <Popconfirm placement={"bottom"} title="未加入茶位，确定要预结吗？" onConfirm={() => handleCateringOrderPrint(data.id)} okText="确定" cancelText="取消">
                                <Button >预结订单</Button>
                            </Popconfirm>
                        }
                        {data.mode !== "reverseClosing" ?
                            <>

                                <Button onClick={() => handleMoveRoom({ ...data, roomStatus: 0 })}>移台</Button>
                            </>
                            : null}
                        <Button onClick={() => handleSinglePrint(data.id)}>整单打印</Button>
                        <Button onClick={() => handleSingleDiscount(data.id, false)}>整单折扣</Button>
                        <Button danger onClick={() => handleSingleDiscount(data.id, true)}>强制折扣</Button>
                        {/* <Popconfirm placement={"bottom"} title="确定要分单吗？" onConfirm={ ()=>openOrder(data.id)} okText="确定" cancelText="取消">
                        <Button danger>分单</Button>
                        </Popconfirm> */}
                        {/* onChange={(e) => {
                            
                            setIsRemoveVegetablesNew(e.target.checked)
                       }} */}
                        {/* description={<Checkbox defaultChecked={true} value={isRemoveVegetablesNew} style={{ float: "right" }}>新增订单</Checkbox>}  */}
                        <Popconfirm placement={"bottom"} title="确定要移菜吗？" onConfirm={() => removeVegetables(data.id)} okText="确定" cancelText="取消">
                            <Button danger>移菜</Button>
                        </Popconfirm>

                        <Popconfirm placement={"bottom"} title="确定要赠送菜吗？" onConfirm={() => giftDish(data.id, 2)} okText="确定" cancelText="取消">
                            <Button danger>菜赠送</Button>
                        </Popconfirm>

                        <Popconfirm placement={"bottom"} title="确定要取消菜赠送吗？" onConfirm={() => giftDish(data.id, 1)} okText="确定" cancelText="取消">
                            <Button danger>取消赠送</Button>
                        </Popconfirm>

                        <Popconfirm placement={"bottom"} title="确定要免菜服务费吗？" onConfirm={() => gitfService(data.id, true)} okText="确定" cancelText="取消">
                            <Button danger>免服务费</Button>
                        </Popconfirm>

                        <Popconfirm placement={"bottom"} title="确定要取消免菜服务费吗？" onConfirm={() => gitfService(data.id, false)} okText="确定" cancelText="取消">
                            <Button danger>取消免服务费</Button>
                        </Popconfirm>

                        {/* <Popconfirm placement={"bottom"} title="确定要免收吗？" onConfirm={() => freeCharge(data.id, 4)} okText="确定" cancelText="取消">
                            <Button danger>免收</Button>
                        </Popconfirm>

                        <Popconfirm placement={"bottom"} title="确定要取消免收吗？" onConfirm={() => freeCharge(data.id, 1)} okText="确定" cancelText="取消">
                            <Button danger>取消免收</Button>
                        </Popconfirm> */}





                        {
                            form.getFieldValue("suCateringOrderId") ?
                                <Popconfirm placement={"bottom"} title="确定要恢复到父单上吗？" onConfirm={() => recoverOrder(data.id)} okText="确定" cancelText="取消">
                                    <Button danger>恢复</Button>
                                </Popconfirm> : null
                        }



                        <Button onClick={() => handleSetAccount(data.id)}>结账</Button>
                        {data.mode === "reverseClosing" ? <Popconfirm placement={"bottom"} title={<div>
                            <div>1.请确保此单未做值班报表再进行反结账</div>
                            <div>2.如若该订单属于线上支付，会原路返还, 确认吗？</div>

                        </div>} onConfirm={() => reverseClosing(data.id)} okText="确定" cancelText="取消">
                            <Button danger>反结账</Button>
                        </Popconfirm> : null}



                    </Space>
                }
            >
                <Col span={24}>

                    <Form
                        layout="vertical"
                        // layout='inline'
                        form={form}

                        name="Edit"
                    >
                        <Row>

                            <Col span={8}>
                                <Form.Item
                                    label={`订单${form.getFieldValue("suCateringOrderId") ? `(父订单${form.getFieldValue("suCateringOrderId")})` : ""}`}
                                    name="id">
                                    <Input readOnly />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <div> </div>
                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label="房台号"
                                    name="roomNum">
                                    <Input readOnly />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <div> </div>

                            </Col>
                            <Col span={7}>
                                <Form.Item
                                    label="市别"
                                    name="sbName">
                                    <Input readOnly />
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    label="开台时间"
                                    name="createdAt">
                                    <Input readOnly />
                                </Form.Item>
                            </Col>
                            <Col span={1}>
                                <div> </div>
                            </Col>

                            <Col span={3}>
                                <Form.Item
                                    label="客人姓名"
                                    name="customerName">
                                    <Input onChange={onChangeCustomerName} />
                                </Form.Item>
                            </Col>


                            <Col span={1}>
                                <div> </div>

                            </Col>
                            <Col span={3}>
                                <Form.Item
                                    label="预估价格"
                                    name="forecastPrice">
                                    <InputNumber readOnly />
                                </Form.Item>
                            </Col>

                            <Col span={2}>
                                <Form.Item
                                    label="赠送价格"
                                    name="giftPrice">
                                    <InputNumber readOnly />
                                </Form.Item>
                            </Col>

                            <Col span={2}>
                                <Form.Item
                                    label="移菜价格"
                                    name="transferPrice">
                                    <InputNumber readOnly />
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item
                                    label="人数"
                                    name="headCount">
                                    <InputNumber onChange={onChangeHeadCount} />
                                </Form.Item>
                            </Col>

                            <Col span={4}>
                                <Form.Item
                                    label="订单状态"
                                    name="caOrderStatusName"

                                >
                                    <Input readOnly />
                                </Form.Item>
                            </Col>
                            <Col style={{ display: "none" }} span={4}>
                                <Form.Item
                                    label="订单状态"
                                    name="caOrderStatus"
                                >
                                    <Input readOnly />
                                </Form.Item>
                            </Col>




                            <Col style={{ display: "none" }} span={4}>
                                <Form.Item
                                    label="状态"
                                    name="state"
                                >
                                    <Input readOnly />
                                </Form.Item>
                            </Col>

                            <Col style={{ display: "none" }} span={4}>
                                <Form.Item
                                    label="父订单"
                                    name="suCateringOrderId"
                                >
                                    <Input readOnly />
                                </Form.Item>
                            </Col>
                        </Row>



                        <Form.List
                            name={formName}
                            rules={[
                                {
                                    validator: async (_, names) => {

                                        if (!names || names.length < 1) {
                                            antMessage('error', "至少要一个菜品", 'error');
                                            return Promise.reject(new Error("至少要一个菜品"));
                                        }
                                    },
                                },
                            ]}
                        >

                            {(fields, { add, remove }) => {
                              
                                bb = add;
                                return (
                                    <div>
                                        {fields.map(({ key, name, ...restField }) => {   
                                            return (
                                                <Row justify="space-between" wrap={false}>
                                                    {/* <div style={{ display: 'flex', marginBottom: 8, alignItems: "center" }}> */}
                                                    <Col span={0.5}>
                                                        <Form.Item
                                                            label={name === 0 ? <span style={{ textAlign: "center" }}></span> : ""}
                                                            name={[name, 'isChecked']}
                                                        >
                                                            <div>{`${name + 1}.`}</div>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={0.5}>
                                                        <Form.Item
                                                            label={name === 0 ? <span style={{ textAlign: "center" }}></span> : ""}
                                                            name={[name, 'isChecked']}
                                                        >
                                                            <Checkbox checked={isCheckData && isCheckData.length > 0 ? isCheckData[name]?.isChecked : false} disabled={formListData ? formListData[name] ? formListData[name].state === "insert" ? true : false : false : false} onChange={(e: any) => we(e, name)}></Checkbox>

                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={0.5}>
                                                        <Form.Item
                                                            label={name === 0 ? <span style={{ textAlign: "center" }}></span> : ""}
                                                            name={[name, '2']}
                                                        // tooltip={{icon:<div>22</div>}}
                                                        // rules={[{ required: formListData[name] ? formListData[name].productId ? true : false : false, message: '请输入产品!' }]}
                                                        >
                                                            <Popconfirm placement={"bottom"} title="确定要删除吗？"
                                                                onConfirm={() => removeList(remove, name, key)} okText="确定" cancelText="取消">
                                                                {/* <MinusCircleOutlined style={{ width: 15, marginLeft: 5 }} /> */}
                                                                <MinusCircleOutlined />

                                                            </Popconfirm>


                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={1}>
                                                        <Form.Item
                                                            label={name === 0 ? <span style={{ textAlign: "center", }}></span> : ""}
                                                            name={[name, 'coMonStatusName']}
                                                        >
                                                            {/* <Input style={{ width: 53 }} readOnly></Input> */}
                                                            <Input style={{color:formListData[name].coMonStatus===2?"#FFD416":""}} readOnly></Input>


                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={5}>

                                                        <Form.Item
                                                           
                                                            className='menuSpec'
                                                            label={name === 0 ? <span style={{ textAlign: "center" }}>菜品</span> : ""}
                                                            name={[name, 'menuSpecId']}
                                                        // rules={[{ required: formListData[name] ? formListData[name].productId ? true : false : false, message: '请输入产品!' }]}
                                                        >
                                                            <Select
                                                                // style={{ color:formListData[name].isDerateServiceCharge?"#83FF16":"" }}
                                                                onSearch={(e) => getList.searchEx('menuSpec', setMenuSpeData, e, menuSpeData, {
                                                                    keyId: name,
                                                                    isFormList: true,
                                                                    form: form,
                                                                    FormListName: formName,
                                                                    name,
                                                                    andWhereObj: {
                                                                        mountDataOptions: {
                                                                            userMount: {
                                                                                isDept: true
                                                                            }
                                                                        },
                                                                    }
                                                                    // type,
                                                                })}
                                                                labelRender={
                                                                  (item:any)=>{
                                                                     return <span style={{ color:formListData[name].isDerateServiceCharge?"#83FF16":"" }}>{item.label}</span>
                                                                  }
                                                                }
                                                                onChange={(e, option) => getSpeAndMemoAndCom(e, option, name)}
                                                                popupMatchSelectWidth={400}
                                                                optionLabelProp='name'
                                                                showSearch
                                                                allowClear
                                                                placeholder="菜品"
                                                                optionFilterProp="children"

                                                                filterOption={(input, option: any) => {

                                                                    return true
                                                                }}
                                                                 
                                                                // labelRender={<div></div>}
                                                                disabled={formListData ? formListData[name] ? formListData[name].state === "insert" ? false : true : true : true}
                                                                options={menuSpeData ? menuSpeData[name] ? menuSpeData[name] : [] : []}
                                                            >

                                                            </Select>


                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1.5}>
                                                        <Form.Item
                                                            name={[name, 'amount']}
                                                            // label="数量"
                                                            label={name === 0 ? <div>数量</div> : ""}
                                                            rules={[{ required: formListData ? formListData[name] ? formListData[name].menuSpecId ? true : false : false : true, message: '请输入数量' }]}
                                                        >
                                                            <InputNumber
                                                                readOnly={formListData ? (formListData[name] ? (formListData[name].state === "insert" ? false : (!formListData[name].isChangeNum)) : true) : true}
                                                                // style={{ width: 50, marginLeft: 5 }}
                                                                onChange={(e) => changeNum(e, name)} min={0.1} placeholder="数量" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Form.Item

                                                            name={[name, "menuSpecSpecialsId"]}
                                                            // label={name === 0 ? <div style={{ textAlign: "center", width: 160 }}>特殊规格</div> : ""}
                                                            label={name === 0 ? <div >特殊规格</div> : ""}

                                                            // label="特殊规格"
                                                            className='menuSpecSpecials'
                                                        >
                                                            <Select
                                                                // style={{ width: 160, marginLeft: 5 }}

                                                                onChange={(e, option) => onChangeSpeSpe(e, option, name)}

                                                                mode="multiple"
                                                                optionLabelProp="name"
                                                                showSearch
                                                                popupMatchSelectWidth={250}
                                                                // defaultValue={menuSpecSpecialIds}
                                                                placeholder="特殊规格"
                                                                // optionFilterProp="children"
                                                                filterOption={(input, option: any) => {
                                                                    return true
                                                                }}
                                                                disabled={formListData ? formListData[name] ? formListData[name].state === "insert" ? false : true : true : true}
                                                                options={menuSpecSpecialData ? menuSpecSpecialData[name] ? menuSpecSpecialData[name] : [] : []}
                                                            >

                                                            </Select>
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        {/* 加料 */}
                                                        <Form.Item
                                                            name={[name, 'menuCondimentIds']}
                                                            // label={name === 0 ? <div style={{ textAlign: "center", width: 130 }}>加料</div> : ""}
                                                            label={name === 0 ? <div >加料</div> : ""}

                                                            className='menuCondiments'
                                                        >
                                                            <Select
                                                                //    aria-readonly
                                                                // style={{ width: 130, marginLeft: 5 }}
                                                                onChange={(e, option) => onChangeCondiments(e, option, name)}
                                                                mode="multiple"
                                                                optionLabelProp="name"
                                                                showSearch
                                                                popupMatchSelectWidth={250}
                                                                // defaultValue={menuSpecSpecialIds}
                                                                placeholder="加料"
                                                                // optionFilterProp="children"
                                                                filterOption={(input, option: any) => {
                                                                    return true
                                                                }}
                                                                disabled={formListData ? formListData[name] ? formListData[name].state === "insert" ? false : true : true : true}
                                                                options={menuCondimentsData ? menuCondimentsData[name] ? menuCondimentsData[name] : [] : []}
                                                            >
                                                            </Select>
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={1.5}>
                                                        <Form.Item

                                                            // label={name === 0 ? <div style={{ textAlign: "center", width: 75 }}>金额</div> : ""}
                                                            label={name === 0 ? <div style={{ textAlign: "center" }}>金额</div> : ""}

                                                            name={[name, 'advancePrice']}
                                                            rules={[{ required: formListData ? formListData[name] ? formListData[name].menuSpecId ? true : false : false : true, message: '总价格' }]}
                                                        >
                                                            {/* <InputNumber style={{ width: 75, marginLeft: 5 }} readOnly placeholder="金额" /> */}
                                                            <InputNumber readOnly placeholder="金额" />

                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1.5}>
                                                        <Form.Item

                                                            name={[name, 'discount']}
                                                            // label={name === 0 ? <div style={{ textAlign: "center", width: 75 }}>折扣</div> : ""}
                                                            label={name === 0 ? <div style={{ textAlign: "center" }}>折扣</div> : ""}

                                                            // label="折扣"
                                                            rules={[{ required: formListData ? formListData[name] ? formListData[name].menuSpecId ? true : false : false : true, message: '请输入数量' }]}
                                                        >
                                                            <InputNumber
                                                                // style={{ width: 75, marginLeft: 5 }}
                                                                onChange={(e) => changeDiscount(e, name)}
                                                                formatter={formatPercentage}
                                                                max={100}
                                                                min={formListData ? formListData[name] ? Number(formListData[name].minDiscount) * 100 : 100 : 100}
                                                                step={1}
                                                                parser={parsePercentage}
                                                                // disabled={}
                                                                placeholder="折扣" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1.5}>
                                                        <Form.Item

                                                            name={[name, 'discountPrice']}
                                                            // label={name === 0 ? <div style={{ textAlign: "center", width: 90 }}>折后价</div> : ""}
                                                            label={name === 0 ? <div >折后价</div> : ""}


                                                            // label="折后价"
                                                            rules={[{ required: formListData ? formListData[name] ? formListData[name].menuSpecId ? true : false : false : true, message: '请输入折后价' }]}
                                                        >
                                                            {/* <InputNumber style={{ width: 90, marginLeft: 5 }} readOnly placeholder="折后价" /> */}
                                                            <InputNumber readOnly placeholder="折后价" />

                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={2}>
                                                        <Form.Item
                                                            // style={{ width: 100, marginLeft: 5 }}

                                                            label={name === 0 ? <div style={{ textAlign: "center" }}>备注</div> : ""}
                                                            name={[name, 'memo']}
                                                        >
                                                            <Input readOnly={formListData ? formListData[name] ? formListData[name].state === "insert" ? false : true : true : true} placeholder="备注" />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={1}>
                                                        <Form.Item
                                                            label={name === 0 ? <div >状态</div> : ""}
                                                            // label={name === 0 ? <div style={{ textAlign: "center", width: 85 }}>菜品状态</div> : ""}
                                                            name={[name, 'caMenuStatusName']}
                                                        >
                                                            <Input readOnly placeholder="状态" />
                                                        </Form.Item>
                                                    </Col>
                                                    <Col span={1.5}>
                                                        <Form.Item

                                                            label={name === 0 ? <div>打印</div> : ""}
                                                            name={[name, 'isPrint']}
                                                            valuePropName="checked"
                                                            initialValue={true}>
                                                            < Switch checked={true}
                                                                // style={{ width: 55, marginLeft: 5 }}
                                                                onChange={(e) => changePrint(e, name)}
                                                                checkedChildren="是"
                                                                unCheckedChildren="否" />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col span={1.5}>
                                                        <Form.Item

                                                            label={name === 0 ? <div style={{ textAlign: "center" }}>改数</div> : ""}
                                                            name={[name, 'isChangeNum']}
                                                            valuePropName="checked"
                                                            initialValue={false}>
                                                            < Switch disabled={formListData ? formListData[name] ? formListData[name].state === "insert" ? true : false : false : false} checked={false}
                                                                // onChange={(e) => changeisChangeNum(e, name)}
                                                                // style={{ width: 55, marginLeft: 5 }}
                                                                checkedChildren="是"
                                                                unCheckedChildren="否" />
                                                        </Form.Item>

                                                    </Col>
                                                    <Col span={1.5}>
                                                        <Form.Item

                                                            label={name === 0 ? <div style={{ textAlign: "center", fontSize: 11 }}>免服费</div> : ""}
                                                            name={[name, 'isDerateServiceCharge']}
                                                            valuePropName="checked"
                                                            initialValue={false}>
                                                            < Switch disabled
                                                                // style={{ width: 60, marginLeft: 5 }}
                                                                // onChange={(e) => changeisChangeNum(e, name)}
                                                                checkedChildren="是"
                                                                unCheckedChildren="否" />
                                                        </Form.Item>
                                                    </Col>

                                                    {/* 
                                                        <Form.Item
                                                            style={{ width: 100, marginLeft: 8 }}
                                                            name={[name, 'discountPrice']}
                                                            // label="折后价"
                                                            rules={[{ required: formListData ? formListData[name] ? formListData[name].menuSpecId ? true : false : false : true, message: '请输入折后价' }]}
                                                        >
                                                            <InputNumber disabled={true} placeholder="折后价" />
                                                        </Form.Item>

                                                    {/* </div> */}
                                                </Row>

                                            )
                                        })}
                                        <Row >
                                            <Form.Item style={{ width: "100%" }}>
                                                <Button type="dashed" onClick={() => customizeAdd(add)} block icon={<PlusOutlined />}>
                                                    添加商品
                                                </Button>
                                            </Form.Item>
                                        </Row>

                                    </div>
                                )
                            }
                            }
                        </Form.List>




                    </Form>

                </Col>

            </Drawer>
            {/**整单折扣页面 */}
            {singleDiscountOpen ? <SingleDiscount
                closeModal={closeSingleDiscount}
                data={singleDiscountDate}
            >
            </SingleDiscount> : ""}

            {/**结账页面 */}
            {
                setAccountsOpen ? (<SetAccounts data={setAccountData} closeModal={onCloseSetAccount}
                />)
                    : null
            }

            {/**订单预结页面 */}
            {cateringOrderPrintOpen ? (<CateringOrderPrint data={cateringOrderPrintData} closeModal={onCloseCateringOrderPrint}
            />)
                : null}

            {/**整单打印 */}
            {singlePrintOpen ? (<DrawerDetailList data={singlePrintData} closeModal={onCloseSinglePrint}
            />)
                : null}

            {/**移台页面 */}
            {moveRoomOpen ? (<MoveRoom data={moveRoomData} closeModal={onCloseMoveRoom}
            />)
                : null}
        </>
    )
}

