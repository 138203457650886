import systemConfig from '../../../../config/systematic';
import { Button, Checkbox, Col, DatePicker, Form, Input, Row, Select, Space, Table } from 'antd';
import React, { useEffect, useState } from 'react'
import getList from '../../../universal/getList';
import fetchApi from '../../../../utils/api/fetchApi';
import dayjs from 'dayjs';
import { handleData } from './DailyReport';
import antMessage from '../../../../utils/extend/AntdNotification'
import DownloadExcel from '../../../report/DownloadExcel';

const { RangePicker } = DatePicker;

export default function EnTrStatistics() {
  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const [form] = Form.useForm();
  let isCurrentBranchStore=Form.useWatch("isCurrentBranchStore",form)
  const [user, setUser] = useState<any>("")
  const [deptData, setDeptData] = useState<any>();
  const [deptModel, setDeptModel] = useState<any>([
    { id: "exDept", name: "调出部门" },
    { id: "imDept", name: "调入部门" },

  ])

  const [productModel, setProductModel] = useState<any>([
    { id: "rangeNumSearch", name: "品号范围搜索" },
    { id: "similarNumSearch", name: "品号相似搜索" },
    { id: "equalNumSearch", name: "品号相等搜索" },
    { id: "similarNameSearch", name: "品名相似搜索" },
    { id: "equalNameSearch", name: "品名相等搜索" },



  ])

  const [selectProdutModel, setSelectProdutModel] = useState<any>("rangeNumSearch")

  const [queryCriteria, setQueryCriteria] = useState<any>("")
  const [classificationWarehouseData, setClassificationWarehouseData] = useState<any>();

  const [rows, setRows] = useState<any>([]);



  useEffect(() => {
    const fetchData = async () => {
      form.setFieldValue("chooseTime", [dayjs(), dayjs()])
      form.setFieldValue("productModel", "rangeNumSearch")
      let deptData = await fetchApi.GET(wmService, {
        apiId: "dept",
        apiExtend: 'showList',
        apiData: {

        }
      })
      if (deptData.success) {
        setDeptData(deptData.data)
      }
      let classificationWarehouseData = await fetchApi.GET(wmService, {
        apiId: "classificationWarehouse",
        apiExtend: 'showList'
      })



      if (classificationWarehouseData.success) {

        setClassificationWarehouseData(classificationWarehouseData.data)
      }


    }
    fetchData()
  }, [])
  /** 表头内容 */
  let columnsHead: any = [

    {
      title: "品号",
      dataIndex: "selfId",
    },



    {
      title: "品名",
      dataIndex: "productName",
    },
    {
      title: "分类",
      dataIndex: "classificationWarehouseName",
    },
    {
      title: "单位",
      dataIndex: "productUnitName",
    },



    {
      title: "数量",
      dataIndex: "bNum",
    },
    {
      title: "均价",
      dataIndex: "univalence",
    },
    {
      title: "金额",
      dataIndex: "amount",
    },


  ]
  const onFinish = () => {
    form.validateFields()
      .then(async (values: any) => {

        fetchApi.GET(wmService, {
          apiId: "entrepotReport",
          apiExtend: 'enTrStatistics',
          apiData: {
            deptIds: handleData(values.deptIds, "deptId"),
            time: values.chooseTime ? {
              startTime: dayjs(values.chooseTime[0]).format("YYYY-MM-DD"),
              endTime: dayjs(values.chooseTime[1]).format("YYYY-MM-DD"),
            } : null,
            selfs: values.selfStart && values.selfEnd ? {
              selfStart: values.selfStart,
              selfEnd: values.selfEnd
            } : null,
            selfId: values.selfId,
            productName: values.productName,
            deptModel: values.deptModel,
            productModel: values.productModel,
            classificationWarehouses: handleData(values.classificationWarehouses, "classificationWarehouseId"),
          }
        }).then((res: any) => {

          setRows(res.data.rows)
          setQueryCriteria(res.data.queryCriteria)
          setUser(res.data.user)
          antMessage('success', '搜索成功', 'success');

        }).catch((err: any) => {
          console.log("err", err);

          antMessage('error', '搜索失败', err.message + ' | status：' + err.errors.status);
        })
      })
      .catch((err) => {
        antMessage('warning', '无法通过数据校验', '请输入所需数据！');
      })





  }
  const changeProductModel = (e: any) => {
    setSelectProdutModel(e)

    
  }

  const onChangeBranchStore=(e:any)=>{
    let checked=e.target.checked;
    
    if(checked){
      fetchApi.GET(wmService, {
        apiId: "branchStore",
        apiExtend: 'getDeptByentrepot',
       
      }).then((res: any) => {
        form.setFieldValue("deptIds",res.data)
      }).catch((err: any) => {
          form.setFieldValue("deptIds",[])
            console.log("err",err);
            
        
      })
    }else{
      form.setFieldValue("deptIds",[])
    }
     
  }
  return (
    <>
      <Form
        form={form}
        onFinish={onFinish}
      >
        <Row>
          <Col span={12}>
            <Form.Item
              label="部门"
              name="deptIds"
              rules={[{ required: isCurrentBranchStore?false:true, message: '请选择部门!' }]}
            >
              <Select
                // orderTypeData
                mode="multiple"
                showSearch
                placeholder="请选择部门!"
                optionFilterProp="children"

                onSearch={(e) => getList.search('dept', setDeptData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(deptData, 'deptName')}
              </Select>
            </Form.Item>
          </Col>


          <Col span={6}>
            <Form.Item
              label="部门模式"
              name="deptModel"
              rules={[{ required: true, message: '请选择部门模式!' }]}
            >
              <Select
                showSearch
                placeholder="部门模式"
                optionFilterProp="children"

                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(deptModel, 'name')}
              </Select>
            </Form.Item>
          </Col>

          <Col span={6}>
            <Form.Item
              label="产品模式"
              name="productModel"

            >
              <Select
                defaultValue={"rangeNumSearch"}
                onChange={changeProductModel}
                showSearch
                placeholder="产品模式"
                optionFilterProp="children"

                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(productModel, 'name')}
              </Select>
            </Form.Item>
          </Col>



          {
            selectProdutModel === "rangeNumSearch" ?
              <>
                <Col span={3}>
                  <Form.Item
                    label="品号"
                    name="selfStart"
                  >
                    <Input
                    />
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item
                    colon={false}
                    label="-"
                    name="selfEnd"
                  >
                    <Input

                    />
                  </Form.Item>
                </Col>
              </>
              : null
          }

          {
            selectProdutModel === "similarNameSearch" || selectProdutModel === "equalNameSearch" ?
              <>
                <Col span={3}>
                  <Form.Item
                    label="品名"
                    name="productName"
                  >
                    <Input />
                  </Form.Item>
                </Col>

              </>
              : null
          }
          {
            selectProdutModel === "similarNumSearch" || selectProdutModel === "equalNumSearch" ?
              <>
                <Col span={3}>
                  <Form.Item
                    label="品号"
                    name="selfId"
                  >
                    <Input />
                  </Form.Item>
                </Col>

              </>
              : null
          }
          <Col span={6}>
            <Form.Item
              label="品类"
              name="classificationWarehouses"
            >
              <Select
                mode="multiple"
                showSearch
                placeholder="品类"
                optionFilterProp="children"
                onSearch={(e) => getList.search('classificationWarehouse', setClassificationWarehouseData, e)}
                filterOption={(input, option: any) => {
                  return true
                }}
              >
                {getList.general(classificationWarehouseData, 'classificationWarehouseName')}
              </Select>
            </Form.Item>
          </Col>
          <Col span={6}>
            <Form.Item
              label="日期"
              name="chooseTime"
            >
              <RangePicker
                defaultValue={[dayjs(), dayjs()]}
                format="YYYY-MM-DD"
              />
            </Form.Item>
          </Col>
          <Col span={1.5}>
            <Form.Item
              label="当前分店"
              name="isCurrentBranchStore"
              valuePropName="checked"
               
            >
              <Checkbox onChange={onChangeBranchStore}></Checkbox>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item >
              <Space>
                <Button type="primary" htmlType="submit">
                  统计
                </Button >
                <Button onClick={() => DownloadExcel(rows, "enTrStatistics", queryCriteria)}>下载EXCEL文件</Button>
                {/* <Button onClick={() => entrepotPrint.printEnReport(rows, "entrepotDailyReportPrint", queryCriteria, user)} >
                  打印
                </Button> */}
                {/* <Button onClick={() => entrepotPrint.printEnReport(rows, "entrepotDailyReportPrint", queryCriteria, user)} >
                  打印
                </Button> */}
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <div style={{ marginTop: "20px" }}>
        <Table
          scroll={{ x: "100%", y: 650 }}
          pagination={false}
          rowKey={(record: any, index: any) => {
            return index
          }}
          columns={columnsHead}
          dataSource={rows}

        >
        </Table>

      </div>
    </>
  )
}
