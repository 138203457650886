import { Button, Popconfirm, Space } from 'antd';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import CondimentWarehouseEdit from './CondimentWarehouseEdit';
import { useRef, useState } from 'react';
import SetInventory from './inventory/SetInventory';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';

export default function CondimentWarehouse(props: any) {

    const [inventoryOpen, setInventoryOpen] = useState<boolean>(false);

    const [inventoryData, setInventoryData] = useState<any>();

    const ref = useRef<any>();

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '库存',
            dataIndex: 'inventory',
            render: (text: any, record: any) => {
                return <div>{text?.quantity}</div>
            }

        },
        {
            title: '库存设置',
            dataIndex: 'price',
            render: (text: any, record: any) => {
                return <Space size="small">
                    <Button size={"small"} type="primary" onClick={() => handleSetInventory(record)}>{record.inventory ? '修改' : "设置"}库存</Button>
                    {record.inventory ?
                        <>
                            <Popconfirm title="确定要库存清零吗？" onConfirm={() => handleInventory({ apiId: "condimentInventory", id: record.inventory?.id, columnName: "quantity", value: 0 })} okText="确定" cancelText="取消">
                                <Button size={"small"} danger>清零</Button>
                            </Popconfirm>
                            <Popconfirm title="确定要将该库存设置为99999吗？" onConfirm={() => handleInventory({ apiId: "condimentInventory", id: record.inventory?.id, columnName: "quantity", value: 99999 })} okText="确定" cancelText="取消">
                                <Button size={"small"} danger>最大</Button>
                            </Popconfirm>
                        </> : null

                    }
                </Space>
            }

        },
        {
            title: '加料',
            dataIndex: 'condimentWarehouseName',
        },
        {
            title: '加价',
            dataIndex: 'price',
        },
        // {
        //     title: '排序',
        //     dataIndex: 'sortBy',
        // },
    ];

    /**库存一键设置 */
    const handleInventory = (apiData: any) => {
        const { apiId, id, columnName, value } = apiData;
        if (!id) return false
        fetchApi.PUT(wmService, {
            apiId,
            apiVariable: id,
            apiExtend: "updateOne",
            apiData: {
                columnName,
                value
            }

        }).then((res) => {
            ref.current.initData()
        }).catch((err) => {

        })
    }

    //打开库存设置
    const handleSetInventory = (record: any) => {
        let type = record.inventory ? "EDIT" : "NEW";
        let id = record.inventory?.id;
        let exId = record.id

        if (record.isSpecial) return
        setInventoryOpen(true)
        setInventoryData({ apiId: "condimentInventory", type, id, exId, exType: "condimentWarehouseId" })
    }

    //关闭库存设置
    const onCloseSetInventory = (status: boolean) => {
        setInventoryOpen(false)
        if (status) {
            ref.current.initData()
        }
    }
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '加料仓库',
        apiId: 'condimentWarehouse',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: CondimentWarehouseEdit,
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
    };

    return (
        <>
            <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>

            {
                inventoryOpen
                    ? (<SetInventory
                        closeModal={onCloseSetInventory} data={inventoryData} />)
                    : null
            }
        </>
    )
}