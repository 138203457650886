import { Col, Form, Input, InputNumber, Row, Select, Switch, TimePicker } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import objectHandle from '../../../utils/baseLib/objectHandle';

export default function MenuSalePeriodEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    /**周期数据 */
    const [weekData, setWeekData] = useState<any>();

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;

            // ref.current.row.then(async (res: any) => {
            //     if (typeof res === 'undefined') { return }
            //     if (res.success === true) {

            //         const weeks = res.data.weeks;
            //         let weeksRows;
            //         if (objectHandle.isEmpty(weeks) === false) {
            //             weeksRows = weeks.split('@');
            //         }
            //         setInitialWeekData(weeksRows);
            //         form.setFieldsValue({
            //             weeks: weeksRows
            //         })
            //     }
            // })
        }
        const fetchData = async () => {

            form.setFieldsValue({ menuId: data.sendPageData.menuId });
            form.setFieldsValue({ shopTypeId: data.sendPageData.shopTypeId });
            form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });

            fetchApi.GET(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: "week",
            }).then(res => {
                if (res.success) {
                    setWeekData(res.data);
                }
            })
        }
        fetchData();
    }, [])

    // const changeDate = (e: any, key: any) => {
    //     if (key === 'startTime' && form.getFieldsValue()['endTime'] !== undefined) {
    //         if (dayjs(form.getFieldsValue()['endTime']).format("HH:mm") < dayjs(e).format("HH:mm")) {
    //             antMessage('error', '时间设置有误', '结束时间小于开始时间,请重新选择.');
    //             form.resetFields(['startTime']);
    //         }
    //     }

    //     if (key === 'endTime') {
    //         if (dayjs(form.getFieldsValue()['startTime']).format("HH:mm") > dayjs(e).format("HH:mm")) {
    //             antMessage('error', '时间设置有误', '开始时间大于结束时间,请重新选择.');
    //             form.resetFields(['endTime']);
    //         }
    //     }
    // };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuSalePeriod',
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '销售周期'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}
            >
                <Form
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="branchStoreId"
                                name="branchStoreId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="menuId"
                                name="menuId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="shopTypeId"
                                name="shopTypeId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        {/* <Col span={24}>
                            <Form.Item
                                label="周期"
                                name="weeks"
                                rules={[{ required: true, message: '请选择周期!' }]}
                                initialValue={initialweekData}
                            >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择周期"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(weekData, "name")}
                                </Select>
                            </Form.Item>
                        </Col> */}

                        <Col span={24}>
                            <Form.Item
                                label="周期"
                                name="weeks"
                                rules={[{ required: true, message: '请选择周期!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="选择周期"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(weekData, "name")}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}