import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import ShopCategoryEdit from './ShopCategoryEdit';

export default function ShopCategory(props: any) {

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '大分类名称',
            dataIndex: 'shopCategoryName',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
            sorter: true,
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜谱大分类',
        apiId: 'shopCategory',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: ShopCategoryEdit,
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}