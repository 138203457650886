import { getPageUrl } from './index';
import { RouteObject } from 'react-router-dom';

//MenuWarehouse
import MenuWarehouseCategory from '../components/internal/menu/MenuWarehouseCategory';
import MenuWarehouseType from '../components/internal/menu/MenuWarehouseType';
import MenuWarehouse from '../components/internal/menu/MenuWarehouse';
import MenuMovie from '../components/internal/menu/MenuMovie';

//MenuWarehouseArticle
import MenuWarehouseArticleCategory from '../components/internal/menu/MenuWarehouseArticleCategory';
import MenuWarehouseArticleType from '../components/internal/menu/MenuWarehouseArticleType';
import MenuWarehouseArticle from '../components/internal/menu/MenuWarehouseArticle';

//BranchStoreMenuWarehouse
import BranchStoreMenuWarehouse from '../components/internal/menu/BranchStoreMenuWarehouse';

//
import DeptPrintDetail from '../components/internal/menu/DeptPrintDetail';

//menu
import ShopCategory from '../components/internal/menu/ShopCategory';
import ShopType from '../components/internal/menu/ShopType';
import MenuHead from '../components/internal/menu/MenuHead';
import CookMethod from '../components/internal/menu/CookMethod';
import MenuMemo from '../components/internal/menu/MenuMemo';
import MenuCondiment from '../components/internal/menu/MenuCondiment';
import MenuShoppingCart from '../components/internal/menu/MenuShoppingCart';
import CondimentWarehouse from '../components/internal/menu/CondimentWarehouse';
import MenuHeadCode from '../components/external/code/MenuHeadCode';
import MenuLabel from '../components/internal/menu/MenuLabel';
import MenuOPLog from '../components/internal/menu/MenuOPLog';
import Scene from '../components/internal/menu/Scene';
import PromoCategory from '../components/internal/menu/PromoCategory';
import MenuSalePeriod from '../components/internal/menu/MenuSalePeriod';
import MenuSaleTime from '../components/internal/menu/MenuSaleTime';
import MenuSalesman from '../components/internal/menu/MenuSalesman';

//Inventory
import CondimentInventory from '../components/internal/menu/inventory/CondimentInventory';
import MenuSpecInventory from '../components/internal/menu/inventory/MenuSpecInventory';
import MenuSpeSpecInventory from '../components/internal/menu/inventory/MenuSpeSpecInventory';
import MenuImSpecInventory from '../components/internal/menu/imInventory/MenuImSpecInventory';
import ImMenuSpeSpecInventory from '../components/internal/menu/imInventory/ImMenuSpeSpecInventory';
import ImCondimentInventory from '../components/internal/menu/imInventory/ImCondimentInventory';

//feastTeam
// import FeastTeamMenu from '../components/internal/feastTeam/FeastTeamMenu';
import FeastTeamMenuWarehouse from '../components/internal/feastTeam/FeastTeamMenuWarehouse';
import FeastTeamMenuWarehouseCategory from '../components/internal/feastTeam/FeastTeamMenuWarehouseCategory';
import FeastTeamMenuHead from '../components/internal/feastTeam/FeastTeamMenuHead';

//setMeal
import SetMealCategory from '../components/internal/menu/SetMealCategory';
import SetMeal from '../components/internal/menu/SetMeal';

//queuing
import QueuingType from '../components/external/queuing/QueuingType';
import Queuing from '../components/external/queuing/Queuing';
import QueuingList from '../components/external/queuing/QueuingList';
import QueuingSetting from '../components/external/queuing/QueuingSetting';
import AliVoice from '../components/external/queuing/AliVoice';
import QueuingMemos from '../components/external/queuing/QueuingMemos';

//Ad
import Ad from '../components/external/ad/Ad';

//menu
const routes_menu = async (licenseList: Array<any>) => {
    const res: RouteObject[] = [
        // 菜式仓库管理
        {
            path: await getPageUrl(licenseList, 'menuWarehouseCategory'),
            element: <MenuWarehouseCategory></MenuWarehouseCategory>
        },
        {
            path: await getPageUrl(licenseList, 'menuWarehouseType'),
            element: <MenuWarehouseType></MenuWarehouseType>
        },
        {
            path: await getPageUrl(licenseList, 'menuWarehouse'),
            element: <MenuWarehouse></MenuWarehouse>
        },
        {
            path: await getPageUrl(licenseList, 'menuMovie'),
            element: <MenuMovie></MenuMovie>
        },

        // 文章管理
        {
            path: await getPageUrl(licenseList, 'menuWarehouseArticleCategory'),
            element: <MenuWarehouseArticleCategory></MenuWarehouseArticleCategory>
        },
        {
            path: await getPageUrl(licenseList, 'menuWarehouseArticleType'),
            element: <MenuWarehouseArticleType></MenuWarehouseArticleType>
        },
        {
            path: await getPageUrl(licenseList, 'menuWarehouseArticle'),
            element: <MenuWarehouseArticle></MenuWarehouseArticle>
        },
        
        // 门店菜式管理
        {
            path: await getPageUrl(licenseList, 'branchStoreMenuWarehouse'),
            element: <BranchStoreMenuWarehouse></BranchStoreMenuWarehouse>
        },

        // 
        {
            path: await getPageUrl(licenseList, 'deptPrintDetail'),
            element: <DeptPrintDetail></DeptPrintDetail>
        },

        // 菜式属性管理
        {
            path: await getPageUrl(licenseList, 'cookMethod'),
            element: <CookMethod></CookMethod>
        },
        {
            path: await getPageUrl(licenseList, 'menuMemo'),
            element: <MenuMemo></MenuMemo>
        },
        {
            path: await getPageUrl(licenseList, 'menuCondiment'),
            element: <MenuCondiment></MenuCondiment>
        },
        {
            path: await getPageUrl(licenseList, 'condimentWarehouse'),
            element: <CondimentWarehouse></CondimentWarehouse>
        },
        {
            path: await getPageUrl(licenseList, 'menuLabel'),
            element: <MenuLabel></MenuLabel>
        },
        {
            path: await getPageUrl(licenseList, 'scene'),
            element: <Scene></Scene>
        },

        // 各店菜谱管理
        {
            path: await getPageUrl(licenseList, 'shopCategory'),
            element: <ShopCategory></ShopCategory>
        },
        {
            path: await getPageUrl(licenseList, 'shopType'),
            element: <ShopType></ShopType>
        },
        {
            path: await getPageUrl(licenseList, 'menuHead'),
            element: <MenuHead></MenuHead>
        },
        {
            path: await getPageUrl(licenseList, 'menuHeadCode'),
            element: <MenuHeadCode></MenuHeadCode>
        },
        {
            path: await getPageUrl(licenseList, 'menuOPLog'),
            element: <MenuOPLog></MenuOPLog>
        },
        {
            path: await getPageUrl(licenseList, 'promoCategory'),
            element: <PromoCategory></PromoCategory>
        },
        {
            path: await getPageUrl(licenseList, 'menuSalePeriod'),
            element: <MenuSalePeriod></MenuSalePeriod>
        },
        {
            path: await getPageUrl(licenseList, 'menuSaleTime'),
            element: <MenuSaleTime></MenuSaleTime>
        },
        {
            path: await getPageUrl(licenseList, 'menuSalesman'),
            element: <MenuSalesman></MenuSalesman>
        },

        // Inventory
        {
            path: await getPageUrl(licenseList, 'menuInventory'),
            element: <MenuSpecInventory></MenuSpecInventory>
        },
        {
            path: await getPageUrl(licenseList, 'menuSpeSpecInventory'),
            element: <MenuSpeSpecInventory></MenuSpeSpecInventory>
        },
        {
            path: await getPageUrl(licenseList, 'condimentInventory'),
            element: <CondimentInventory></CondimentInventory>
        },
        {
            path: await getPageUrl(licenseList, 'menuImSpecInventory'),
            element: <MenuImSpecInventory></MenuImSpecInventory>
        },
        {
            path: await getPageUrl(licenseList, 'imMenuSpeSpecInventory'),
            element: <ImMenuSpeSpecInventory></ImMenuSpeSpecInventory>
        },
        {
            path: await getPageUrl(licenseList, 'imCondimentInventory'),
            element: <ImCondimentInventory></ImCondimentInventory>
        },

        // 套餐/菜单管理
        {
            path: await getPageUrl(licenseList, 'setMealCategory'),
            element: <SetMealCategory></SetMealCategory>
        },
        {
            path: await getPageUrl(licenseList, 'setMeal'),
            element: <SetMeal></SetMeal>
        },

        // 福食管理
        {
            path: await getPageUrl(licenseList, 'feastTeamMenuWarehouse'),
            element: <FeastTeamMenuWarehouse></FeastTeamMenuWarehouse>
        },
        {
            path: await getPageUrl(licenseList, 'feastTeamMenuWarehouseCategory'),
            element: <FeastTeamMenuWarehouseCategory></FeastTeamMenuWarehouseCategory>
        },
        {
            path: await getPageUrl(licenseList, 'feastTeamMenuHead'),
            element: <FeastTeamMenuHead></FeastTeamMenuHead>
        },

        // 点餐订单管理
        {
            path: await getPageUrl(licenseList, 'menuShoppingCart'),
            element: <MenuShoppingCart></MenuShoppingCart>
        },

        // 排队管理
        {
            path: await getPageUrl(licenseList, 'queuingType'),
            element: <QueuingType></QueuingType>
        },
        {
            path: await getPageUrl(licenseList, 'queuing'),
            element: <Queuing></Queuing>
        },
        {
            path: await getPageUrl(licenseList, 'queuingList'),
            element: <QueuingList></QueuingList>
        },
        {
            path: await getPageUrl(licenseList, 'queuingSetting'),
            element: <QueuingSetting></QueuingSetting>
        },
        {
            path: await getPageUrl(licenseList, 'aliVoice'),
            element: <AliVoice></AliVoice>
        },
        {
            path: await getPageUrl(licenseList, 'queuingMemos'),
            element: <QueuingMemos></QueuingMemos>
        },

        // 广告管理
        {
            path: await getPageUrl(licenseList, 'ad'),
            element: <Ad></Ad>
        },
    ]
    return res;
};

export default routes_menu