import React, { useEffect, useRef, useState } from 'react';
import MoonEdit from './MoonEdit';
import WebUniversal, { initDataType, WUExtendPropsType } from '../../components/system/WebUniversalNewEx'
import { columnsheadId } from '../universal/columnshead';
import MoonInsert from './MoonInsert';
import MoonUpdate from './MoonUpdate';
import { Button, Popconfirm, Select, Space } from 'antd';
import getList from '../universal/getList';
import fetchApi from '../../utils/api/fetchApi';
import moment from 'moment';
import antMessage from '../../utils/extend/AntdNotification'
import systemConfig from '../../config/systematic';
import MoonExchange from './MoonExchange';

//组件 Moon 
const Moon = (props: any) => {

    /** api服务id */
    const apiId = 'moon';
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const ref = useRef<any>();
    const [moonStatusData, setMoonStatusData] = useState<any>();
    const [selectMoonStatusIdData, setSelectMoonStatusIdData] = useState(100);
    const [modalChoosebranchStoreopen, setModalChoosebranchStoreopen] = useState<Boolean>(false)
    const [selectMoonTypeIdData, setSelectMoonTypeStatusIdData] = useState("-1");

    const [selectExchangebranchStoreIdData, setSelectExchangebranchStoreIdData] = useState("999");
    const [selectSalebranchStoreIdData, setSelectSalebranchStoreIdData] = useState("998");

    /**票据类型 */
    const [moonTypeData, setMoonTypeData] = useState<any>()
    /**选择分店数据 */
    const [branchStoreChooseData, setbranchStoreChooseData] = useState<any>();
    /**兑换分店 */
    const [branchStoreData, setbranchStoreData] = useState<any>()


    /** 售出分店*/
    const [salebranchStoreDate, setSalebranchStoreDate] = useState<any>()
    //表头
    // branchStoreName
    const columnsHead = [
        {
            title: '票据类型',
            dataIndex: 'moonType',
            // render: (text: any) => (text ? text.moonType : "")
        },
        {
            title: '折扣',
            dataIndex: 'disCountName',
        },
        {
            title: '票号',
            dataIndex: 'ticketNo',
        },
        {
            title: "售出分店",
            dataIndex: "saleBranchStoreName",
            // render:(text:any)=>(text?text.branchStoreName:"")
        },
        {
            title: '售出时间',
            dataIndex: 'sysDate',
            render: (text: any, record: any) => (text ? moment(text.createdAt).format("YYYY-MM-DD HH:mm:ss") : "")

        },
        {
            title: "兑换分店",
            dataIndex: "exchangeBranchStoreName",
            // render:(text:any)=>(text?text.branchStoreName:"")
        },
        {
            title: '兑换时间',
            dataIndex: 'moonStatus',
            render: (text: any, record: any) => {

                return text === "已售出" ?
                    <>

                        <Popconfirm title="确定要兑换吗？" onConfirm={() => handleUpDate(record)} okText="确定" cancelText="取消">
                            <Button size={"small"} type="primary">兑换</Button>
                        </Popconfirm>

                    </>
                    : <>
                        <span>{moment(record.exchangeTime).format("YYYY-MM-DD HH:mm:ss")}</span>
                        <span>  </span>
                        <Popconfirm title="确定要重置吗？" onConfirm={() => handleReset(record)} okText="确定" cancelText="取消">
                            <Button danger size={"small"} type="primary">重置兑换</Button>
                        </Popconfirm>
                    </>
            }
        },


        {
            title: '票据状态',
            dataIndex: 'moonStatus',
        },




    ];
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        //同步获取数据 async - await
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'moonStatus',
                    apiExtend: 'showList'
                }).then(res => {

                    if (res.success) {
                        setMoonStatusData(res.data);
                    }
                })
            fetchApi.GET(
                wmService,
                {
                    apiId: "moonType",
                    apiExtend: 'showList'
                }
            ).then(res => {
                if (res.success) {
                    let newData = [{ id: "-1", moonType: "全部类型" }, ...res.data]

                    setMoonTypeData(newData);

                }
            })

            fetchApi.GET(
                wmService,
                {
                    apiId: "branchStore",
                    apiExtend: 'showList'
                }
            ).then(res => {
                if (res.success) {
                    let newData = [{ id: "999", branchStoreName: "所有兑换分店" }, ...res.data]
                    let NewData = [{ id: "998", branchStoreName: "所有售出分店" }, ...res.data]
                    setSalebranchStoreDate(NewData)
                    setbranchStoreData(newData);
                }
            })

        };
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '票据',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        bulkInsert: true,
        bulkUpdate: true,
        UnDateComponent: MoonUpdate,
        InsertComponent: MoonInsert,
        EditComponent: MoonEdit,
        sendPageData: { moonStatus: selectMoonStatusIdData, moonTypeId: selectMoonTypeIdData, exchangebranchStoreId: selectExchangebranchStoreIdData, salebranchStoreId: selectSalebranchStoreIdData },
    };
    /*查看不同状态的票据*/
    const moonStatusChange = (e: any) => {

        setSelectMoonStatusIdData(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { moonStatus: e, moonTypeId: selectMoonTypeIdData, exchangebranchStoreId: selectExchangebranchStoreIdData, salebranchStoreId: selectSalebranchStoreIdData }
        }
        ref.current.initData(currentData);
    }

    /*查看不同类型的票据*/
    const moonTypeChange = (e: any) => {

        setSelectMoonTypeStatusIdData(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { moonTypeId: e, moonStatus: selectMoonStatusIdData, exchangebranchStoreId: selectExchangebranchStoreIdData, salebranchStoreId: selectSalebranchStoreIdData }
        }
        ref.current.initData(currentData);
    }


    /*查看不同售出分店的票据*/
    const salebranchStoreChange = (e: any) => {

        setSelectSalebranchStoreIdData(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { moonTypeId: selectMoonTypeIdData, moonStatus: selectMoonStatusIdData, salebranchStoreId: e, exchangebranchStoreId: selectExchangebranchStoreIdData }
        }
        ref.current.initData(currentData);
    }

    /*查看不同兑换分店的票据*/
    const exchangebranchStoreChange = (e: any) => {

        setSelectExchangebranchStoreIdData(e)
        const currentData: initDataType = {
            apiId,
            sendPageData: { moonTypeId: selectMoonTypeIdData, moonStatus: selectMoonStatusIdData, exchangebranchStoreId: e, salebranchStoreId: selectSalebranchStoreIdData }
        }
        ref.current.initData(currentData);
    }
    const handleUpDate = (e: any) => {
        fetchApi.PUT(wmService,
            {
                apiId: 'moon',
                apiExtend: 'exchange',
                apiVariable: e.id,
                apiData: {
                    moonStatus: 2,
                }
            }).then(res => {

                antMessage('success', '兑换成功', "success")
                ref.current.initData()
            }).catch(err => {
                antMessage('error', '删编辑失败', err);
            })
    }
    const handleReset = (e: any) => {
        fetchApi.POST(wmService,
            {
                apiId: 'moon',
                apiExtend: 'resetExchange',
                apiVariable: e.id,
            }).then(res => {
                if (res.success === true) {

                    antMessage('success', res.data.message, "success")
                    ref.current.initData()
                }
                // antMessage('success', '兑换成功', "success")
                // ref.current.initData()
            }).catch(err => {


                antMessage('error', "重置兑换失败", 'error');
            })

    }
    const onClose = (boolean: Boolean) => {
        setModalChoosebranchStoreopen(false)
        if (boolean === true) {
            const currentData: initDataType = {
                apiId,
                sendPageData: { moonTypeId: selectMoonTypeIdData, moonStatus: selectMoonStatusIdData, exchangebranchStoreId: selectExchangebranchStoreIdData, salebranchStoreId: selectSalebranchStoreIdData }
            }
            ref.current.initData(currentData);
        }
    }

    return (
        <>
            <Select
                showSearch
                allowClear
                onChange={moonStatusChange}
                placeholder="票据状态"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(moonStatusData)}
            </Select>
            <span>   </span>

            <Select
                showSearch
                defaultValue="所有类型"
                onChange={moonTypeChange}
                placeholder="票据类型"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(moonTypeData, "moonType")}
            </Select>
            <span>   </span>
            <Select
                showSearch
                defaultValue="所有售出分店"
                onChange={salebranchStoreChange}
                placeholder="售出分店"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(salebranchStoreDate, "branchStoreName")}
            </Select>

            <Select
                showSearch
                defaultValue="所有兑换分店"
                onChange={exchangebranchStoreChange}
                placeholder="兑换分店"
                optionFilterProp="children"
                filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                {getList.general(branchStoreData, "branchStoreName")}
            </Select>
            <span>   </span>
            <WebUniversal props={extendProps} ref={ref} />
            {modalChoosebranchStoreopen ? (<MoonExchange data={branchStoreChooseData} closeModal={onClose}
            />)
                : null}
        </>
    )
}

export default Moon;
