import React, { useEffect, useRef, useState } from 'react';
import { Transfer } from 'antd';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import system from "../../../config/systematic";
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification';

export default function CondimnetWarehouseToMenu(props: any) {

    /**后端数据wmService */
    let wmService = system.wmService;

    const { closeModal, data } = props;

    const ref = useRef<any>();
    let initData: any = ref.current?.initData;

    const [menuData, setMenuData] = useState<any>();

    const [menuWarehouseData, setMenuWarehouseData] = useState<any>([]);
    
    const [shopMenuKeys, setShopMenuKeys] = useState<any>([]);

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            // eslint-disable-next-line react-hooks/exhaustive-deps
            initData = ref.current.initData;
        }
        const fetchData = async () => {
            fetchApi.GET(wmService, {
                apiId: "condimentWarehouse",
                apiExtend: 'showMenuWarehouse',
                apiData: {
                    menuId: data.menuId,
                    branchStoreId: localStorage.getItem("currentBranchStoreId")
                }
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseData(res.data)
                    const newBranchStoreMenuKeys = [];
                    const newFoodWarehouseData = [];
                    for (let j = 0; j < res.data.length; j++) {
                        const data = {
                            key: res.data[j].id.toString(),
                            condimentWarehouseName: res.data[j].condimentWarehouseName,
                            price: res.data[j].price,
                            chosen: res.data[j].isMenu
                        };
                        if (data.chosen) {
                            newBranchStoreMenuKeys.push(data.key);
                        }
                        newFoodWarehouseData.push(data);
                    }
                    setShopMenuKeys(newBranchStoreMenuKeys);
                    setMenuWarehouseData(newFoodWarehouseData);
                }
            });
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleOk = (e: any) => {
        fetchApi.POST(wmService, {
            apiId: "menuCondiment",
            apiExtend: 'menuCondimentAdd',
            menuData: menuData,
            menuId: data.menuId
        }).then((res: any) => {
            if (res.data.message) {
                antMessage('error', res.data.message, 'error');
                closeModal(true);
                // getData();
            }
            else {
                antMessage('success', '操作成功', 'success');
                closeModal(true);
                // getData();
            }
        })
    };

    const handleCancel = (e: any) => {
        closeModal();
    };

    const onChange = (newBranchStoreMenuKeys: any, direction: any, moveKeys: any) => {
        setShopMenuKeys(newBranchStoreMenuKeys);
        setMenuData(newBranchStoreMenuKeys);
    };

    return (
        <>
            <AntDraggableModal
                title={"【 " + data.foodName + " 】—— 添加加料"}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}
            >
                <Transfer
                    dataSource={menuWarehouseData}
                    titles={['加料仓库', '现有加料']}
                    showSearch
                    targetKeys={shopMenuKeys}
                    onChange={onChange}
                    render={item => `${item.condimentWarehouseName + "-----" + item.price}`}
                    pagination
                />
            </AntDraggableModal>
        </>
    );
};