import { Form, Input, InputNumber, Select, Row, Col } from 'antd';
import { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import system from "../../../config/systematic";
import WebIngredientFormList from '../../generalComponents/WebIngredientFormList';
//布局
const layout = {
    // labelCol: { span: 8 },
    // wrapperCol: { span: 16 },
};

const { Option } = Select;

export default function MenuWarehouseIngredientEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data, closeModal } = props;
    const ref = useRef<any>();

    /**计算毛利率 */
    const setPersent = (form: any, e: any) => {
        let grossProfitRatio: any = (e - form.getFieldValue("costPrice")) / e * 100;
        form.setFieldsValue({ grossProfitRatio: grossProfitRatio.toFixed(2) });
    }

    /** 基础数据*/
    const [ingredientBaseData, setIngredientBaseData] = useState<any>(data.type === "EDIT" ? true : false);

    /** 单位数据 */
    const [productUnitData, setProductUnitData] = useState<any>();

    /** 主配料数据 */
    const [ingredientData, setIngredientData] = useState<any>([]);

    /** 规格数据 */
    const [menuWarehouseSpecData, setMenuWarehouseSpecData] = useState<any>();

    /** 菜式仓库数据 */
    const [menuWarehouseData, setMenuWarehouseData] = useState<any>();

    /** 福食菜式仓库数据 */
    const [feastTeamMenuWarehouseData, setFeastTeamMenuWarehouseData] = useState<any>();

    /** 规格的状态 */
    const [disable, setDisable] = useState<any>(false);

    /** 福食菜式仓库和菜式仓库的显示状态 */
    const [isMenuWarehouseDisable, setIsMenuWarehouseDisable] = useState<any>(false);


    /** 合并数据 */
    const [ingredientDetailData, setIngredientDetailData] = useState<any>([]);

    const [tempDeleteData, setTempDeleteData] = useState<any>();

    const [formList, setFormList] = useState<any>({ form, type: data.type, ref, setIngredientDetailData, setTempDeleteData, setPersent })


    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据Service */
    let wmService = system.wmService;

    const getListDisabled = (rows: any, itemName: string) => {
        // console.log(rows);

        //console.log(rows);
        if (typeof rows !== "undefined") {
            if (rows.constructor === Object) {
                rows = [rows]
            } else {
                rows = rows
            }
            if (rows.length === undefined) { return undefined }

            return (rows.map((item: any, key: number) => {
                // console.log(item);

                return <Option value={item.id} key={key} disabled={inArray(item.id, item.menuWarehouseIngredients)}>{item[itemName]}</Option>
            }))
        }
    };

    //查询该规格是否在已设置主配料
    const inArray = (search: any, array: any) => {
        // console.log("-----------------------------------------")
        for (var i in array) {
            // console.log("inarray 里面循环第 " + i + " 次")
            // console.log("数据库里面的数据 " + array[i])
            // console.log("现在提交的数据是 " + search)
            if (array[i].menuWarehouseSpecId === search) {
                return true;
            }
        }
        // console.log("-----------------------------------------")
        return false;
    }

    /**数据 */
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    // console.log(res.data);

                    // const menuWarehouseIngredientId = res.data.id;

                    // fetchApi.GET(wmService, {
                    //     apiId: "menuWarehouseIngredientDetail",
                    //     apiExtend: "showLists",
                    //     apiData: {
                    //         menuWarehouseIngredientId: menuWarehouseIngredientId,
                    //     }
                    // }).then(async res => {
                    // if (res.success) {
                    // console.log(res.data);

                    let new_arr = [];
                    let str_arr: any = [];

                    /** 点击编辑后，根据数据的内容来进行分析，筛选出之前有数据的口味 */
                    for (var i_data = 0, len_data = res.data.length; i_data < len_data; i_data++) {
                        let str_data = "ingredient" + i_data;
                        form.setFieldsValue({ [str_data]: i_data });

                        if (res.data[i_data].length === 0) {
                            str_arr.push(i_data);
                        }
                        else {
                            str_arr.push(999);
                        }
                    }

                    await fetchApi.GET(wmService, {
                        apiId: 'generalData',
                        apiVariable: 'ingredient',
                        apiExtend: 'showList'
                        // eslint-disable-next-line no-loop-func
                    }).then(ress => {
                        
                        new_arr = ress.data.map((item: any) => ({
                            id: item.id,                      //设置字段，在form.list下的form.item中指定的字段值
                            name: item.name,
                            visible: str_arr[item.id] === item.id ? false : item.visible
                        }));
                        setIngredientData(new_arr);
                    });
                    //     }
                    // })
                }
            })
        }

        const fetchData = async () => {

            if (props.data.sendPageData.path === 'MenuWarehouse') {
                form.setFieldsValue({ menuWarehouseId: data.sendPageData.menuWarehouseId });
                setIsMenuWarehouseDisable(true);
            }
            else {
                form.setFieldsValue({ feastTeamMenuWarehouseId: data.sendPageData.feastTeamMenuWarehouseId });
                form.setFieldsValue({ productUnitId: data.sendPageData.productUnitId });
                setIsMenuWarehouseDisable(false);
            }

            if (data.type !== 'NEW') {
                setDisable(true);
            }

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'ingredient',
                apiExtend: 'showList'
            }).then(res => {
                //console.log(res.data);
                setIngredientData(res.data);
                let ingredient_arr = [];
                for (var ingredientid = 0, ingredientlen = res.data.length; ingredientid < ingredientlen; ingredientid++) {
                    ingredient_arr.push([]);
                }
                // console.log(ingredient_arr);
                // setBaseData(ingredient_arr);
                // setFinalData(ingredient_arr);

                for (var i = 0, len = res.data.length; i < len; i++) {
                    let str_data = "ingredient" + res.data[i].id;
                    //let filedStr_data = "ingredientDetail" + res.data[i].id;
                    form.setFieldsValue({ [str_data]: res.data[i].id });
                    //form.setFieldsValue({ [filedStr_data]: '' });
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'menuWarehouseSpec',
                apiExtend: 'showList',
                menuWarehouseId: data.sendPageData.menuWarehouseId
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseSpecData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'menuWarehouse',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'feastTeamMenuWarehouse',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setFeastTeamMenuWarehouseData(res.data);
                }
            });

            fetchApi.GET(wmService, {
                apiId: "productUnit",
                apiExtend: 'showList'
            }).then(res => {
                setProductUnitData(res.data)
            });
        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuWarehouseIngredient'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} totalData={ingredientDetailData} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '菜式主配料'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={1350}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[8, 0]}>
                        <Col span={8}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={isMenuWarehouseDisable ? 8 : 0}>
                            <Form.Item
                                label="菜式名称"
                                name="menuWarehouseId">
                                <Select
                                    disabled
                                    showSearch
                                    placeholder="菜式名称"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(menuWarehouseData, 'foodName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={isMenuWarehouseDisable ? 8 : 0}>
                            <Form.Item
                                label="规格"
                                name="menuWarehouseSpecId"
                                rules={[{
                                    required: isMenuWarehouseDisable ? true : false,
                                    message: '请输入规格!'
                                }]}>
                                <Select
                                    disabled={disable}
                                    showSearch
                                    placeholder="规格"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getListDisabled(menuWarehouseSpecData, 'menuWarehouseSpecName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={isMenuWarehouseDisable ? 0 : 8}>
                            <Form.Item
                                label="福食菜式名称"
                                name="feastTeamMenuWarehouseId">
                                <Select
                                    disabled
                                    showSearch
                                    placeholder="福食菜式名称"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(feastTeamMenuWarehouseData, 'foodName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={isMenuWarehouseDisable ? 0 : 8}>
                            <Form.Item
                                label="福食规格"
                                name="productUnitId"
                            >
                                <Select
                                    disabled
                                    showSearch
                                    placeholder="规格"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(productUnitData, 'productUnitName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="售价"
                                name="sellPrice"
                                rules={[{ required: true, message: '请输入售价!' }]}>
                                <InputNumber min={0} addonBefore="¥" addonAfter="元" onChange={(e) => setPersent(form, e)} />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="成本"
                                name="costPrice"
                                rules={[{ required: true, message: '请填写成本!' }]}>
                                <InputNumber disabled min={0} addonBefore="¥" addonAfter="元" />
                            </Form.Item>
                        </Col>

                        <Col span={8}>
                            <Form.Item
                                label="毛利率"
                                name="grossProfitRatio"
                                rules={[{ required: true, message: '请填写毛利率!' }]}>
                                <InputNumber disabled min={0.01} max={99.99} addonAfter="%" />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[8, 0]}>
                        {
                            ingredientData.length > 0 ? ingredientData.map((item: any, index: any) => {
                                return (
                                    <>
                                        <Col span={24}>
                                            <Row gutter={[8, 0]}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        //label={item.name}
                                                        name={"ingredient" + item.id}
                                                    >
                                                        <Select
                                                            showSearch
                                                            disabled
                                                            placeholder={item.name}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option: any) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                            {getList.general(ingredientData, 'name')}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>

                                                <Col span={24}>
                                                    <WebIngredientFormList props={formList} formName={'ingredientDetail' + item.id} isBase={ingredientBaseData} ingredient={item.id} ingredientLength={ingredientData.length} tempDeleteData={tempDeleteData} ></WebIngredientFormList>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </>
                                )
                            })
                                : null
                        }
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}