import { Select } from "antd";
import fetchApi from "../../utils/api/fetchApi";
import system from '../../config/systematic';
import objectHandle from '../../utils/baseLib/objectHandle';

import { debounce } from 'lodash'
import arrayHandle from "../../utils/baseLib/arrayHandle";
const { Option } = Select;
/** 后端数据wmService */
const wmService = system.wmService;

/**
 * 由数据集生成列表Option
 */
const getList = () => {
};

/**
 * 获取通用列表
 * @param rows 数据集
 * @param itemName Option.item.name，默认：'name'
 * @param itemId Option.item.id，默认：'id'
 * @param noId 没有id键的数组
 * @param includeNull 包括 无(NULL)，默认：true
 * @returns Option 
 */
getList.general = (rows: any, itemName?: string, itemId?: string, noId?: boolean, includeNull?: boolean) => {

    if (typeof rows !== "undefined") {
        if (rows.constructor === Object) {
            rows = [rows]
        }
        /** Option.item.id */
        let itemIdx = 'id';
        if (typeof itemId !== 'undefined') {
            itemIdx = itemId;
        }
        /** Option.item.name */
        let itemNamex = 'name';
        if (typeof itemName !== 'undefined') {
            itemNamex = itemName;
        }
        if (typeof includeNull === 'undefined') {
            includeNull = false;
        }

        if (includeNull === true) {
            rows = rows.concat({
                [itemIdx]: null,
                [itemNamex]: '无（null）'
            });
        }
        if (rows.length === undefined) { return undefined }
        return (rows.map((item: any, key: number) => {

            return <Option value={
                noId === true ?
                    item
                    :
                    item[itemIdx]
            }
                key={key}>
                {noId === true ?
                    item
                    :
                    item[itemNamex]}
            </Option>
        }))
    }
};

/** 获取通用列表的操作类型 */
export declare type generalOPType = {
    /** Option.item.id，默认：'id' */
    itemId?: string,
    /** Option.item.name，默认：'name' */
    itemName?: string,
    /** 没有id键的数组 */
    noId?: boolean,
    /** 包括 无(NULL)，默认：true */
    includeNull?: boolean
};

/**
 * 获取通用列表Ex
 * @param rows 数据集
 * @param generalOP 获取通用列表的操作类型
 * @returns Option 
 */
getList.generalEx = (rows: any, generalOP?: generalOPType) => {
    if (!generalOP) { generalOP = {}; }
    let { itemId, itemName, noId, includeNull } = generalOP;
    return getList.general(rows, itemName, itemId, noId, includeNull);
};

/**
 * 查询
 * @param apiId 接口名称
 * @param setData react useState => setData
 * @param queryString 查询的内容
 * @param options 选项
 * @returns 列表 | undefined
 */
getList.search = debounce(async (
    apiId: string,
    setData: any,
    queryString?: string,
    options?: {
        apiExtend?: any
        andWhereObj?: any
    }
) => {
    if (objectHandle.isEmpty(queryString) === true) {
        console.log('查询不能为空');
        return undefined;
    }
    if (!options) {
        options = {}
    }
    const { andWhereObj, apiExtend } = options;
    let apiExtendEx = "showList";
    if (apiExtend) {
        apiExtendEx = apiExtend
    }
    fetchApi.GET(wmService,
        {
            apiId,
            apiExtend: apiExtendEx,
            apiData: {
                queryString,
                ...andWhereObj
            }
        }).then((res) => {
            if (res.success === true) {
                
                setData(res.data);
                console.log('查询到:');
                console.log(res.data);
                setData(res.data);
            }
        }).catch((err) => {
            console.log(err);
        })
    return setData;
}, 150, {
    leading: false,
    trailing: true
});

/**
 * 查询
 * @param apiId 接口名称
 * @param setData react useState => setData
 * @param queryString 查询的内容
 * @param originalData 原数据
 * @param extendObj 扩展参数
 * @returns 列表 | undefined
 */
getList.searchEx = debounce(async (
    apiId: string,
    setData: any,
    queryString?: string,
    originalData?: any,
    extendObj?: {
        apiExtend?: any
        andWhereObj?: any,
        keyId?: number,
        isFormList?: boolean,
        setObj?: Array<any>,
        setObjValue?: Array<any>,
        form?: any,
        FormListName?: any,
        name?: any,
        selectFun?: Function,
        changeFun?: Function,
        type?: string
    }
) => {
    if (objectHandle.isEmpty(queryString) === true) {
        console.log('查询不能为空');
        return undefined;
    }
    if (!extendObj) {
        extendObj = {}
    }

    const { keyId, isFormList, setObj, setObjValue, form, FormListName, name, selectFun, type, changeFun, andWhereObj, apiExtend } = extendObj;

    let apiExtendEx = "showList";
    if (apiExtend) {
        apiExtendEx = apiExtend
    }

    fetchApi.GET(wmService,
        {
            apiId,
            apiExtend: apiExtendEx,
            apiData: {
                queryString,
                ...andWhereObj
            }
        }).then((res) => {
            
            if (res.success === true) {
                if (res.data.length > 0 && form && setObj && setObjValue) {
                    if (res.data.length === 1) {
                        let data = res.data[0];
                        if (selectFun) {
                            selectFun(data.id)
                        }
                        if (changeFun) {
                            changeFun(form, "", "", name, true, FormListName, type, true)
                        }
                        for (var i = 0; i < setObj.length; i++) {
                            if (setObjValue[i].includes("#")) {
                                let spiltData = setObjValue[i].split("#")
                                if (spiltData.length > 0) {
                                    for (var j = 0; j < spiltData.length; j++) {

                                        if (j + 1 < spiltData.length) {

                                            if (data[spiltData[j]][spiltData[j + 1]] !== "undefined") {
                                                if (isFormList) {
                                                    form.setFieldValue([FormListName, name, setObj[i]], data[spiltData[j]][spiltData[j + 1]]);


                                                } else {
                                                    form.setFieldValue(setObj[i], data[spiltData[j]][spiltData[j + 1]]);
                                                }
                                            }
                                        }

                                    }
                                }


                            } else {

                                if (isFormList) {
                                    form.setFieldValue([FormListName, name, setObj[i]], data[setObjValue[i]]);
                                } else {

                                    form.setFieldValue(setObj[i], data[setObjValue[i]])
                                }

                            }
                        }
                    }
                    if (res.data.length > 1) {
                        let newData: any = arrayHandle.merge(arrayHandle.findData(res.data, "productName", queryString), arrayHandle.findData(res.data, "selfId", queryString));

                        if (newData.length === 1) {
                            if (changeFun) {
                                changeFun(form, "", "", name, true, FormListName, type, true)
                            }
                            let data = newData[0];
                            for (var i = 0; i < setObj.length; i++) {
                                if (setObjValue[i].includes("#")) {
                                    let spiltData = setObjValue[i].split("#")
                                    if (spiltData.length > 0) {
                                        for (var j = 0; j < spiltData.length; j++) {

                                            if (j + 1 < spiltData.length) {

                                                if (data[spiltData[j]][spiltData[j + 1]] !== "undefined") {
                                                    if (isFormList) {
                                                        form.setFieldValue([FormListName, name, setObj[i]], data[spiltData[j]][spiltData[j + 1]]);


                                                    } else {
                                                        form.setFieldValue(setObj[i], data[spiltData[j]][spiltData[j + 1]]);
                                                    }
                                                }
                                            }

                                        }
                                    }


                                } else {
                                    if (isFormList) {
                                        form.setFieldValue([FormListName, name, setObj[i]], data[setObjValue[i]]);
                                    } else {

                                        form.setFieldValue(setObj[i], data[setObjValue[i]])
                                    }
                                }
                            }
                        }

                    }


                }
                if (res.data.length === 0) {
                    if (isFormList && setObj) {
                        for (var i = 0; i < setObj.length; i++) {
                            form.setFieldValue([FormListName, name, setObj[i]], null);
                        }
                    }
                }

                isFormList ? keyId || keyId === 0 ? setData({
                    ...originalData,
                    [keyId]: res.data
                }) : setData({
                    0: res.data
                }) : setData(res.data)


                console.log('查询到:');
                console.log(res.data);

            }
        }).catch((err) => {
            console.log(err);

        })




    return setData;
}, 150, {
    leading: false,
    trailing: true
})

export interface generalOptionEx {
    /** Option.item.name，默认：'name' */
    itemName?: string,
    /** Option.item.id，默认：'id' */
    itemId?: string,
    /** 没有id键的数组 */
    noId?: boolean,
    /** 是否自定义显示数据 */
    label?: string,
    /** 自定义下拉框显示 */
    children?: any,
    /** 是否禁用 */
    isDisabled?: boolean,
    /** 条件 */
    abConditions?: any
}
/**
 * 获取通用列表
 * @param rows 数据集
 * @param extendObj 选项，generalOptionEx
 * @returns Option 
 */
getList.generalOption = (rows: any, extendObj: generalOptionEx) => {
    const { itemName, itemId, noId, label, children, isDisabled, abConditions } = extendObj


    let itemNameX = "name"
    if (typeof itemName !== "undefined") {
        itemNameX = itemName
    }
    let itemIdx = 'id';
    if (typeof itemId !== 'undefined') {
        itemIdx = itemId;
    }

    if (typeof rows !== "undefined") {

        if (rows.constructor === Object) {
            rows = [rows]
        }
        if (rows.length === undefined) { return undefined }
        return (rows.map((item: any, key: number) => {


            return <Option disabled={isDisabled ? Number(item[abConditions]) === 0 ? true : false : false} label={label ? item[label] : item[itemNameX]} value={
                noId === true ?
                    item
                    :
                    item[itemIdx]
            }

                key={
                    noId === true ?
                        item : item[itemIdx]}>
                {children ?
                    children(item)
                    : noId === true ?
                        item : item[itemNameX]}
            </Option>
        }))

    }
};


/**antd 5.0 select框写法 */
getList.generalSelcetOption = (rows: any, itemName?: string, itemId?: string, children?: any) => {

    if (typeof rows === "undefined") return []
    if (rows.constructor === Object) {
        rows = [rows]
    }
    let returnData: Array<any> = [];
    let itemNamex = 'name';
    let itemIdx = 'id';
    if (typeof itemId !== 'undefined') {
        itemIdx = itemId;
    }
    if (typeof itemName !== 'undefined') {
        itemNamex = itemName;
    }
    rows.forEach((item: any) => {
        returnData.push({
            label: children ? children(item) : item[itemNamex],
            value: item[itemIdx],
            name: item[itemIdx]
        })
    })


    return returnData;


}

export default getList;