import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import ShopTypeEdit from './ShopTypeEdit';
import { Button, Space, Switch } from 'antd';
import MenuSalePeriod from '../menu/MenuSalePeriod';
import { useState } from 'react';

export default function ShopType(props: any) {

    //销售周期窗口
    const [menuSalePeriodVisible, setMenuSalePeriodVisible] = useState(false);

    //销售周期数据
    const [menuSalePeriodData, setMenuSalePeriodData] = useState<any>();

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.shopCategory.branchStore.brand.brandName + " " + record.shopCategory.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '大分类',
            dataIndex: 'shopCategoryName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.shopCategory.shopCategoryName}</div>
                )
            }
        },
        {
            title: '小分类名称',
            dataIndex: 'shopTypeName',
        },
        {
            title: '小分类英文',
            dataIndex: 'shopTypeNameEn',
        },
        {
            title: '销售周期',
            dataIndex: 'menuSalePeriod',
            key: 'menuSalePeriod',
            render: (text: any, record: any) => {
                return (
                    <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => handleMenuSalePeriod(record)}>销售周期</Button>
                    </Space>
                )
            }
        },
        {
            title: '客服专用',
            dataIndex: 'isStaff',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                // onChange={(e) => handleChange(record.id, 'isStaff', e)}
                />
            )
        },
        {
            title: '打折',
            dataIndex: 'isDiscount',
            render: (text: any, record: any) => (
                <Switch checkedChildren="是" unCheckedChildren="否"
                    checked={text} key={record.id}
                />
            )
        },
        {
            title: '最高折扣',
            dataIndex: 'maxDiscount',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    //打开销售时段页面
    const handleMenuSalePeriod = (record: any) => {
        //console.log(record)
        sendData.shopTypeId = record.id;
        setMenuSalePeriodData(sendData);
        setMenuSalePeriodVisible(true);
    };

    //关闭销售时段页面
    const onCloseMenuSalePeriod = async (status: boolean) => {
        setMenuSalePeriodVisible(false);
        // if (status) {
        //     await initData();
        // }
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    //传关参数
    let sendData = {
        type: 'EDIT',
        shopTypeId: ''
    }

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜谱分类',
        apiId: 'shopType',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: ShopTypeEdit,
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>

            {
                menuSalePeriodVisible
                    ? (<MenuSalePeriod
                        closeModal={onCloseMenuSalePeriod} data={menuSalePeriodData} />)
                    : null
            }
        </>
    )
}