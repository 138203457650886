import { Col, Form, Input, InputNumber, Row, Switch } from 'antd';
import React, { useEffect, useRef } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function ShopCategoryEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    // const [brandData, setBrandData] = useState<any>();

    /**品牌的状态 */
    // const [brandIdDisable, setBrandIdDisable] = useState<any>(false);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = "";

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });
        }
        const fetchData = async () => {
            // fetchApi.GET(wmService, {
            //     apiId: "brand",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         setBrandData(res.data)
            //     }
            // });

            // fetchApi.GET(wmService, {
            //     apiId: "menuWarehouse",
            //     apiExtend: 'getBrandId'
            // }).then(res => {
            //     if (res.success) {
            //         if (res.data.key !== 'manager') {
            //             setBrandIdDisable(true);
            //             form.setFieldsValue({ brandId: res.data.brand.id });
            //         }
            //     }
            // });

        }
        fetchData()
    }, [])

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'shopCategory'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '菜谱大分类'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="门店Id"
                                name="branchStoreId"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="菜谱大分类"
                                name="shopCategoryName"
                                rules={[{ required: true, message: '请输入菜谱大分类名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        {/* <Col span={24}>
                            <Form.Item
                                label="品牌"
                                name="brandId"
                                rules={[{ required: true, message: '请选择品牌!' }]}>
                                <Select
                                    showSearch
                                    disabled={brandIdDisable}
                                    placeholder="品牌"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(brandData, 'brandName')}
                                </Select>
                            </Form.Item>
                        </Col> */}

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>

                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal >
        </>
    )
}