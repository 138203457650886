import { Form, Row, Col, Select, Button, Anchor, Switch } from 'antd';
import { useEffect, useState } from 'react';
import fetchApi from '../../../utils/api/fetchApi';
import systematic from '../../../config/systematic';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification'
import stringHandle from '../../../utils/baseLib/stringHandle';

/** 后端数据defaultService */
const defaultService = systematic.wmService;

/**
 * 系统配置
 * @returns 
 */
const Configs = () => {
    const [form] = Form.useForm(); //form 
    const [modalVisible, setModalVisible] = useState(true);
    const [nodesData, setNodesData] = useState();
    const [divCmpt, setDivCmpt] = useState<any>();
    const [divCmpt2, setDivCmpt2] = useState<any>();

    const [divCmpt3, setDivCmpt3] = useState<any>();

    const [currentNodeId, setCurrentNodeId] = useState<any>();

    let [filesServerData, setFilesServerData] = useState<any>();

    let [filesGroupData, setFilesGroupData] = useState<any>();

    let [wiscaDiskGroupData, setWiscaDiskGroupData] = useState<any>();

    let [filesServiceList, setFilesServiceList] = useState<any>();

    let [wiscaDiskServiceList, setWiscaDiskServiceList] = useState<any>();
    /**用户数据 */
    const [userData, setUserData] = useState<any>();

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
            let arr: any = [];
            arr = arr.concat({
                key: 'general',
                href: '#general',
                title: '通用',
                id: 'general'
            })
            arr = arr.concat({
                key: 'files',
                href: '#files',
                title: '文件',
                id: 'files'
            })
            arr = arr.concat({
                key: 'functionalAuthority',
                href: '#functionalAuthority',
                title: '功能权限',
                id: 'functionalAuthority'
            })
            // Functional authority
            setNodesData(arr);

            // fetchApi.GET(defaultService, {
            //     apiId: "user",
            //     apiExtend: "showList"
            // }).then((res) => {
            //     setUserData(res.data)

            // })
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    /** 确定 */
    const handleOk = async (e: any) => {
        if (!currentNodeId) return false;
        let userId = form.getFieldValue("userId")
        console.log("userId", userId);


        // return false
        form.validateFields()
            .then(async (values: any) => {


                fetchApi.PUT(defaultService,
                    {
                        apiId: 'configNodes',
                        apiExtend: 'setConfig',
                        apiData: {
                            id: 'config',
                            configName: '系统设置',
                            isUser: userId ? true : false,
                            userId,
                            nodes: {
                                [currentNodeId]: values
                            }
                        }
                    }).then(res => {
                        if (res.success) {
                            antMessage('success', '保存数据成功！', 'success');
                            // setModalVisible(false);
                        }
                    }).catch(err => {
                        antMessage('error', '保存数据失败！', err.message);
                    });

            })
        // setModalVisible(false)
    };

    /** 取消 */
    const handleCancel = async (e: any) => {
        setModalVisible(false)
    };

    /**
     * 获取文件服务器 服务号 列表
     * @param filesServerId 服务器id
     */
    const getFilesServiceList = async (filesServerId: string, name: string) => {
        // let filesServiceList: any;
        try {
            const res = await fetchApi.GET(defaultService, {
                apiId: 'filesServer',
                apiExtend: 'getDefaultServer',
                apiVariable: filesServerId
            });
            if (res.success === true) {
                const { api } = res.data;
                const { manageServiceUrl
                } = api;
                const filesServiceRes = await fetchApi.GET(manageServiceUrl, {
                    apiId: 'service',
                    apiExtend: 'showList'
                })
                if (filesServiceRes.success === true) {
                    setFilesServiceList(filesServiceRes.data)
                }
            }
        }
        catch (err: any) {
            antMessage('error', '无法获取数据！', err.message);
        }


    };

    /**
   * 获取文件服务器 服务号 列表
   * @param filesServerId 服务器id
   */
    const getWiscaDiskServiceList = async (filesServerId: string, name: string) => {
        // let filesServiceList: any;
        try {
            const res = await fetchApi.GET(defaultService, {
                apiId: 'filesServer',
                apiExtend: 'getDefaultServer',
                apiVariable: filesServerId
            });
            if (res.success === true) {
                const { api } = res.data;
                const { manageServiceUrl
                } = api;
                const filesServiceRes = await fetchApi.GET(manageServiceUrl, {
                    apiId: 'service',
                    apiExtend: 'showList'
                })
                if (filesServiceRes.success === true) {
                    setWiscaDiskServiceList(filesServiceRes.data)
                    // filesServiceList = filesServiceRes.data;
                }
            }
        }
        catch (err: any) {
            antMessage('error', '无法获取数据！', err.message);
        }

    };

    /**
     * 初始化组件
     * @param nodeId 节点id
     */
    const getDivComponent = async (nodeId: any) => {
        nodeId = stringHandle.removeExtension(nodeId, '#', 3);

        setCurrentNodeId(nodeId)
        //获取节点数据
        let nowFilesServerId;
        let nowWiscaDiskServerId;
        let nowFilesServiceId;
        let nowwiscaDiskServiceId;

        try {
            /** config res */
            const configRes = await fetchApi.GET(defaultService,
                {
                    apiId: 'configNodes',
                    apiExtend: 'getConfig',
                    apiData: {
                        id: 'config',
                        nodeId: "general"
                    }
                })
            if (configRes.success === true) {
                const { filesServerId, filesServiceId, wiscaDiskServerId, wiscaDiskServiceId } = configRes.data;
                nowFilesServerId = filesServerId;
                nowWiscaDiskServerId = wiscaDiskServerId;
                nowFilesServiceId = filesServiceId;
                nowwiscaDiskServiceId = wiscaDiskServiceId;


                form.setFieldValue('filesServerId', filesServerId);
                form.setFieldValue('filesServiceId', filesServiceId);
                form.setFieldValue('wiscaDiskServerId', wiscaDiskServerId);
                form.setFieldValue('wiscaDiskServiceId', wiscaDiskServiceId);

            }
        } catch (err: any) {
            antMessage('error', '无法获取数据！', err.message);
        }

        switch (nodeId) {
            case 'general': //通用配置节点
                //1) 初始化
                /** 文件服务器列表 */
                // let filesServerData;
                try {
                    const filesServerRes = await fetchApi.GET(defaultService, {
                        apiId: 'filesServer',
                        apiExtend: 'showList'
                    });
                    if (filesServerRes.success === true) {
                        setFilesServerData(filesServerRes.data)
                        // filesServerData = filesServerRes.data;
                    }
                }
                catch (err: any) {
                    antMessage('error', '无法获取数据！', err.message);
                }
                // //2) 生成组件
                // setDivCmpt(
                //     <>
                //         {/* 以下可以修改 */}

                //         {/* --- */}
                //     </>
                // );
                //3) 获取节点内容
                //以下可以修改
                getFilesServiceList(nowFilesServerId, "files");
                getWiscaDiskServiceList(nowWiscaDiskServerId, "wiscaDisk");


                //--------

                break;

            case 'files': //文件配置节点
                // setDivCmpt2(undefined)
                // setDivCmpt3(undefined)

                //1) 初始化
                // let filesGroupData;
                // let wiscaDiskGroupData;
                try {

                    const resFiles = await fetchApi.GET(defaultService, {
                        apiId: 'filesServer',
                        apiExtend: 'getDefaultServer',
                        apiVariable: nowFilesServerId
                    });

                    if (resFiles.success === true) {
                        const { api } = resFiles.data;
                        const { manageServiceUrl
                        } = api;
                        const filesGroupRes = await fetchApi.GET(manageServiceUrl, {
                            apiId: 'filesGroup',
                            apiExtend: 'showList'
                        }, {
                            serviceId: nowFilesServiceId
                        })
                        if (filesGroupRes.success === true) {
                            setFilesGroupData(filesGroupRes.data)
                            // filesGroupData = ;
                        }
                    }
                    const resWiscaDisk = await fetchApi.GET(defaultService, {
                        apiId: 'filesServer',
                        apiExtend: 'getDefaultServer',
                        apiVariable: nowWiscaDiskServerId
                    });
                    if (resWiscaDisk.success === true) {
                        const { api } = resWiscaDisk.data;
                        const { manageServiceUrl
                        } = api;
                        const filesGroupRes = await fetchApi.GET(manageServiceUrl, {
                            apiId: 'filesGroup',
                            apiExtend: 'showList'
                        }, {
                            serviceId: nowwiscaDiskServiceId
                        })
                        if (filesGroupRes.success === true) {
                            setWiscaDiskGroupData(filesGroupRes.data)
                        }
                    }
                }
                catch (err: any) {
                    console.log("err", err);

                    antMessage('error', '无法获取数据！', err.message);
                }
                //2) 生成组件
                setDivCmpt(<>
                    {/* 以下可以修改 */}


                    {/* --- */}
                </>);

                //3) 获取节点内容
                fetchApi.GET(defaultService,
                    {
                        apiId: 'configNodes',
                        apiExtend: 'getConfig',
                        apiData: {
                            id: 'config',
                            nodeId
                        }
                    }).then(res => {
                        if (res.success) {
                            try {
                                const { roomFilesGroupId,
                                    roomTypeFilesGroupId,
                                    menuSpecFilesGroupId,
                                    adFilesGroupId,
                                    menuWarehouseFilesGroupId,
                                    assetStatementFilesGroupId,
                                    branchStoreFilesGroupId,
                                    articleFilesGroupId
                                } = res.data;
                                //以下可以修改
                                // menuWarehouseFilesGroupId
                                form.setFieldValue('roomFilesGroupId', roomFilesGroupId);
                                form.setFieldValue('roomTypeFilesGroupId', roomTypeFilesGroupId);
                                form.setFieldValue('menuSpecFilesGroupId', menuSpecFilesGroupId);
                                form.setFieldValue('adFilesGroupId', adFilesGroupId);
                                form.setFieldValue('menuWarehouseFilesGroupId', menuWarehouseFilesGroupId);
                                form.setFieldValue('assetStatementFilesGroupId', assetStatementFilesGroupId);
                                form.setFieldValue('branchStoreFilesGroupId', branchStoreFilesGroupId);
                                form.setFieldValue('articleFilesGroupId', articleFilesGroupId);

                                //--------
                            }
                            catch (err: any) {
                                antMessage('error', '无法获取数据！', err.message);
                            }
                        }
                    }).catch(err => {
                        antMessage('error', '无法获取数据！', err.message);
                    });

                break;



            case 'functionalAuthority': //功能节点配置

                try {

                }
                catch (err: any) {
                    antMessage('error', '无法获取数据！', err.message);
                }

                //3) 获取节点内容
                fetchApi.GET(defaultService,
                    {
                        apiId: 'configNodes',
                        apiExtend: 'getConfig',
                        apiData: {
                            id: 'config',
                            nodeId
                        }
                    }).then(res => {
                        if (res.success) {
                            console.log("res", res);

                            try {
                                const { checkDeptAudit,
                                    checkEntrepotAudit,
                                    checkSubscribe,
                                    checkSubscribeCancel,
                                    checkSubscribeFinish,
                                    deptAuditSignature,
                                    subscribeOrder,
                                    subscribeSignature
                                } = res.data;
                                //以下可以修改
                                // menuWarehouseFilesGroupId
                                form.setFieldValue('checkDeptAudit', checkDeptAudit);
                                form.setFieldValue('checkEntrepotAudit', checkEntrepotAudit);
                                form.setFieldValue('checkSubscribe', checkSubscribe);
                                form.setFieldValue('checkSubscribeCancel', checkSubscribeCancel);
                                form.setFieldValue('checkSubscribeFinish', checkSubscribeFinish);
                                form.setFieldValue('deptAuditSignature', deptAuditSignature);
                                form.setFieldValue('subscribeOrder', subscribeOrder);
                                form.setFieldValue('subscribeSignature', subscribeSignature);


                                //--------
                            }
                            catch (err: any) {
                                antMessage('error', '无法获取数据！', err.message);
                            }
                        }
                    }).catch(err => {
                        antMessage('error', '无法获取数据！', err.message);
                    });
                break;

            // functionalAuthority
            default:


                break;
        }
    };
    return (
        <>
            <AntDraggableModal
                title={'系统设置'}
                open={modalVisible}
                okText='保存'
                onOk={handleOk}
                onCancel={handleCancel}
                // cancelButtonProps={{ style: { display: "none" } }}
                closable={false}
                maskClosable={true}
                width={800}>
                <Anchor
                    affix={false}
                    direction='horizontal'
                    items={nodesData}
                    onChange={getDivComponent}
                />
                <br />
                <div id='main'>
                    <Form
                        form={form}
                        name="Edit">
                        <Row gutter={[8, 0]}>

                            {currentNodeId === "general" ?
                                <>
                                    <Col span={12}>
                                        <Form.Item
                                            label="files文件服务器"
                                            name="filesServerId">
                                            <Select
                                                showSearch
                                                placeholder="文件服务器"
                                                optionFilterProp="children"
                                                onChange={(e) => getFilesServiceList(e, "files")}
                                                filterOption={(input, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {getList.general(filesServerData, 'serverName')}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label="wiscaDisk文件服务器"
                                            name="wiscaDiskServerId">
                                            <Select
                                                showSearch
                                                placeholder="文件服务器"
                                                optionFilterProp="children"
                                                onChange={(e) => getWiscaDiskServiceList(e, "wiscaDisk")}
                                                filterOption={(input, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {getList.general(filesServerData, 'serverName')}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item
                                            label={`files服务id`}
                                            name={`filesServiceId`}>
                                            <Select
                                                showSearch
                                                placeholder="服务id"
                                                optionFilterProp="children"
                                                filterOption={(input, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {getList.generalEx(filesServiceList, {
                                                    itemName: 'serviceName'
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>


                                    <Col span={12}>
                                        <Form.Item
                                            label={`wiscaDisk服务id`}
                                            name={`wiscaDiskServiceId`}>
                                            <Select
                                                showSearch
                                                placeholder="服务id"
                                                optionFilterProp="children"
                                                filterOption={(input, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {getList.generalEx(wiscaDiskServiceList, {
                                                    itemName: 'serviceName'
                                                })}
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                </> : ""}

                            {currentNodeId === "files" ? <>
                                <Col span={12}>
                                    <Form.Item
                                        label="房间图片组"
                                        name="roomFilesGroupId">
                                        <Select
                                            showSearch
                                            placeholder="文件组"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(filesGroupData, 'groupName')}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="房间类型图片组"
                                        name="roomTypeFilesGroupId">
                                        <Select
                                            showSearch
                                            placeholder="文件组"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(filesGroupData, 'groupName')}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item
                                        label="菜式图片组"
                                        name="menuSpecFilesGroupId">
                                        <Select
                                            showSearch
                                            placeholder="文件组"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(filesGroupData, 'groupName')}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="广告图片组"
                                        name="adFilesGroupId">
                                        <Select
                                            showSearch
                                            placeholder="文件组"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(filesGroupData, 'groupName')}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="菜式仓库组"
                                        name="menuWarehouseFilesGroupId">
                                        <Select
                                            showSearch
                                            placeholder="文件组"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(wiscaDiskGroupData, 'groupName')}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="资产表图片组"
                                        name="assetStatementFilesGroupId">
                                        <Select
                                            showSearch
                                            placeholder="文件组"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(filesGroupData, 'groupName')}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="分店图片组"
                                        name="branchStoreFilesGroupId">
                                        <Select
                                            showSearch
                                            placeholder="分店图片组"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(filesGroupData, 'groupName')}
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={12}>
                                    <Form.Item
                                        label="文章图片组"
                                        name="articleFilesGroupId">
                                        <Select
                                            showSearch
                                            placeholder="文章图片组"
                                            optionFilterProp="children"
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                            {getList.general(filesGroupData, 'groupName')}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </> : ""}
                            {currentNodeId === "functionalAuthority" ? <>
                                <Col span={6}>
                                    <Form.Item
                                        label="申购下单"
                                        name="subscribeOrder"
                                        valuePropName="checked">
                                        < Switch checked={false}
                                            checkedChildren="启用"
                                            unCheckedChildren="关闭" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label="申购签名"
                                        name="subscribeSignature"
                                        valuePropName="checked">
                                        < Switch checked={false}
                                            checkedChildren="启用"
                                            unCheckedChildren="关闭" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label="部门审核签名"
                                        name="deptAuditSignature"
                                        valuePropName="checked">
                                        < Switch checked={false}
                                            checkedChildren="启用"
                                            unCheckedChildren="关闭" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label="查看申购下单"
                                        name="checkSubscribe"
                                        valuePropName="checked">
                                        < Switch checked={false}
                                            checkedChildren="启用"
                                            unCheckedChildren="关闭" />
                                    </Form.Item>
                                </Col>
                                <Col span={6}>
                                    <Form.Item
                                        label="查看部门审核"
                                        name="checkDeptAudit"
                                        valuePropName="checked">
                                        < Switch checked={false}
                                            checkedChildren="启用"
                                            unCheckedChildren="关闭" />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        label="查看仓库审核"
                                        name="checkEntrepotAudit"
                                        valuePropName="checked">
                                        < Switch checked={false}
                                            checkedChildren="启用"
                                            unCheckedChildren="关闭" />
                                    </Form.Item>
                                </Col>


                                <Col span={6}>
                                    <Form.Item
                                        label="查看申购取消"
                                        name="checkSubscribeCancel"
                                        valuePropName="checked">
                                        < Switch checked={false}
                                            checkedChildren="启用"
                                            unCheckedChildren="关闭" />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        label="查看申购完成"
                                        name="checkSubscribeFinish"
                                        valuePropName="checked">
                                        < Switch checked={false}
                                            checkedChildren="启用"
                                            unCheckedChildren="关闭" />
                                    </Form.Item>
                                </Col>

                                <Col span={6}>
                                    <Form.Item
                                        label="指定用户"
                                        name="userId"
                                    >
                                        <Select
                                            allowClear
                                            showSearch
                                            placeholder="用户"
                                            optionFilterProp="children"
                                            onSearch={(e) => getList.search('user', setUserData, e, {


                                            })}
                                            filterOption={(input, option: any) =>
                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                        >
                                            {getList.general(userData, 'userName')}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </> : ""}

                            {/* {divCmpt}
                            {divCmpt2}
                            {divCmpt3} */}

                            {/* {buttonCmpt} */}
                        </Row>
                    </Form>
                </div>
            </AntDraggableModal>
        </>
    )
};

/**
 * 获取默认文件服务器
 */
Configs.getDefaultFilesServer = async () => {
    let res: any = {
        success: false,
        data: undefined
    };
    const configNodesRes = await fetchApi.GET(defaultService,
        {
            apiId: 'configNodes',
            apiExtend: 'getConfig',
            apiData: {
                id: 'config',
                nodeId: 'general'
            }
        })
    if (configNodesRes.success === true) {
        const { filesServerId } = configNodesRes.data;
        const filesServerRes = await fetchApi.GET(defaultService,
            {
                apiId: 'filesServer',
                apiVariable: filesServerId
            })
        res = filesServerRes
    }
    return res;
}

export default Configs;
