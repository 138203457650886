import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'


const EditComponent = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据defaultService */
    let defaultService: any = '';
    // const [branchStoreData, setBranchStoreData] = useState<any>();
    // const [roomData, setRoomData] = useState<any>();

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            defaultService = ref.current.wmService;
        }
        //同步获取数据 async - await
        const fetchData = async () => {
            // fetchApi.GET(defaultService,
            //     {
            //         apiId: 'branchStore',
            //         apiExtend: 'showList'
            //     }).then(res => {
            //         if (res.success) {
            //             setBranchStoreData(res.data);
            //         }
            //     });
            // fetchApi.GET(defaultService,
            //     {
            //         apiId: 'room',
            //         apiExtend: 'showList'
            //     }).then(res => {
            //         if (res.success) {
            //             setRoomData(res.data);
            //         }
            //     });
        };
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'cateringOrder',
    };

    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '餐饮订单'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}>
                    <Row>

                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input  disabled/>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="人数"
                                name="headCount">
                                <InputNumber  />
                            </Form.Item>
                        </Col>

                        {/* <Col span={12}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col> */}

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default EditComponent;