import React from 'react'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';

export default function MenuShoppingCart(props: any) {

    //表头
    const columnsHead = [
        {
            title: '微信',
            dataIndex: 'unionId',
        },
        {
            title: '分店',
            dataIndex: 'branchStoreName',
        },
        {
            title: '房间',
            dataIndex: 'room',
            render: (text: any, record: any) =>
                <div>{record.room ? record.room.roomName : ""}</div>
        }, {
            title: '菜式',
            dataIndex: 'foodName',
        },
        {
            title: '规格',
            dataIndex: 'menuSpecName',
        },
        {
            title: '特殊规格',
            dataIndex: 'menuSpecSpecialName',
        },
        {
            title: '加料',
            dataIndex: 'condiments',
        },
        {
            title: '备注',
            dataIndex: 'memos',
        },
        {
            title: '加入时价格',
            dataIndex: 'priceAtJoin',
        },
        {
            title: '数量',
            dataIndex: 'amount',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '购物车',
        apiId: 'menuShoppingCart',
        showStates: true,
        showDelete: true,
        showEdit: false,
        showAdd: false,
        columns,
        pagination: true,
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}