import React, { useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import { Button, Col, Divider, Form, Input, InputNumber, Row, Select } from 'antd';
import systemConfig from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import antMessage from '../../../utils/extend/AntdNotification'
import moment from 'moment';


/**结账页面 */
export default function SetAccounts(props: any) {
    const { data, closeModal } = props;
    const [form] = Form.useForm(); //form 

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    const [order, setOrder] = useState<any>({});

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formListData = Form.useWatch("paymentMethodList", form) as any;

    const payModeId = Form.useWatch("payModeId", form) as any;






    /** 分店支付方式 */
    const [paymentListData, setPaymentListData] = useState<Array<any>>([]);


    /**支付模式 */
    const [payMode, setPayMode] = useState<Array<any>>();




    const handleOk = (e: any) => {
        setIsLoading(true)
        form.validateFields() //数据校验
            .then(async (values: any) => {
                
                fetchApi.POST(wmService, {
                    apiId: "cateringOrder",
                    apiExtend: "settleAccounts",
                    apiData: {
                        orderId: data.id,
                        tempRoomId: data.tempRoomId,
                        ...values,
                        // money:0.01,
                        // menuSpe:selectMenuSpeData
                    }
                }).then(res => {

                    setIsLoading(false)
                    antMessage('success', '新增成功', 'success');
                    closeModal(true);
                }).catch(err => {
                    setIsLoading(false)

                    antMessage('error', '错误，无法保存', err.message + ' | status：' + err.errors.status);
                })
            })
            .catch((err: any) => {
                setIsLoading(false)
                const { errorFields } = err;
                let errors = errorFields[0].errors[0]

                antMessage('warning', '无法通过数据校验', errors);
            });
    }
    const handleCancel = (e: any) => {
        closeModal(false)

    }

    //初始化数据
    const initData = () => {

        fetchApi.GET(wmService, {
            apiId: "cateringOrder",
            apiExtend: "getSettleAccounts",
            apiData: {
                id: data.id
            }
        }).then((res) => {
            form.setFieldsValue({
                ...res.data,
                payModeId: 1,
                money: res.data.shouldMoney
            })

            setOrder(res.data)
        }).catch((err) => {
            console.log("err", err);
            closeModal(false)
            antMessage("error", '错误', err.message);
        })
    }
    useEffect(() => {
        const fetchData = async () => {
            fetchApi.GET(wmService,
                {
                    apiId: 'paymentList',
                    apiExtend: 'showList',
                    apiData: {
                        mountDataOptions: {
                            userMount: {
                                isDept: true,
                            }
                        },
                    }
                }).then(res => {
                    if (res.success) {
                        form.setFieldValue("paymentMethodList", res.data)
                        setPaymentListData(res.data)
                        form.setFieldValue("paymentListId",res.data[0].id)
                    } else {
                        antMessage("error", '无支付信息', '错误');

                    }
                });
            fetchApi.GET(wmService, {
                apiId: "generalData",
                apiExtend: 'showList',
                apiVariable: "payMode",
            }).then(res => {
                if (res.success) {

                    setPayMode(res.data)
                }
            })
        }

        fetchData()
        initData()

    }, [])



    const onChangeMoney = (name: any, value: any) => {

    }

    const changePayMode = (e: any) => {
        if(e===1){
            if(paymentListData.length>0){
                form.setFieldValue("paymentListId",paymentListData[0].id)
            }
            
        }

    }

    return (
        <>
            <AntDraggableModal
                title={"详情"}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={"60%"}
                confirmLoading={isLoading}

            >
                <Row>
                    {/* <Col span={12}>
                        <div >
                            <div>单号:{order.id}</div>
                            <div>客人姓名:{order.customerName}</div>
                            <div>人数:{order.headCount}</div>
                            <div>房台号:{order.roomNum}</div>
                            <div>房名:{order.roomName}</div>
                            <Divider dashed />
                            <div>市别名:{order.sbName}</div>
                            <div>下单人:{order.userName}</div>
                            <Divider dashed />
                            <div>开台时间:{moment(order.orderDate).format("YYYY-MM-DD")}</div>
                            <div>收银:{order.cashier}</div>

                        </div>
                    </Col> */}

                    <Col span={12}>
                        <div >
                            <Form
                                form={form}
                                name="Edit"
                                onFinish={handleOk}
                                onKeyDown={(e: any) => {
                                    if (e.key === 'Enter') {
                                        handleOk(e)
                                    }
                                }}>
                                <Row>

                                    <Col span={24}>
                                        <Form.Item
                                            label="消费总额"

                                            name="totalMoney">
                                            <InputNumber precision={2} disabled addonAfter={"元"} ></InputNumber>
                                        </Form.Item>
                                    </Col>



                                    <Col span={24}>
                                        <Form.Item
                                            label="折扣额"
                                            name="agioMoney">
                                            <InputNumber precision={2} addonAfter={"元"} disabled ></InputNumber>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            label="服务费率"
                                            name="serviceRate"

                                        >
                                            <InputNumber disabled ></InputNumber>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="服务费"
                                            name="serviceCharge">
                                            <InputNumber precision={2} disabled addonAfter={"元"} ></InputNumber>
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            label="应收金额"
                                            name="shouldMoney">
                                            <InputNumber precision={2} disabled addonAfter={"元"} ></InputNumber>
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item
                                            label="支付模式"
                                            name="payModeId"
                                            rules={[{ message: '请选择支付模式!', required: true }]}>
                                            <Select
                                                showSearch
                                                placeholder="支付模式"
                                                optionFilterProp="children"
                                                onChange={changePayMode}
                                                filterOption={(input, option: any) =>
                                                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                {getList.general(payMode)}
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                    {
                                        payModeId === 2 ? <Col span={24}>

                                            <Form.List name="paymentMethodList"
                                                rules={[
                                                    {
                                                        validator: async (_, names) => {
                                                            if (names.length === 0) return Promise.reject(new Error("无支付信息"));
                                                            let isMoney = false;
                                                            let dataEx = form.getFieldsValue()
                                                            const { shouldMoney } = dataEx
                                                            let shouldMoneyEx = 0;

                                                            if (names.length > 0) {
                                                                for (let i = 0; i < names.length; i++) {
                                                                    let money = names[i].money;
                                                                    if (money) {
                                                                        isMoney = true;
                                                                        shouldMoneyEx += money;
                                                                    }

                                                                }
                                                            }
                                                            if (isMoney === false) {
                                                                return Promise.reject(new Error("无支付信息"));
                                                            }
                                                            if (shouldMoneyEx !== shouldMoney) return Promise.reject(new Error("支付金额应等于应收金额"));
                                                        },
                                                    },
                                                ]}
                                            >
                                                {(fields, { add, remove }) => {
                                                    return (
                                                        <>
                                                            {fields.map(({ key, name, ...restField }) => (
                                                                <Row style={{ width: "100%" }} gutter={4} justify="space-between">
                                                                    <Col className="gutter-row" span={24}>
                                                                        <Form.Item
                                                                            {...restField}
                                                                            label={formListData ? formListData[name].paymentMethodName : ""}
                                                                            name={[name, 'money']}
                                                                        >
                                                                            <InputNumber min={0} onChange={(value: any) => onChangeMoney(name, value)} addonAfter={"元"}></InputNumber>

                                                                        </Form.Item>
                                                                    </Col>





                                                                </Row>
                                                            ))}
                                                            {/* <Col span={24}>
                                                            <Form.Item>
                                                                <Button type="dashed">
                                                                    自定义支付方式
                                                                </Button>
                                                            </Form.Item>
                                                        </Col> */}

                                                        </>
                                                    )
                                                }
                                                }
                                            </Form.List>



                                        </Col> : null

                                    }
                                    {
                                        payModeId === 1 ? <Col span={24}>

                                            <Col span={24}>
                                                <Form.Item
                                                    label="支付方式"

                                                    name="paymentListId"
                                                    rules={[{ message: '请选择支付方式!', required: true }]}>
                                                    <Select
                                                        showSearch
                                                        placeholder="支付方式"
                                                        optionFilterProp="children"
                                                        filterOption={(input, option: any) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                                        {getList.general(paymentListData, "paymentMethodName")}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    label={"支付金额"}
                                                    name={'money'}
                                                    rules={[
                                                        {
                                                            validator: async (_, names) => {
                                                                let dataEx = form.getFieldsValue()
                                                                const { shouldMoney } = dataEx

                                                                if (names !== shouldMoney) return Promise.reject(new Error("支付金额应等于应收金额"));
                                                            },

                                                        },
                                                    ]}
                                                >

                                                    <InputNumber min={0} addonAfter={"元"}></InputNumber>

                                                </Form.Item>
                                            </Col>

                                        </Col> : null

                                    }



                                                                      
                                    <Col style={{display:"none"}} span={24}>
                                        <Form.Item
                                            label="先后付"
                                            name="orderPaymentModeId">
                                            <Input  ></Input>
                                        </Form.Item>
                                    </Col>


                                </Row>
                            </Form>



                        </div>
                    </Col>
                </Row>

            </AntDraggableModal>
        </>
    )
}
