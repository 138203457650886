import { Button, List, Modal, Tooltip } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import systemConfig from '../../config/systematic';
import fetchApi from '../../utils/api/fetchApi';
import { SocketContext } from '../../App';
// import { SocketContext } from '../../App';
export default function BranchStoreList(props: any) {
    const { setShopTypeData, setDeptData, setSbData, onRefresh, form, setRoomTypeData, setBranchStoreFloor,setRoomData } = props
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [branchStoreData, setBranchStoreData] = useState<any>();

    const useContextData = useContext(SocketContext);

    const { contextData, setContextData } = useContextData;
    // const useContextData = useContext(SocketContext);
    // const { wsServer } = useContextData;
    //显示分店弹窗
    const [branchStoreListOpen, setBranchStoreListOpen] = useState<boolean>(false);
    useEffect(() => {
        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "branchStore",
                apiExtend: 'showListManage'
            }).then(res => {

                setBranchStoreData(res.data)
            })
          


            await onchangeCurrentBranchStore({
                id: localStorage.getItem("currentBranchStoreId") ? localStorage.getItem("currentBranchStoreId") : sessionStorage.getItem('branchStoreId'),
                branchStoreName: localStorage.getItem("currentBranchStoreName") ? localStorage.getItem("currentBranchStoreName") : sessionStorage.getItem('branchStoreName'),
                brandName: localStorage.getItem("currentBrandName") ? localStorage.getItem("currentBrandName") : sessionStorage.getItem('brandName'),
                brandId: localStorage.getItem("currentBrandId") ? localStorage.getItem("currentBrandId") : sessionStorage.getItem('brandId'),
                refresh: false
            })
        }

        fetchData()
    }, [])
    const onchangeCurrentBranchStore = async (item: any) => {
        
        setContextData({ ...contextData, currentBranchStoreName: item.branchStoreName, currentBranchStoreId: item.id, currentBrandName: item.brandName, currentBrandId: item.brandId })
        localStorage.setItem("currentBranchStoreId", item.id)
        localStorage.setItem("currentBranchStoreName", item.branchStoreName)
        localStorage.setItem("currentBrandName", item.brandName)
        localStorage.setItem("currentBrandId", item.brandId)
        if (form) form.resetFields(["sbId", "deptIds", "roomTypeIds", "shopTypeIds", "roomIds"])
        if (setSbData) {
            let sb = await fetchApi.GET(wmService, {
                apiId: "sb",
                apiExtend: 'getBrandStoreIdToSb',
                apiData: {
                    branchStoreId: item.id
                }
            })

            if (sb.success) {
                setSbData(sb.data)
            }


        }
        if (setRoomData) {
            fetchApi.GET(wmService,
                {
                    apiId: 'room',
                    apiExtend: 'showList',
                    apiData: {
                        branchStoreId: item.id,
                        extendObj: {
                            menuHeadsIdNum: -1,
                            printersIdNum: -1,
                        }
                    }
                }).then(res => {

                    if (res.success) {
                        setRoomData(res.data);
                    }
                })
        }
        if (setDeptData) {
            let dept = await fetchApi.GET(wmService, {
                apiId: "dept",
                apiExtend: 'getBrandStoreIdToDept',
                apiData: {
                    branchStoreId: item.id
                }
            })
            if (dept.success) {

                setDeptData(dept.data)
            }
        }
        if (setRoomTypeData) {

            let roomType = await fetchApi.GET(wmService, {
                apiId: "roomType",
                apiExtend: 'showList',
                apiData: {
                    branchStoreId: item.id,
                    extendObj: {
                        menuHeadsIdNum: -1,

                    }
                }
            })
            if (roomType.success) {

                setRoomTypeData(roomType.data)
            }
        }

        if (setShopTypeData) {
            let shopType = await fetchApi.GET(wmService, {
                apiId: "shopType",
                apiExtend: 'showList',
                apiData: {
                    branchStoreId: item.id,
                    mountDataOptions: {
                        userMount: {
                            isDept: true,
                        }
                    },
                }
            })

            if (shopType.success) {

                setShopTypeData(shopType.data)
            }
        }
        if(setBranchStoreFloor){
            let branchStoreFloorData = await fetchApi.GET(wmService, {
                apiId: "branchStoreFloor",
                apiExtend: 'showList',
                apiData: {
                    sendPageData:{
                        branchStoreId: item.id,
                    }
                 
                   
                }
            })

            if (branchStoreFloorData.success) {
                    
                setBranchStoreFloor(branchStoreFloorData.data)
            }
        }
        setBranchStoreListOpen(false)
        if (onRefresh && item.refresh) onRefresh({...item,branchStoreId:item.id})

    }
    return (
        <>
            <div style={{ fontSize: 20 }} onClick={() => setBranchStoreListOpen(true)} >
                <Tooltip title="点击可修改分店">
                    当前分店:{localStorage.getItem("currentBranchStoreName") ? `${localStorage.getItem("currentBrandName") ? localStorage.getItem("currentBrandName") : null}${localStorage.getItem("currentBranchStoreName")}` : "请选择分店"}
                </Tooltip>
            </div>

            {/**查看分店 */}
            {branchStoreListOpen ? <Modal
                title={<div style={{ fontSize: "30px" }}>请选择分店</div>}
                open={true}
                onCancel={() => setBranchStoreListOpen(false)}
                onOk={() => setBranchStoreListOpen(false)}
                footer={null}
                width={"60%"}
                centered
            >
                <List
                    size="large"
                    dataSource={branchStoreData}
                    renderItem={(item: any) => (
                        <List.Item key={item.id}>
                            <List.Item.Meta
                                title={<div style={{ fontSize: "35px" }}>{`${item.brandName}~${item.branchStoreName}`}</div>}
                            />
                            <Button type="primary" size="large" onClick={() => onchangeCurrentBranchStore({ ...item, refresh: true })}>
                                选择
                            </Button>
                        </List.Item>
                    )}
                />
            </Modal> : null}
        </>

    )
}
