import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';

const { TextArea } = Input;

export default function MenuWarehouseArticleEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;

    const ref = useRef<any>();

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    /**文章大分类数据 */
    const [menuWarehouseArticleCategoryData, setMenuWarehouseArticleCategoryData] = useState<any>();

    /**文章小分类数据 */
    const [menuWarehouseArticleTypeData, setMenuWarehouseArticleTypeData] = useState<any>();

    /**记录选择前的文章大分类 */
    const [defaultArticleCategoryId, setDefaultArticleCategoryId] = useState<any>();

    /**文章小分类的状态 */
    const [articleDisable, setArticleDisable] = useState<any>(true);

    /**查看文章方式 */
    const [checkArticleTypeData, setCheckArticleTypeData] = useState<any>();

    /** 选择查看文章方式数据 */
    const [selectCheckArticleType, setSelectCheckArticleType] = useState<any>();

    /** 是否推广数据 */
    const [isPromoData, setIsPromoData] = useState<any>(false);

    /**数据 */
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });

            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {

                    const menuWarehouseArticleTypeId = res.data.menuWarehouseArticleTypeId;
                    setSelectCheckArticleType(res.data.checkArticleTypeId);
                    setIsPromoData(res.data.isPromo);

                    fetchApi.GET(wmService, {
                        apiId: 'menuWarehouseArticleType',
                        apiVariable: menuWarehouseArticleTypeId
                    }).then(res => {
                        if (res.success) {
                            form.setFieldsValue({ menuWarehouseArticleCategoryId: res.data.menuWarehouseArticleCategoryId });
                        }
                    })
                }
            })
        }

        const fetchData = async () => {

            if (data.type !== 'NEW') {
                setArticleDisable(false);
            }

            fetchApi.GET(wmService, {
                apiId: "menuWarehouseArticleType",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseArticleTypeData(res.data)
                }
            });

            fetchApi.GET(wmService, {
                apiId: "menuWarehouseArticleCategory",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setMenuWarehouseArticleCategoryData(res.data)
                }
            });

            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'checkArticleType',
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {
                    setCheckArticleTypeData(res.data);
                }
            });

        }
        fetchData()
    }, [])

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    const getDefaultArticleCategoryToTypeData = (e: any) => {
        fetchApi.GET(wmService, {
            apiId: "menuWarehouseArticleCategory",
            apiExtend: 'getArticleTypeList',
            apiVariable: form.getFieldValue('menuWarehouseArticleCategoryId'),
        }).then(res => {
            if (res.success) {
                setMenuWarehouseArticleTypeData(res.data);
            }
        });
    }

    const getDefaultArticleCategoryData = (e: any) => {
        setDefaultArticleCategoryId(form.getFieldValue('menuWarehouseArticleCategoryId'));
    }

    const getArticleCategoryToTypeData = (menuWarehouseArticleCategoryId: string) => {
        setArticleDisable(false);

        if (menuWarehouseArticleCategoryId !== defaultArticleCategoryId) {
            form.setFieldsValue({ menuWarehouseArticleTypeId: "" });
        }

        fetchApi.GET(wmService, {
            apiId: "menuWarehouseArticleCategory",
            apiExtend: 'getArticleTypeList',
            apiVariable: menuWarehouseArticleCategoryId,
        }).then(res => {
            if (res.success) {
                setMenuWarehouseArticleTypeData(res.data)
            }
        });
    }

    const getCheckArticleType = (checkArticleTypeId: string) => {
        setSelectCheckArticleType(checkArticleTypeId);
    }

    const setIsPromo = (e: any) => {
        setIsPromoData(e);
        console.log(e);
    }

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuWarehouseArticle'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '文章'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    form={form}
                    initialValues={{ "sortBy": 999, "promoSortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[16, 0]}><Col span={24}>
                        <Form.Item
                            label="id"
                            name="id">
                            <Input disabled={true} />
                        </Form.Item>
                    </Col>

                        <Col span={24}>
                            <Form.Item
                                label="文章大分类"
                                name="menuWarehouseArticleCategoryId"
                                rules={[{ required: true, message: '请选择文章大分类!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="文章大分类"
                                    optionFilterProp="children"
                                    onSelect={getArticleCategoryToTypeData}
                                    onFocus={getDefaultArticleCategoryData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(menuWarehouseArticleCategoryData, {
                                        itemName: "menuWarehouseArticleCategoryName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="文章小分类"
                                name="menuWarehouseArticleTypeId"
                                rules={[{ required: true, message: '请选择文章小分类!' }]}
                            >
                                <Select
                                    disabled={articleDisable}
                                    showSearch
                                    placeholder="文章小分类"
                                    optionFilterProp="children"
                                    onMouseEnter={getDefaultArticleCategoryToTypeData}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(menuWarehouseArticleTypeData, {
                                        itemName: "menuWarehouseArticleTypeName",
                                        includeNull: false
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="查看文章方式"
                                name="checkArticleTypeId"
                                rules={[{ required: true, message: '请选择查看文章方式!' }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="查看文章方式"
                                    optionFilterProp="children"
                                    onSelect={getCheckArticleType}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(checkArticleTypeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={selectCheckArticleType === 'link' ? 24 : 0}>
                            <Form.Item
                                label="文章链接"
                                name="articleLink"
                                rules={[{ required: selectCheckArticleType === 'link' ? true : false, message: '请输入文章链接!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={selectCheckArticleType === 'tencentMovie' ? 24 : 0}>
                            <Form.Item
                                label="腾讯视频ID"
                                name="movieId"
                                rules={[{ required: selectCheckArticleType === 'tencentMovie' ? true : false, message: '请输入腾讯视频ID!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="文章标题"
                                name="articleTitle"
                                rules={[{ required: true, message: '请输入文章标题!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="文章摘要"
                                name="abstract"
                                rules={[{ required: true, message: '请输入文章摘要!' }]}
                            >
                                <TextArea
                                    placeholder="文章摘要"
                                    autoSize={{ minRows: 5, maxRows: 7 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="是否推广"
                                name="isPromo"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    onChange={setIsPromo}
                                    checkedChildren="是"
                                    unCheckedChildren="否 " />
                            </Form.Item>
                        </Col>

                        <Col span={isPromoData ? 24 : 0}>
                            <Form.Item
                                label="推广排序"
                                name="promoSortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}