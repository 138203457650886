import { Col, Form, Input, InputNumber, Row, Select, Space, Switch, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import fetchApi from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';
import systemConfig from '../../../config/systematic';
import { PlusOutlined } from '@ant-design/icons';
import objectHandle from "../../../utils/baseLib/objectHandle";

export default function MenuFoodEdit(props: any) {


    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    const [discountData, setDiscountData] = useState<boolean>(true);
    const [promoCategory, setPromoCategory] = useState<boolean>(true);

    const [commonMemoTags, setCommonMemoTags] = useState(['']);
    const [categoryMemoTags, setCategoryMemoTags] = useState(['']);
    const [menuMemoTags, setMenuMemoTags] = useState(['']);
    const [internalMemoTags, setInternalMemoTags] = useState(['']);

    const [inputMemoVisible, setInputMemoVisible] = useState<any>(false);
    const [inputMemoValue, setInputMemoValue] = useState<any>('');
    const [editInputMemoIndex, setEditInputMemoIndex] = useState<any>(-1);
    const [editInputMemoValue, setEditInputMemoValue] = useState<any>('');

    // const [commonCondimentTags, setCommonCondimentTags] = useState(['']);
    // const [categoryCondimentTags, setCategoryCondimentTags] = useState(['']);
    // const [menuCondimentTags, setMenuCondimentTags] = useState(['']);
    // const [internalCondimentTags, setInternalCondimentTags] = useState(['']);

    // const [inputCondimentVisible, setInputCondimentVisible] = useState<any>(false);
    // const [inputCondimentValue, setInputCondimentValue] = useState<any>('');
    // const [editInputCondimentIndex, setEditInputCondimentIndex] = useState<any>(-1);
    // const [editInputCondimentValue, setEditInputCondimentValue] = useState<any>('');

    const [shopTypeData, setShopTypeData] = useState<any>();
    const [menuLabelData, setMenuLabelData] = useState<any>();
    const [promoCategoryData, setPromoCategoryData] = useState<any>();

    /**视频数据 */
    const [menuMovieData, setMenuMovieData] = useState<any>();
    const [initialMoviesId, setInitialMoviesId] = useState<any>([]);

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };

    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });

            ref.current.row.then(async (res: any) => {

                if (typeof res === 'undefined') { return }
                if (res.success === true) {
                    if (data.type === "EDIT") {

                        if (res.data.dept) {
                            form.setFieldsValue({ deptId: res.data.dept.id });
                        }

                        if (res.data.isDiscount) {
                            setDiscountData(!res.data.isDiscount);
                        }

                        if (res.data.isPromo) {
                            setPromoCategory(!res.data.isPromo);
                        }

                        form.setFieldsValue({ branchStoreMenuWarehouseId: res.data.branchStoreMenuWarehouseId });

                        fetchApi.GET(wmService, {
                            apiId: "menuMemo",
                            apiExtend: 'showList',
                            apiVariable: localStorage.getItem("currentBranchStoreId")
                        }).then(rs => {
                            if (rs.success) {
                                let commonMemoTagsData: any = [];
                                let categoryMemoTagsData: any = [];
                                let menuMemoTagsData: any = [];
                                let internalMemoTagsData: any = [];

                                for (var i = 0, len = rs.data.length; i < len; i++) {
                                    if (rs.data[i].memoCondimentId === 0) {
                                        commonMemoTagsData.push(rs.data[i].menuMemoName)
                                    }
                                    if (rs.data[i].memoCondimentId === 1 && res.data.shopTypeId === rs.data[i].shopTypeId) {
                                        categoryMemoTagsData.push(rs.data[i].menuMemoName)
                                    }
                                    if (rs.data[i].memoCondimentId === 2 && res.data.branchStoreMenuWarehouseId === rs.data[i].branchStoreMenuWarehouseId) {
                                        menuMemoTagsData.push(rs.data[i].menuMemoName)
                                    }
                                    if (rs.data[i].memoCondimentId === 3) {
                                        internalMemoTagsData.push(rs.data[i].menuMemoName)
                                    }
                                }

                                setCommonMemoTags(commonMemoTagsData);
                                setCategoryMemoTags(categoryMemoTagsData);
                                setMenuMemoTags(menuMemoTagsData);
                                setInternalMemoTags(internalMemoTagsData);
                                form.setFieldsValue({ menuMemo: menuMemoTagsData });
                            }
                        })

                        fetchApi.GET(wmService, {
                            apiId: 'menuMovie',
                            apiExtend: 'showList',
                            apiData: {
                                menuWarehouseId: res.data.branchStoreMenuWarehouse.menuWarehouseId
                            }
                        }).then(res => {
                            if (res.success) {
                                let newDatas: Array<any> = [];
                                for (var j = 0; j < res.data.length; j++) {
                                    newDatas.push({
                                        value: res.data[j].id,
                                        label: res.data[j].movieId + "（" + res.data[j].memo + "）"
                                    })
                                }
                                setMenuMovieData(newDatas);
                            }
                        });
                        
                        const moviesId = res.data.moviesId;
                        let movieRows;
                        if (objectHandle.isEmpty(moviesId) === false) {
                            movieRows = moviesId.split('@');
                        }
                        setInitialMoviesId(movieRows);
                        form.setFieldsValue({
                            moviesId: movieRows
                        })

                        // fetchApi.GET(wmService, {
                        //     apiId: "menuCondiment",
                        //     apiExtend: 'showList'
                        // }).then(rs => {
                        //     if (rs.success) {
                        //         let commonCondimentTagsData: any = [];
                        //         let categoryCondimentTagsData: any = [];
                        //         let menuCondimentTagsData: any = [];
                        //         let internalCondimentTagsData: any = [];

                        //         for (var j = 0, j_len = rs.data.length; j < j_len; j++) {
                        //             let condimentStr = rs.data[j].menuCondimentName + "@" + rs.data[j].price
                        //             if (rs.data[j].memoCondimentId === 0) {
                        //                 commonCondimentTagsData.push(condimentStr)
                        //             }
                        //             if (rs.data[j].memoCondimentId === 1 && res.data.shopTypeId === rs.data[j].shopTypeId) {
                        //                 categoryCondimentTagsData.push(condimentStr)
                        //             }
                        //             if (rs.data[j].memoCondimentId === 2 && res.data.menuWarehouseId === rs.data[j].menuWarehouseId) {
                        //                 menuCondimentTagsData.push(condimentStr)
                        //             }
                        //             if (rs.data[j].memoCondimentId === 3) {
                        //                 internalCondimentTagsData.push(condimentStr)
                        //             }
                        //         }

                        //         setCommonCondimentTags(commonCondimentTagsData);
                        //         setCategoryCondimentTags(categoryCondimentTagsData);
                        //         setMenuCondimentTags(menuCondimentTagsData);
                        //         setInternalCondimentTags(internalCondimentTagsData);
                        //         form.setFieldsValue({ menuCondiment: menuCondimentTagsData });
                        //     }
                        // })
                    }
                }
            })
        }

        const fetchData = async () => {

            fetchApi.GET(wmService, {
                apiId: "menuShopType",
                apiExtend: 'showShopTypeList',
                menuHeadId: data.sendPageData.menuHeadId,
                branchStoreId: localStorage.getItem("currentBranchStoreId")
            }).then(res => {
                if (res.success) {
                    setShopTypeData(res.data);
                }
            })

            fetchApi.GET(wmService, {
                apiId: "promoCategory",
                apiExtend: 'showList',
                apiVariable: localStorage.getItem("currentBranchStoreId")
            }).then(res => {
                if (res.success) {
                    setPromoCategoryData(res.data);
                }
            })

            fetchApi.GET(wmService, {
                apiId: "menuLabel",
                apiExtend: 'showList',
                apiVariable: localStorage.getItem("currentBranchStoreId")
            }).then(res => {
                if (res.success) {
                    setMenuLabelData(res.data);
                }
            })
        }
        fetchData()
    }, [])

    /**
     * 更改Select
     */
    const changeSelect = async (key: any, rows: any, e: any) => {
        switch (key) {
            case "menuLabel":
                for (var i = 0, len = rows.length; i < len; i++) {
                    if (rows[i].id === e) {
                        form.setFieldsValue({ menuLabelName: rows[i].menuLabelName });
                    }
                    if (objectHandle.isEmpty(e)) {
                        form.setFieldsValue({ menuLabelName: '' });
                    }
                }
                break;

            case "promoCategory":
                for (var i = 0, len = rows.length; i < len; i++) {
                    if (rows[i].id === e) {
                        form.setFieldsValue({ promoCategoryName: rows[i].promoCategoryName });
                    }
                }
                break;

            case "shopType":
                for (var i = 0, len = rows.length; i < len; i++) {
                    if (rows[i].id === e) {
                        form.setFieldsValue({ shopType_shortCode: rows[i].shopType_shortCode });
                        form.setFieldsValue({ shopTypeName: rows[i].shopTypeName });
                    }
                }
                break;
        }

    };


    // /**
    //  * 更改分类类型
    //  */
    // const changeShopType = async (rows: any, e: any) => {
    //     for (var i = 0, len = rows.length; i < len; i++) {
    //         if (rows[i].id === e) {
    //             form.setFieldsValue({ shopType_shortCode: rows[i].shopType_shortCode });
    //             form.setFieldsValue({ shopTypeName: rows[i].shopTypeName });
    //         }
    //     }
    // };

    /** 控制Switch */
    const handleSwitch = (e: any, setData: any) => {
        setData(!e);
    };

    // /** 是否可以打折 */
    // const handleDiscount = (e: any) => {
    //     setDiscountData(!e);
    // };

    // /** 是否推广 */
    // const handlePromo = (e: any) => {
    //     setPromoCategory(!e);
    // };

    const handleCloseMemoTag = (removedTag: string) => {
        const newTags = menuMemoTags.filter((tag) => tag !== removedTag);
        setMenuMemoTags(newTags);
        form.setFieldsValue({ menuMemo: newTags });
    };

    const showInputMemo = () => {
        setInputMemoVisible(true);
    };

    const handleInputMemoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputMemoValue(e.target.value);
    };

    const handleInputMemoConfirm = () => {
        if (inputMemoValue && menuMemoTags.indexOf(inputMemoValue) === -1 && commonMemoTags.indexOf(inputMemoValue) === -1 && categoryMemoTags.indexOf(inputMemoValue) === -1) {
            setMenuMemoTags([...menuMemoTags, inputMemoValue]);
            form.setFieldsValue({ menuMemo: [...menuMemoTags, inputMemoValue] });
        }
        setInputMemoVisible(false);
        setInputMemoValue('');
    };

    const handleEditInputMemoChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEditInputMemoValue(e.target.value);
    };

    const handleEditInputMemoConfirm = () => {
        const newTags = [...menuMemoTags];
        newTags[editInputMemoIndex] = editInputMemoValue;
        setMenuMemoTags(newTags);
        form.setFieldsValue({ menuMemo: newTags });
        setEditInputMemoIndex(-1);
        setInputMemoValue('');
    };

    // const handleCloseCondimentTag = (removedTag: string) => {
    //     const newTags = menuCondimentTags.filter((tag) => tag !== removedTag);
    //     setMenuCondimentTags(newTags);
    //     form.setFieldsValue({ menuCondiment: newTags });
    // };

    // const showInputCondiment = () => {
    //     setInputCondimentVisible(true);
    // };

    // const handleInputCondimentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setInputCondimentValue(e.target.value);
    // };

    // const handleInputCondimentConfirm = () => {
    //     if (inputCondimentValue.indexOf("@") > -1 && inputCondimentValue.length - 1 > inputCondimentValue.indexOf("@") && Number(inputCondimentValue.split('@')[1]) >= 0 && inputCondimentValue.split('@').length === 2) {
    //         if (inputCondimentValue && checkSame(menuCondimentTags, inputCondimentValue) && checkSame(commonCondimentTags, inputCondimentValue) && checkSame(categoryCondimentTags, inputCondimentValue)) {
    //             setMenuCondimentTags([...menuCondimentTags, inputCondimentValue]);
    //             form.setFieldsValue({ menuCondiment: [...menuCondimentTags, inputCondimentValue] });
    //         }
    //         setInputCondimentVisible(false);
    //         setInputCondimentValue('');
    //     }
    // };

    // const checkSame = (rows: any, value: any) => {
    //     for (var i = 0, len = rows.length; i < len; i++) {
    //         if (rows[i].split('@')[0] === value.split('@')[0]) {
    //             return false;
    //         }
    //     }
    //     return true;
    // };

    // const handleEditInputCondimentChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    //     setEditInputCondimentValue(e.target.value);
    // };

    // const handleEditInputCondimentConfirm = () => {
    //     const newTags = [...menuCondimentTags];
    //     newTags[editInputCondimentIndex] = editInputCondimentValue;
    //     setMenuCondimentTags(newTags);
    //     form.setFieldsValue({ menuCondiment: newTags });
    //     setEditInputCondimentIndex(-1);
    //     setInputCondimentValue('');
    // };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menu'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '菜式'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={700}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="branchStoreMenuWarehouseId"
                                name="branchStoreMenuWarehouseId">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="门店Id"
                                name="branchStoreId"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="菜式名称"
                                name="foodName"
                                rules={[{ required: true, message: '请输入菜式名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="菜式英文"
                                name="foodNameEn"
                            >
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="所属分类"
                                name="shopTypeId"
                                rules={[{ required: true, message: '请选择所属分类!' }]}>
                                <Select
                                    showSearch
                                    placeholder="所属分类"
                                    optionFilterProp="children"
                                    onChange={(e) => changeSelect("shopType", shopTypeData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(shopTypeData, {
                                        itemName: "shopTypeName",
                                        includeNull: false,
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="所属分类"
                                name="shopTypeName"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="起卖数"
                                name="minAmount"
                            >
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="是否推广"
                                name="isPromo"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch
                                    checkedChildren="推广"
                                    unCheckedChildren="不推广"
                                    onChange={(e) => handleSwitch(e, setPromoCategory)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="推广分类"
                                name="promoCategoryId"
                                rules={[{ required: !promoCategory, message: '请选择推广分类!' }]}>
                                <Select
                                    disabled={promoCategory}
                                    showSearch
                                    placeholder="推广分类"
                                    optionFilterProp="children"
                                    onChange={(e) => changeSelect("promoCategory", promoCategoryData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(promoCategoryData, {
                                        itemName: "promoCategoryName",
                                        includeNull: false,
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="推广分类名称"
                                name="promoCategoryName"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="打折"
                                name="isDiscount"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch
                                    checkedChildren="打折"
                                    unCheckedChildren="不打折"
                                    onChange={(e) => handleSwitch(e, setDiscountData)}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="最高折扣"
                                name="maxDiscount"
                                initialValue={1}
                                rules={[{ required: true, message: '请输入最高折扣!' }]}
                            >
                                <InputNumber min={0} max={1} disabled={discountData} step={0.05} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="外卖"
                                name="isWm"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="所需饭盒数"
                                name="boxNum"
                            >
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="视频"
                                name="moviesId"
                                initialValue={initialMoviesId} >
                                <Select
                                    mode="multiple"
                                    showSearch
                                    placeholder="选择视频"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    options={menuMovieData}
                                >
                                    {/* {getList.general(menuMovieData, 'movieId', 'id', undefined, false)} */}
                                </Select>
                            </Form.Item>
                        </Col>

                        {/* <Col span={12}>
                            <Form.Item
                                label="小程序显示"
                                name="isWx"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="显示"
                                    unCheckedChildren="不显示" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="服务人员专用"
                                name="isStaff"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch
                                    checkedChildren="是"
                                    unCheckedChildren="否"
                                />
                            </Form.Item>
                        </Col> */}

                        <Col span={12}>
                            <Form.Item
                                label="标签"
                                name="menuLabelId">
                                <Select
                                    showSearch
                                    placeholder="选择标签"
                                    optionFilterProp="children"
                                    onChange={(e) => changeSelect("menuLabel", menuLabelData, e)}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(menuLabelData, {
                                        itemName: "menuLabelName",
                                        includeNull: true,
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="标签"
                                name="menuLabelName"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        {/* //该项原来是客服专用，后来发现和小程序显示有点冲突，故目前两个合二为一，先暂停小程序显示，以客服专用暂时叫小程序显示 */}
                        <Col span={12}>
                            <Form.Item
                                label="小程序显示"
                                name="isStaff"
                                tooltip="当菜式需要下架时，只需要选择“不显示”，客人便看不到了。"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch
                                    checkedChildren="不显示"
                                    unCheckedChildren="显示"
                                />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="短码"
                                name="shopType_shortCode"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="打印小标签"
                                name="isPrintLabel"
                                tooltip="这功能是给需要通过标签打印机打印标签贴到冷饮杯的门店使用"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="打印规格"
                                name="isPrintSpec"
                                tooltip="当遇到一些规格不是单位的菜式，这里选择“打印”即可把规格打印出来。例如：该菜式的规格是“虾酱啫”和“老干妈啫”，如果这里不选择打印，厨房就不知道该菜式是什么做法了。"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="打印"
                                    unCheckedChildren="不打印" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="显示加料"
                                name="isShowCondiment"
                                tooltip="当加料内容希望显示给客人看时，这里选择“显示”即可。"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="显示"
                                    unCheckedChildren="不显示" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="加料必选"
                                name="isRequiredCondiment"
                                tooltip="当加料希望必选时，这里选择“是”即可。"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="特殊显示"
                                name="isSpecialShow"
                                tooltip="这功能是给扫码看菜单的门店使用，如菜式需要显示“份计”、“斤计”、“只计”等等的，只需选择“是”即可。"
                                valuePropName="checked"
                                initialValue={false}>
                                <Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="上架"
                                    unCheckedChildren="下架" />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="排序" name="sortBy"
                            >
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="showMemo">
                                <Space size={[0, 8]} wrap>
                                    <Space size={[0, 8]} wrap>
                                        {commonMemoTags.map((tag, index) => {
                                            const commonMemoTagElem = (
                                                <Tag
                                                    key={tag}
                                                    color="green"
                                                    style={{ fontSize: 15, height: 22 }}
                                                >
                                                    {tag}
                                                </Tag>
                                            );
                                            return (
                                                commonMemoTagElem
                                            );
                                        })}
                                    </Space>

                                    <Space size={[0, 8]} wrap>
                                        {internalMemoTags.map((tag, index) => {
                                            const internalMemoTagElem = (
                                                <Tag
                                                    key={tag}
                                                    color="orange"
                                                    style={{ fontSize: 15, height: 22 }}
                                                >
                                                    {tag}
                                                </Tag>
                                            );
                                            return (
                                                internalMemoTagElem
                                            );
                                        })}
                                    </Space>

                                    <Space size={[0, 8]} wrap>
                                        {categoryMemoTags.map((tag, index) => {
                                            const categoryMemoTagElem = (
                                                <Tag
                                                    key={tag}
                                                    color="blue"
                                                    style={{ fontSize: 15, height: 22 }}
                                                >
                                                    {tag}
                                                </Tag>
                                            );
                                            return (
                                                categoryMemoTagElem
                                            );
                                        })}
                                    </Space>

                                    <Space size={[0, 8]} wrap>
                                        {menuMemoTags.map((tag, index) => {
                                            if (editInputMemoIndex === index) {
                                                return (
                                                    <Input
                                                        key={tag}
                                                        size="small"
                                                        value={editInputMemoValue}
                                                        onChange={handleEditInputMemoChange}
                                                        onBlur={handleEditInputMemoConfirm}
                                                        onPressEnter={handleEditInputMemoConfirm}
                                                    />
                                                );
                                            }
                                            const menuMemoTagElem = (
                                                <Tag
                                                    key={tag}
                                                    color="red"
                                                    style={{ fontSize: 15, height: 22 }}
                                                    closable={index >= 0}
                                                    onClose={() => handleCloseMemoTag(tag)}
                                                >
                                                    {tag}
                                                </Tag>
                                            );
                                            return (
                                                menuMemoTagElem
                                            );
                                        })}
                                    </Space>
                                    {inputMemoVisible ? (
                                        <Input
                                            type="text"
                                            size="small"
                                            value={inputMemoValue}
                                            onChange={handleInputMemoChange}
                                            onBlur={handleInputMemoConfirm}
                                            onPressEnter={handleInputMemoConfirm}
                                        />
                                    ) : (
                                        <Tag onClick={showInputMemo}>
                                            <PlusOutlined /> 添加
                                        </Tag>
                                    )}
                                </Space>
                            </Form.Item>
                        </Col>

                        {/* <Col span={24}>
                            <Form.Item
                                label="加料"
                                name="showCondiment">
                                <Space size={[0, 8]} wrap>
                                    <Space size={[0, 8]} wrap>
                                        {commonCondimentTags.map((tag, index) => {
                                            const commonCondimentTagElem = (
                                                <Tag
                                                    key={tag}
                                                    color="green"
                                                    style={{ fontSize: 15, height: 22 }}
                                                >
                                                    {tag}
                                                </Tag>
                                            );
                                            return (
                                                commonCondimentTagElem
                                            );
                                        })}
                                    </Space>

                                    <Space size={[0, 8]} wrap>
                                        {internalCondimentTags.map((tag, index) => {
                                            const internalCondimentTagElem = (
                                                <Tag
                                                    key={tag}
                                                    color="orange"
                                                    style={{ fontSize: 15, height: 22 }}
                                                >
                                                    {tag}
                                                </Tag>
                                            );
                                            return (
                                                internalCondimentTagElem
                                            );
                                        })}
                                    </Space>

                                    <Space size={[0, 8]} wrap>
                                        {categoryCondimentTags.map((tag, index) => {
                                            const categoryCondimentTagElem = (
                                                <Tag
                                                    key={tag}
                                                    color="blue"
                                                    style={{ fontSize: 15, height: 22 }}
                                                >
                                                    {tag}
                                                </Tag>
                                            );
                                            return (
                                                categoryCondimentTagElem
                                            );
                                        })}
                                    </Space>

                                    <Space size={[0, 8]} wrap>
                                        {menuCondimentTags.map((tag, index) => {
                                            if (editInputCondimentIndex === index) {
                                                return (
                                                    <Input
                                                        key={tag}
                                                        size="small"
                                                        value={editInputCondimentValue}
                                                        onChange={handleEditInputCondimentChange}
                                                        onBlur={handleEditInputCondimentConfirm}
                                                        onPressEnter={handleEditInputCondimentConfirm}
                                                    />
                                                );
                                            }
                                            const menuCondimentTagElem = (
                                                <Tag
                                                    key={tag}
                                                    color="red"
                                                    style={{ fontSize: 15, height: 22 }}
                                                    closable={index >= 0}
                                                    onClose={() => handleCloseCondimentTag(tag)}
                                                >
                                                    {tag}
                                                </Tag>
                                            );
                                            return (
                                                menuCondimentTagElem
                                            );
                                        })}
                                    </Space>
                                    {inputCondimentVisible ? (
                                        <Input
                                            type="text"
                                            size="small"
                                            placeholder="格式:鱿鱼@8"
                                            value={inputCondimentValue}
                                            onChange={handleInputCondimentChange}
                                            onBlur={handleInputCondimentConfirm}
                                            onPressEnter={handleInputCondimentConfirm}
                                        />
                                    ) : (
                                        <Tag onClick={showInputCondiment}>
                                            <PlusOutlined /> 添加
                                        </Tag>
                                    )}
                                </Space>
                            </Form.Item>
                        </Col> */}

                        {/* <Col span={0}>
                            <Form.Item
                                label="加料"
                                name="menuCondiment">
                                <Input />
                            </Form.Item>
                        </Col> */}

                        <Col span={0}>
                            <Form.Item
                                label="备注"
                                name="menuMemo">
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}