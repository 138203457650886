import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import fetchApi, { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';
import objectHandle from '../../../utils/baseLib/objectHandle';


const RoomTypeEdit = (props: any) => {
  const [form] = Form.useForm(); //form 
  const { data } = props;

  const ref = useRef<any>();
  const [branchStoreData, setbranchStoreData] = useState<any>();
  const [initialMenuHeadsId, setInitialMenuHeadsId] = useState<any>([]);
  const [menuHeadData, setMenuHeadData] = useState<any>();
  const [orderingMode, setOrderingMode] = useState<any>()

  /** 后端数据wmService */
  let wmService: any = "";
  const handleOk = (e: any) => {
    ref.current.handleOk();
  };
  const handleCancel = (e: any) => {
    ref.current.handleCancel();
  };
  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wmService = ref.current.wmService;
      ref.current.row.then(async (res: any) => {
        if (typeof res === 'undefined') return
        if (res.success === true) {
          const menuHeadsId = res.data.menuHeadsId;
          let menuHeadRows;
          if (objectHandle.isEmpty(menuHeadsId) === false) {
            menuHeadRows = menuHeadsId.split('@');
          }
          setInitialMenuHeadsId(menuHeadRows);
          form.setFieldsValue({
            menuHeadsId: menuHeadRows
          })
        }
      })
    }
    const fetchData = async () => {




      httpGet(wmService, {
        apiId: "branchStore",
        apiExtend: 'showList'
      }).then(res => {
        if (res.success) {

          setbranchStoreData(res.data)
        }
      })

      fetchApi.GET(wmService,
        {
          apiId: 'menuHead',
          apiExtend: 'showList',
          apiData: {
            mountDataOptions: {
              userMount: {
                isDept: true,
              }
            },
          }
        }).then(res => {
          if (res.success) {
            setMenuHeadData(res.data);
          }
        });


      httpGet(wmService, {
        apiId: "generalData",
        apiExtend: 'showList',
        apiVariable: "orderingMode",
      }).then(res => {
        if (res.success) {
          setOrderingMode(res.data)
        }
      })
    };
    fetchData();
  }, []);
  let extendProps: WEUExtendPropsType = {
    ...props,
    form,
    apiId: 'roomType',
  };

  return (
    <><WebEditUniversal ref={ref} props={extendProps} />
      <AntDraggableModal
        title={(data.type === 'NEW' ? '新增' : '编辑') + '品牌'}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
        width={900}
      >
        <Form
          form={form}
          name="Edit"
          onFinish={handleOk}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}>
          <Row >
            <Col span={8}>
              <Form.Item
                label="id"
                name="id">
                <Input disabled={true} />
              </Form.Item>

            </Col>

            <Col span={8}>
              <Form.Item
                label="分店"
                name="branchStoreId"
                rules={[{ message: '请选择分店!', required: true }]}>
                <Select
                  showSearch
                  placeholder="选择分店"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(branchStoreData, 'branchStoreName')}
                </Select>
              </Form.Item>

            </Col>

            <Col span={8}>

              <Form.Item
                label="房间类型"
                name="roomType"
                rules={[{ required: true, message: '请输入房间类型!' }]}>
                <Input />
              </Form.Item>
            </Col>
            <Col span={6}>
              <Form.Item
                label="别名"
                name="anotherName"
              >
                <Input />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                label="菜谱"
                name="menuHeadsId"
                initialValue={initialMenuHeadsId} >
                <Select
                  mode="multiple"
                  showSearch
                  placeholder="选择菜谱"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(menuHeadData, 'menuHeadName', 'id', undefined, false)}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>

              <Form.Item
                label="预定金"
                name="deposit"

              >
                <InputNumber
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="最低容纳人数"
                name="containMinNum"
                rules={[{ required: true, message: '请输入最低容纳人数!' }]}
              >
                <InputNumber
                />
              </Form.Item>

            </Col>

            <Col span={8}>

              <Form.Item
                label="最高容纳人数"
                name="containMaxNum"
                rules={[{ required: true, message: '请输入最高容纳人数!' }]}
              >
                <InputNumber
                />
              </Form.Item>

            </Col>

            <Col span={8}>

              <Form.Item
                label="最低消费"
                name="minConsumption">
                <InputNumber
                />
              </Form.Item>

            </Col>

            <Col span={6}>
              <Form.Item
                label="点餐模式"
                name="orderingModeId"
                rules={[{ message: '请选择点餐模式!', required: false }]}>
                <Select
                  showSearch
                  placeholder="请选择点餐模式"
                  optionFilterProp="children"
                  filterOption={(input, option: any) =>
                    option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {getList.general(orderingMode, 'name')}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>


              <Form.Item
                label="状态"
                name="isUse"
                valuePropName="checked"
                initialValue={true}>
                < Switch checked={true}
                  checkedChildren="启用"
                  unCheckedChildren="关闭" />
              </Form.Item>

            </Col>


            <Col span={8}>
              <Form.Item
                label="是否预定"
                name="isReserve"
                valuePropName="checked"
                initialValue={true}>
                < Switch checked={true}
                  checkedChildren="启用"
                  unCheckedChildren="关闭" />
              </Form.Item>


            </Col>

            <Col span={6}>
              <Form.Item
                label="排序"
                name="roomSort">
                <Input type={"number"} />
              </Form.Item>
            </Col>

          </Row>














        </Form>
      </AntDraggableModal>
    </>
  )
}

export default RoomTypeEdit;