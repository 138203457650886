import { Button, Space } from 'antd';
import { useEffect, useRef } from 'react'
import system from '../../../config/systematic';
import fetchApi from '../../../utils/api/fetchApi';
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';

export default function Queuing(props: any) {

    const ref = useRef<any>();

    /** api服务id */
    const apiId = 'queuing';

    /** 后端数据wmService */
    let wmService: any = system.wmService;

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

        }
        fetchData()
    }, [])


    //  功能操作
    const handleAction = (record: any, key: any) => {

        fetchApi.PUT(wmService, {
            apiId: "queuing",
            apiExtend: 'onReset',
            apiData: {
                branchStoreId: localStorage.getItem("currentBranchStoreId"),
                queuingId: record !== undefined ? record.id : undefined,
                key: key
            }
        }).then(res => {
            if (res.success) {
                const currentData: initDataType = {
                    apiId,
                }
                ref.current.initData(currentData);
            }
        })
    }

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.queuingType.branchStore.brand.brandName + " " + record.queuingType.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '类型',
            dataIndex: 'queuingTypeName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.queuingType.queuingTypeName}</div>
                )
            }
        },
        {
            title: '日期',
            dataIndex: 'selectDay',
        },
        {
            title: '市别',
            dataIndex: 'sb',
            render: (text: any, record: any) => {
                return (
                    <div>{record.sb.sbName}</div>
                )
            }
        },
        {
            title: '当前叫号',
            dataIndex: 'currentSortNum',
        },
        {
            title: '最后排序',
            dataIndex: 'lastSortNum',
        },
        {
            title: '重置',
            dataIndex: 'action',
            key: 'action',
            render: (text: any, record: any) => {
                return (
                    <Space size="small">
                        <Button size={"small"} type="primary" onClick={() => handleAction(record, 'single')}>重置序号</Button>
                    </Space>
                )
            }
        },
    ];

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: ''
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '排队序号',
        apiId,
        columns,
        // showImage: true,
        // showUploadFile: true,
        showStates: false,
        showEdit: false,
        showQuery: false,
        showOperation: true,
        pagination: true,
        isAutomaticRefresh: true,
        showDelete: true,
        showAdd: false,
        // sendPageData: { queuingTypeId: queuingTypeIdData }
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
    };

    return (
        <>
            <Button type="primary" onClick={() => handleAction(undefined, 'all')}>一键重置(全部)</Button>
            <span>&nbsp;&nbsp;</span>
            <br />
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
        </>
    )
}