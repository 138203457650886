import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import SceneEdit from './SceneEdit';

export default function Scene(props: any) {

    //表头
    const columnsHead = [
        {
            title: '用餐性质',
            dataIndex: 'sceneName',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];
    
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '用餐性质',
        apiId: 'scene',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: SceneEdit,
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}