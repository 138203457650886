import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import MenuWarehouseCategoryEdit from './MenuWarehouseCategoryEdit';

export default function MenuWarehouseCategory(props: any) {

    //表头
    const columnsHead = [
        {
            title: '大类名称',
            dataIndex: 'menuWarehouseCategoryName',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式仓库大类',
        apiId: 'menuWarehouseCategory',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: MenuWarehouseCategoryEdit,
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}