import systemConfig from '../../../config/systematic';
import { Button, Col, DatePicker, Form, Input, Row, Select, Space, Table, Tooltip } from 'antd';
import React, { useContext, useEffect, useState } from 'react'
import getList from '../../universal/getList';
import fetchApi from '../../../utils/api/fetchApi';
import dayjs from 'dayjs';
import antMessage from '../../../utils/extend/AntdNotification'
import DownloadExcel from '../../report/DownloadExcel';
import BranchStoreList from '../../generalComponents/BranchStoreList';
import { QuestionCircleFilled } from '@ant-design/icons';
import { SocketContext } from '../../../App';
const { RangePicker } = DatePicker;

/**房台统计 */
export default function RoomPlatformStatistics(props: any) {
    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;
    const [form] = Form.useForm();
    const [sbData, setSbData] = useState<any>();
    const [sbName, setSbName] = useState<string>("所有");
    const [roomTypeName, setRoomTypeName] = useState<string>("所有");
    const [rows, setRows] = useState<any>([]);
    const [roomTypeData, setRoomTypeData] = useState<any>();
    const [roomData, setRoomData] = useState<any>();
    const [branchStoreFloor, setBranchStoreFloor] = useState<any>();

    const [branchStoreFloorName, setBranchStoreFloorName] = useState<any>("所有")

    const [userName, setUserName] = useState<string>("")
    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;
    useEffect(() => {
        const fetchData = async () => {
            form.setFieldsValue({
                "chooseTime": [dayjs(), dayjs()]
            })

            // if (branchStoreId) {
            //     let sb = await fetchApi.GET(wmService, {
            //         apiId: "sb",
            //         apiExtend: 'getBrandStoreIdToSb',
            //         apiData: {
            //             branchStoreId
            //         }
            //     })
            //     if (sb.success) {

            //         setSbData(sb.data)
            //     }
            // }

            // let roomType = await fetchApi.GET(wmService, {
            //     apiId: "roomType",
            //     apiExtend: 'showList',
            //     apiData: {
            //         branchStoreId,
            //         extendObj:{
            //             menuHeadsIdNum:-1,

            //         }
            //     }
            // })
            // if (roomType.success) {

            //     setRoomTypeData(roomType.data)
            // }

            // let room = await fetchApi.GET(wmService, {
            //     apiId: "room",
            //     apiExtend: 'showList',
            //     apiData: {
            //         branchStoreId,
            //         extendObj: {
            //             menuHeadsIdNum: -1,
            //             printersIdNum: -1,
            //         }
            //     }
            // })
            // if (room.success) {

            //     setRoomData(room.data)
            // }



            // 
        }
        fetchData()
    }, [])
    /** 表头内容 */
    let columnsHead: any = [
        // {
        //   title: "采购商",
        //   dataIndex: "buyerName",
        // },
        {
            title: "房名",
            dataIndex: "roomName",
            width: 110
        },
        {
            title: "单数",
            dataIndex: "counts",
        },
        {
            title: <div>金额(实收)<Tooltip title="实收金额，包括退货"><QuestionCircleFilled /></Tooltip> </div>,
            dataIndex: "totalRealMoney",

        },
        {
            title: <div>金额(退款)<Tooltip title="退款金额(特指线上退款)"><QuestionCircleFilled /></Tooltip> </div>,
            dataIndex: "refundAmount",

        },
        {
            title: <div>金额(实入)<Tooltip title="实收金额-退款金额"><QuestionCircleFilled /></Tooltip> </div>,
            dataIndex: "realIncome",

        },
        {
            title: "人数",
            dataIndex: "totalHeadCount",
        },
        {
            title: "人均",
            dataIndex: "perCapita",
        },
        {
            title: "金额(赠送)",
            dataIndex: "totalGiftPrice",
        },
        {
            title: "金额(折扣)",
            dataIndex: "totalAgioMoney",
        },
        {
            title: "应收服务费",
            dataIndex: "totalShouldServiceCharge",
        },
        {
            title: "实收服务费",
            dataIndex: "totalServiceCharge",
        },
        {
            title: "减免服务费",
            dataIndex: "totalDerateServiceCharge",
        },
    ]
    const onFinish = () => {
        let branchStoreId = contextData?.currentBranchStoreId;
        if (!branchStoreId) {
            antMessage("error", '错误', "必须选择分店");
            return false
        }
        form.validateFields()
            .then(async (values: any) => {
                fetchApi.GET(wmService, {
                    apiId: "financeReport",
                    apiExtend: 'roomPlatformStatistics',
                    apiData: {
                        ...values,
                        branchStoreId
                    }
                }).then((res) => {
                    if (res.success) {
                        setUserName(res.data.userName)
                        setRows(res.data.rows)
                    }
                }).catch((err) => {
                    antMessage("error", '错误', err);
                })
            })
            .catch((err) => {

                antMessage('warning', '无法通过数据校验', '请输入所需数据！');
            })


    }
    const changeSb = (e: any, option: any) => {

        if (option?.children) setSbName(option.children)

    }

    const changedRoomType = (e: any, option: any) => {
        let roomTypeName: Array<any> = []
        for (let i = 0; i < option.length; i++) {
            roomTypeName.push(option[i].children)
        }
        setRoomTypeName(roomTypeName.join("|"))
    }

    const changedBranchStoreFloor = (e: any, option: any) => {
        let branchStoreFloorName: Array<any> = []
        for (let i = 0; i < option.length; i++) {
            branchStoreFloorName.push(option[i].children)
        }
        setBranchStoreFloorName(branchStoreFloorName.join("|"))
    }

    return (
        <>
            <BranchStoreList form={form} setRoomData={setRoomData} setBranchStoreFloor={setBranchStoreFloor} setSbData={setSbData} setRoomTypeData={setRoomTypeData} ></BranchStoreList>
            <Form
                form={form}
                onFinish={onFinish}
            >
                <Row>

                    <Col span={6}>
                        <Form.Item
                            label="市别"
                            name="sbId"
                        >
                            <Select
                                onChange={changeSb}
                                allowClear
                                placeholder="市别"
                                optionFilterProp="children"
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(sbData, 'sbName')}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col span={10}>
                        <Form.Item
                            label="分店楼层"
                            name="branchStoreFloorIds"
                        >
                            <Select
                                // orderTypeData
                                mode="multiple"
                                showSearch
                                allowClear
                                placeholder="请选择房型!"
                                optionFilterProp="children"
                                onChange={changedBranchStoreFloor}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(branchStoreFloor, 'floor')}
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={10}>
                        <Form.Item
                            label="房型"
                            name="roomTypeIds"
                        >
                            <Select
                                // orderTypeData
                                mode="multiple"
                                showSearch
                                allowClear
                                placeholder="请选择房型!"
                                optionFilterProp="children"
                                onChange={changedRoomType}
                                onSearch={(e) => getList.search('roomType', setRoomTypeData, e, { andWhereObj: { branchStoreId: localStorage.getItem("currentBranchStoreId") } })}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(roomTypeData, 'roomType')}
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col span={16}>
                        <Form.Item
                            label="房间"
                            name="roomIds"
                        >
                            <Select
                                // orderTypeData
                                mode="multiple"
                                allowClear
                                showSearch
                                placeholder="请选择房间!"
                                optionFilterProp="children"
                                //   onChange={changedRoomType}
                                onSearch={(e) => getList.search('room', setRoomData, e, { andWhereObj: { branchStoreId: localStorage.getItem("currentBranchStoreId") } })}
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                            >
                                {getList.general(roomData, 'roomNum')}
                            </Select>
                        </Form.Item>
                    </Col>


                    <Col span={6}>
                        <Form.Item
                            label="日期"
                            name="chooseTime"
                        >
                            <RangePicker
                                format="YYYY-MM-DD"
                            />
                        </Form.Item>
                    </Col>
                    
                    <Col span={6}>
                        <Form.Item

                            label="时间"
                            name="time"
                        >
                            <RangePicker
                                picker={"time"}
                                format="H"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={4}>
                        <Form.Item >
                            <Space>
                                <Button type="primary" htmlType="submit">
                                    统计
                                </Button >
                                <Button onClick={() => DownloadExcel(rows, "roomPlatformStatistics", { date: form.getFieldValue("chooseTime"), sbName, userName, roomTypeName })}>下载EXCEL文件</Button>

                                {/* purContrast */}
                            </Space>
                        </Form.Item>
                    </Col>
                </Row>

            </Form>

            <div style={{ marginTop: "20px" }}>
                <Table
                    pagination={false}
                    rowKey={(record: any, index: any) => {
                        return index
                    }}
                    columns={columnsHead}
                    dataSource={rows}

                >
                </Table>

            </div>
        </>
    )
}
