import React, { useEffect, useRef, useState } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import MenuSaleTimeEdit from './MenuSaleTimeEdit';
import { Button, Space, Switch } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';

export default function MenuSaleTime(props: any) {

    const ref = useRef<any>();

    const { closeModal, data } = props;

    /** api服务id */
    const apiId = 'menuSaleTime';

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const initData = async () => {
        const menuSalePeriodId = data.id;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                menuSalePeriodId: menuSalePeriodId
            }
        }
        ref.current.initData(currentData);
    }

    const handleDelete = (record: any) => {
        fetchApi.DELETE(wmService, {
            apiId,
            apiExtend: "remove",
            apiVariable: record.id,
            apiData: {
                branchStoreId: localStorage.getItem("currentBranchStoreId")
            }
        }).then(async res => {
            if (res.success) {
                antMessage('success', '删除成功', 'success');
                await initData();
                if (res.data.state) {
                    closeModal(true);
                }
            }
        })
    };

    //表头
    const columnsHead = [
        {
            title: '周期',
            dataIndex: 'weekName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.menuSalePeriod.weekName}</div>
                )
            }
        },
        {
            title: '开始时间',
            dataIndex: 'startTime',
            render: (val: any) =>
                <span>
                    {val.substring(0, 5)}
                </span>,
        },
        {
            title: '结束时间',
            dataIndex: 'endTime',
            render: (val: any) =>
                <span>
                    {val.substring(0, 5)}
                </span>,
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    //传关参数
    let sendData = {
        type: 'EDIT',
        menuSaleTimeId: '',
        path: 'MenuSaleTime',
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '销售时段',
        apiId,
        columns,
        showStates: true,
        showQuery: false,
        pagination: true,
        // showDelete: false,
        showAdd: true,
        EditComponent: MenuSaleTimeEdit,
        sendPageData: {
            menuSalePeriodId: data.id,
            menuId: data.menuId
        },
        coverHandleDelete: handleDelete
    };

    return (
        <>
            <AntDraggableModal
                title={'销售时段'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}            
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={() => closeModal(true)}
                closable={false}
                maskClosable={false}
                width={1200}>
                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
            </AntDraggableModal>
        </>
    )
}