import WebUniversal, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx'
import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx from '../../system/WebUniversalNewEx';
import { useContext, useRef } from 'react';
import NoPrintConfigurationEdit from './NoPrintConfigurationEdit';
import { SocketContext } from '../../../App';
import BranchStoreList from '../../generalComponents/BranchStoreList';
//组件 
const NoPrintConfiguration = (props: any) => {

    /** api服务id */
    const apiId = 'noPrintConfiguration';
    const ref = useRef<any>();
    const useContextData = useContext(SocketContext);
    const { contextData } = useContextData;

    //表头
    const columnsHead = [
        {
            title: '打印单类型',
            dataIndex: 'printTypeName',
        },
        {
            title: '分类',
            dataIndex: 'shopType',
            render:(text:any)=>(text?text.shopTypeName:"")
        },
        {
            title: '门店菜',
            dataIndex: 'branchStoreMenuWarehouse',
            render:(text:any)=>(text?text.foodName:"")
        },


    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '打印配置',
        apiId,
        columns,
        showStates: true,
        pagination: true,
        EditComponent: NoPrintConfigurationEdit,
        sendPageData: { branchStoreId: contextData?.currentBranchStoreId }
    };
    const onRefresh = (item: any) => {

        const { branchStoreId } = item
        const currentData: initDataType = {
            apiId,
            sendPageData: { branchStoreId }
        }
        ref.current.initData(currentData)
    }
    return (
        <>
            <div style={{ width: "30%" }}>
                <BranchStoreList onRefresh={onRefresh}  ></BranchStoreList>
            </div>
            <WebUniversalNewEx ref={ref} props={extendProps}></WebUniversalNewEx>
        </>
    )
}

export default NoPrintConfiguration;