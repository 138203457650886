import React, { useEffect, useRef, useState } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import MenuWarehouseArticleEdit from './MenuWarehouseArticleEdit';
import MenuWarehouseArticleUploadFile from './MenuWarehouseArticleUploadFile';
import { Switch, Tooltip } from 'antd';

export default function MenuWarehouseArticle(props: any) {

    const ref = useRef<any>();

    /** api服务id */
    const apiId = 'menuWarehouseArticle';

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {

        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '大分类',
            dataIndex: 'menuWarehouseArticleCategory',
            render: (text: any, record: any) => {
                return (
                    <div>{record.menuWarehouseArticleType.menuWarehouseArticleCategory.menuWarehouseArticleCategoryName}</div>
                )
            }
        },
        {
            title: '小分类',
            dataIndex: 'menuWarehouseArticleType',
            render: (text: any, record: any) => {
                return (
                    <div>{text.menuWarehouseArticleTypeName}</div>
                )
            }
        },
        {
            title: '文章标题',
            dataIndex: 'articleTitle',
        },
        {
            title: '查看方式',
            dataIndex: 'checkArticleTypeId',
            render: (text: any, record: any) => {
                if (text === 'link') {
                    return (
                        <Tooltip placement="topLeft" title={record.articleLink} color='blue'>
                            <div>{record.checkArticleTypeName}</div>
                        </Tooltip>
                    )
                }
                if (text === 'tencentMovie') {
                    return (
                        <Tooltip placement="topLeft" title={record.movieId} color='blue'>
                            <div>{record.checkArticleTypeName}</div>
                        </Tooltip>
                    )
                }
            }
        },
        {
            title: '推广',
            dataIndex: 'isPromo',
            render: (text: any, record: any) => {
                return (
                    <Switch checkedChildren="是" unCheckedChildren="否"
                        checked={text} key={record.id}
                    />
                )
            },
            filters: [
                {
                    text: '是',
                    value: true,
                },
                {
                    text: '否',
                    value: false,
                },
            ],
            onFilter: (value: any, record: any) => record.isPromo === value,
            filterSearch: false,
        },
        {
            title: '推广排序',
            dataIndex: 'promoSortBy',
            sorter: (a: any, b: any) => a.promoSortBy - b.promoSortBy,
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '文章',
        apiId,
        columns,
        showImage: true,
        showUploadFile: true,
        showStates: true,
        pagination: true,
        arrayFilesName: 'menuWarehouseArticleFiles',
        EditComponent: MenuWarehouseArticleEdit,
        UploadFileComponent: MenuWarehouseArticleUploadFile
    };

    return (
        <>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
        </>
    )
}