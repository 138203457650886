import React, { useEffect, useRef } from 'react';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import MenuMovieEdit from './MenuMovieEdit';

export default function MenuMovie(props: any) {

    const ref = useRef<any>();

    const { closeModal, data } = props;

    /** api服务id */
    const apiId = 'menuMovie';

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    //表头
    const columnsHead = [
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) =>
                <div>{record.menuWarehouse.foodName}</div>
        },
        {
            title: '视频ID',
            dataIndex: 'movieId',
        },
        {
            title: '备注',
            dataIndex: 'memo',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式视频',
        apiId,
        columns,
        showStates: true,
        showDefault: true,
        pagination: true,
        EditComponent: MenuMovieEdit,
        extendIdName: "menuWarehouseId",
        sendPageData: { menuWarehouseId: data.id }
    };

    return (
        <AntDraggableModal
            title={'菜式视频'}
            open={true}
            okText='关闭'
            // cancelText={stringHandle.isUndefined()}            
            cancelButtonProps={{ style: { display: "none" } }}
            onOk={() => closeModal(true)}
            closable={false}
            maskClosable={false}
            width={900}>
            <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
        </AntDraggableModal>
    )
}