import { Col, Form, Input, InputNumber, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react'
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import fetchApi from '../../../utils/api/fetchApi';
import getList from '../../universal/getList';
import system from "../../../config/systematic";

//布局
const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
};

export default function MenuMemoEdit(props: any) {

    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();

    /** 适用范围数据*/
    const [memoCondimentData, setMemoCondimentData] = useState<any>();
    const [shopTypeData, setShopTypeData] = useState<any>();
    const [branchStoreMenuWarehouseData, setBranchStoreMenuWarehouseData] = useState<any>();
    const [branchStoreMenuWarehouseToMenuData, setBranchStoreMenuWarehouseToMenuData] = useState<any>([]);

    const [memoCondiment, setMemoCondiment] = useState<any>(0);

    // const [branchStoreData, setBranchStoreData] = useState<any>();

    /** 后端数据wmService */
    let wmService = system.wmService;

    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
            form.setFieldsValue({ branchStoreId: localStorage.getItem("currentBranchStoreId") });

            ref.current.row.then(async (res: any) => {
                if (typeof res === 'undefined') { return }
                if (res.success === true) {

                    getBranchStoreMenuWarehouseToMenuData(res.data.branchStoreMenuWarehouseId);

                    let newData: Array<any> = [];
                    if (res.data.branchStoreMenuWarehouse) {
                        if (data.type === "EDIT") {
                            newData.push({
                                id: res.data.branchStoreMenuWarehouseId,
                                foodName: res.data.branchStoreMenuWarehouse.foodName,
                            })
                        }
                    }
                    setBranchStoreMenuWarehouseData(newData);
                }
            })
        }
        
        const fetchData = async () => {

            // fetchApi.GET(wmService, {
            //     apiId: "branchStore",
            //     apiExtend: 'showList'
            // }).then(res => {
            //     if (res.success) {
            //         setBranchStoreData(res.data)
            //     }
            // });

            // fetchApi.GET(wmService, {
            //     apiId: "menuHead",
            //     apiExtend: 'getBranchStoreId'
            // }).then(res => {
            //     if (res.success) {
            //         if (res.data.key !== 'manager') {
            //             form.setFieldsValue({ branchStoreId: res.data.branchStore.id });
            //         }
            //     }
            // });

            fetchApi.GET(wmService, {
                apiId: "shopType",
                apiExtend: 'showList',
                apiData: {
                    branchStoreId: localStorage.getItem("currentBranchStoreId")
                }
            }).then(res => {
                if (res.success) {
                    setShopTypeData(res.data)
                }
            });

            // fetchApi.GET(wmService, {
            //     apiId: "branchStoreMenuWarehouse",
            //     apiExtend: 'showList',
            // }).then(res => {
            //     if (res.success) {
            //         setBranchStoreMenuWarehouseData(res.data)
            //     }
            // });

            /** 查找generalData来获取适用范围数据 */
            fetchApi.GET(wmService, {
                apiId: 'generalData',
                apiVariable: 'memoCondiment',
                apiExtend: 'showList'
            }).then(res => {
                // console.log(res.data);
                setMemoCondimentData(res.data);
            });
        }
        fetchData()
    }, [])

    const changeMemoCondiment = (e: any) => {
        setMemoCondiment(e);
    };

    const getBranchStoreMenuWarehouseToMenuData = (branchStoreMenuWarehouseId: string) => {
        fetchApi.GET(wmService, {
            apiId: 'menu',
            apiExtend: 'branchStoreMenuWarehouseToMenu',
            apiData: {
                branchStoreMenuWarehouseId,
                branchStoreId: localStorage.getItem("currentBranchStoreId")
            }
        }).then(res => {
            if (res.success) {
                setBranchStoreMenuWarehouseToMenuData(res.data);
            }
        });
    }

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'menuMemo'
    };

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '菜式备注'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
            >
                <Form
                    {...layout}
                    form={form}
                    initialValues={{ "sortBy": 999 }}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            handleOk(e)
                        }
                    }}
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={0}>
                            <Form.Item
                                label="门店Id"
                                name="branchStoreId"
                            >
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="适用范围"
                                name={"memoCondimentId"}
                                rules={[{
                                    required: true,
                                    message: '请选择适用范围!'
                                }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="适用范围"
                                    disabled={data.type === "EDIT" ? true : false}
                                    optionFilterProp="children"
                                    onChange={changeMemoCondiment}
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {/* {getList.general(memoCondimentData, "name")} */}
                                    {getList.generalEx(memoCondimentData, {
                                        itemName: "name",
                                        includeNull: false,
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="分类"
                                name="shopTypeId"
                                rules={[{
                                    required: memoCondiment === 1 ? true : false,
                                    message: '请选择分类!'
                                }]}>
                                <Select
                                    showSearch
                                    placeholder="分类"
                                    disabled={memoCondiment === 1 ? false : true}
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.generalEx(shopTypeData, {
                                        itemName: "shopTypeName",
                                        includeNull: false,
                                    })}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="菜式"
                                name={"branchStoreMenuWarehouseId"}
                                rules={[{
                                    required: memoCondiment === 2 ? true : false,
                                    message: '请选择菜式!'
                                }]}
                            >
                                <Select
                                    showSearch
                                    placeholder="菜式"
                                    disabled={memoCondiment === 2 ? false : true}
                                    onSearch={(e) => getList.searchEx('branchStoreMenuWarehouse', setBranchStoreMenuWarehouseData, e, branchStoreMenuWarehouseData, {
                                        apiExtend: 'showMenuList',
                                        andWhereObj: { branchStoreId: localStorage.getItem("currentBranchStoreId") }
                                    })}
                                    onSelect={getBranchStoreMenuWarehouseToMenuData}
                                    optionFilterProp="children"
                                    // filterOption={(input, option: any) =>
                                    //     option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                                    filterOption={(input, option: any) => {
                                        return true
                                    }}
                                >
                                    {/* {getList.general(branchStoreMenuWarehouseData, 'foodName')} */}
                                    {getList.generalEx(branchStoreMenuWarehouseData, {
                                        itemName: "foodName",
                                        includeNull: false,
                                    })}
                                    {/* {getList.generalOption(menuWarehouseData, undefined, "id", false, "foodName")} */}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item label="以下菜式统一备注" >
                                {
                                    branchStoreMenuWarehouseToMenuData.map((item: any, index: any) => {
                                        if (branchStoreMenuWarehouseToMenuData.length > 0) {
                                            return (
                                                <>
                                                    <Form.Item>
                                                        <Input disabled style={{ color: "yellow" }} value={'【' + item.menuHead.menuHeadName + '】   ' + item.foodName} />
                                                    </Form.Item>
                                                </>
                                            )
                                        }
                                    })
                                }
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="备注"
                                name="menuMemoName"
                                rules={[{ required: true, message: '请输入备注!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="排序"
                                name="sortBy">
                                <InputNumber min={1} />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                <Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}