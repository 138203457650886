import React, { useEffect, useRef, useState,useContext } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import { Col, Form, QRCode, Row, Select, Switch, Tooltip } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import antMessage from '../../../utils/extend/AntdNotification';
import systemConfig from '../../../config/systematic';
import QrCodeEdit from './QrCodeEdit';
import getList from '../../universal/getList';
import BranchStoreList from '../../generalComponents/BranchStoreList';
import { SocketContext } from '../../../App';

export default function QrCode(props: any) {

  const ref = useRef<any>();
  const [form] = Form.useForm();
  /** api服务id */
  const apiId = 'qrCode';
  const [qrCodeTypeData, setQrCodeTypeData] = useState<any>();
  const [selectQrCodeType, setSelectQrCodeType] = useState<any>();
  /** 后端数据wmService */
  let wmService: any = systemConfig.wmService;
  const useContextData = useContext(SocketContext);
  const { contextData } = useContextData;
  
  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }

    //同步获取数据 async - await
    const fetchData = async () => {
      fetchApi.GET(wmService,
        {
          apiId: 'qrCodeType',
          apiExtend: 'showList'
        }).then(res => {
          if (res.success) {
            setQrCodeTypeData(res.data)

          }
        })
    }
    fetchData();
    //禁用规则
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); //初始化数据




  //表头
  const columnsHead = [
    {
      title: '码类型',
      dataIndex: 'qrCodeType',
      render: (text: any, record: any) =>
        <div>{text.qrCodeTypeName}</div>
    },
    {
      title: '扩展名',
      dataIndex: 'qrExtend',

    },
    {
      title: '扩展变量',
      dataIndex: 'qrVariable',

    },
    {
      title: '加密',
      dataIndex: 'qrEncrypt',
      render: (text: any, record: any) => (
        <Switch checkedChildren="启用" unCheckedChildren="关闭"
          checked={text} key={record.id}
          onChange={(e) => ref.current.handleChangeOne(record.id, 'qrEncrypt', e, apiId)}
        // onChange={(e) => handleChangeOne(record.id, 'isUse', e, apiName)

        // }
        />
      )
      // render: (text: any, record: any) =>
      //     <div>{record.voiceId}</div>
    },
    {
      title: '扩展数据',
      dataIndex: 'qrData',
      render: (text: any) => <Tooltip placement="topLeft" title={text}>{
        (typeof text !== "undefined" && text !== null) ?
          (text.length >= 10 ? text.substring(0, 10) + '....' : text)
          : ''
      }</Tooltip>
    },
    {
      title: '二维码',
      dataIndex: 'qrData',
      render: (text: any,record:any) => <QRCode size={100}  value={`${record.qrCodeType.qrCodeUrl}qrCodeId=${record.id}`}></QRCode>
    },
  ];

  const columns = columnsheadId
    .concat(columnsHead as []);

  let extendProps: WUExtendPropsType = {
    ...props,
    titleName: '二维码',
    apiId,
    columns,
    // showImage: true,
    // showUploadFile: true,
    showStates: true,
    pagination: true,
    EditComponent: QrCodeEdit
  };

  const qrCodeTypeChange = (e: any) => {
    setSelectQrCodeType(e)
    
    const currentData: initDataType = {
      apiId,
      sendPageData: { qrCodeType: e,branchStoreId:contextData?contextData.currentBranchStoreId:null }
    }
    ref.current.initData(currentData);
  }
  const onRefresh = (item: any) => {
        
    const {branchStoreId}=item
    const currentData: initDataType = {
        apiId,
        sendPageData: { qrCodeType:selectQrCodeType,branchStoreId }
    }

    ref.current.initData(currentData)
}
  return (
    <>
      <div  style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ width: "70%" }}>
          <Form form={form} >
            <Row>
              <Col span={5}>
                <Form.Item
                  name="qrCodeTypeId"
                >
                  <Select
                    showSearch
                    allowClear
                    mode="multiple"
                    onChange={qrCodeTypeChange}
                    placeholder="类型"
                    optionFilterProp="children"
                    filterOption={(input, option: any) =>
                      option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {getList.general(qrCodeTypeData, "qrCodeTypeName")}
                  </Select>
                </Form.Item>
              </Col>

            </Row>
          </Form>
        </div>
        <div style={{ width: "30%" }} >
          <BranchStoreList form={form} onRefresh={onRefresh}  ></BranchStoreList>
        </div>
      </div>
      <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>
    </>
  )
}