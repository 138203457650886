import { Col, Form, Input, Row, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';
const { TextArea } = Input;

//布局
// const layout = {
//     labelCol: { span: 8 },
//     wrapperCol: { span: 16 },
// };

const SmsCustomEdit = (props: any) => {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    /** 后端数据wmService */
    let wmService: any = "";
    const [branchStoreData, setbranchStoreData] = useState<any>();
    const [smsCustomTypeData, setSmsCustomTypeData] = useState<any>();
    const [roomRules, setRoomRules] = useState<any>();
    const ref = useRef<any>();
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const handleCancel = (e: any) => {
        ref.current.handleCancel();
    };

    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'smsCustom',
    };
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            httpGet(wmService, {
                apiId: "branchStore",
                apiExtend: 'showList'
            }).then(res => {
                if (res.success) {

                    setbranchStoreData(res.data)
                }
            })

            httpGet(wmService,
                {
                    apiId: 'generalData',
                    apiVariable: 'smsCustomType@roomRules',
                    apiExtend: 'showLists'
                }).then(res => {
                    console.log("res", res);

                    if (res.success) {
                        const { roomRules, smsCustomType } = res.data
                        setSmsCustomTypeData(smsCustomType);
                        setRoomRules(roomRules)
                    }
                })
        };
        fetchData();
    }, []);
    return (
        <><WebEditUniversal ref={ref} props={extendProps} />
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '短信定制'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={800}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    onKeyDown={(e: any) => {
                        if (e.key === 'Enter') {
                            // handleOk(e)
                        }
                    }}>
                    <Row >
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled={true} />
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="定制名称"
                                name="customName"
                                rules={[{ required: true, message: '请输入定制名称!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>



                        <Col span={12}>
                            <Form.Item
                                label="分店"
                                name="branchStoreId">
                                <Select
                                    disabled={true}
                                    showSearch
                                    placeholder="选择分店"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(branchStoreData, 'branchStoreName')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="短信定制类"
                                name="smsCustomTypeId"
                                rules={[{ message: '请选择短信定制类!', required: true }]}>
                                <Select
                                    showSearch
                                    placeholder="选择短信定制类"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(smsCustomTypeData, 'name')}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={11}>
                            <Form.Item
                                label="房号显示规则"
                                name="roomRuleId"
                                rules={[{ message: '请选择显示规则!', required: false }]}>
                                <Select
                                    showSearch
                                    placeholder="请选择显示规则"
                                    optionFilterProp="children"
                                    filterOption={(input, option: any) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                                    {getList.general(roomRules)}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={5}>
                            <Form.Item
                                label="合并楼层"
                                name="isMergeFloor"
                                valuePropName="checked"
                                tooltip='@roomName前面显示楼层'
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="默认"
                                name="isDefault"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>

                        <Col span={4}>
                            <Form.Item
                                label="状态"
                                name="isUse"
                                valuePropName="checked"
                                initialValue={true}>
                                < Switch checked={true}
                                    checkedChildren="启用"
                                    unCheckedChildren="关闭" />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="发送内容"
                                name="content"
                                rules={[{ message: '请输入发送内容!', required: true }]}
                            >
                                <TextArea
                                    placeholder="发送内容"
                                    autoSize={{ minRows: 2, maxRows: 10 }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            【标识】：[@name]：姓名；[@appellationName]：称呼；[@brandName]：品牌；[@branchStoreName]：店名；
                            [@contactCall]：电话；[@address]：地址；[@parkingLot]：停车场；[@timeTypeName]：预订时间；
                            [@roomTypeName]：房间类型名；[@roomName]：房间名；[@intro]：简介；[@memos]：备注；
                        </Col>

                    </Row>
                </Form>
            </AntDraggableModal>
        </>
    )
}

export default SmsCustomEdit;