import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import MenuMemoEdit from './MenuMemoEdit';

export default function MenuMemo(props: any) {

    //表头
    const columnsHead = [
        {
            title: '门店',
            dataIndex: 'branchStoreName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStore.brand.brandName + " " + record.branchStore.branchStoreName}</div>
                )
            }
        },
        {
            title: '适用范围',
            dataIndex: 'memoCondimentName',
        },
        {
            title: '类别',
            dataIndex: 'shopTypeName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.shopType ? record.shopType.shopTypeName : ""}</div>
                )
            }
        },
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.branchStoreMenuWarehouse ? record.branchStoreMenuWarehouse.foodName : ''}</div>
                )
            }
        },
        {
            title: '备注',
            dataIndex: 'menuMemoName',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];
    
    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '菜式备注',
        apiId: 'menuMemo',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: MenuMemoEdit,
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") }
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}