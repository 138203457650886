import React, { useEffect, useRef, useState } from 'react';
import WebUniversalNewEx, { initDataType, WUExtendPropsType } from '../../system/WebUniversalNewEx';
import { columnsheadId } from '../../universal/columnshead';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import MenuSalePeriodEdit from './MenuSalePeriodEdit';
import MenuSaleTime from './MenuSaleTime';
import { Button, Form, Modal, Select, Space, Switch, Tooltip, Typography } from 'antd';
import fetchApi from '../../../utils/api/fetchApi';
import systemConfig from '../../../config/systematic';
import antMessage from '../../../utils/extend/AntdNotification';
import arrayHandle from "../../../utils/baseLib/arrayHandle";

const { Paragraph } = Typography;

export default function MenuSalePeriod(props: any) {

    const [form] = Form.useForm(); //form 
    const ref = useRef<any>();
    const { closeModal, data } = props;

    //时段数据
    const [menuSaleTimeData, setMenuSaleTimeData] = useState<any>();

    //时段窗口
    const [menuSaleTimeVisible, setMenuSaleTimeVisible] = useState(false);

    //复制窗口
    const [copyVisible, setCopyVisible] = useState(false);
    const [copyData, setCopyData] = useState();

    const [weeksData, setWeeksData] = useState<any>();

    /** api服务id */
    const apiId = 'menuSalePeriod';

    /** 后端数据wmService */
    let wmService: any = systemConfig.wmService;

    useEffect(() => {
        //同步获取数据 async - await
        const fetchData = async () => {
        }
        fetchData();
        //禁用规则

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); //初始化数据

    const getWeeksData = () => {
        fetchApi.GET(wmService, {
            apiId: "generalData",
            apiExtend: 'showList',
            apiVariable: "week",
        }).then(async res => {
            if (res.success) {

                let rows = await arrayHandle.sort(res.data, 'id', 'asc');
                let newSelectData: Array<any> = [];
                for (var j = 0; j < rows.length; j++) {
                    const res = await fetchApi.GET(wmService, {
                        apiId: 'menuSalePeriod',
                        apiExtend: 'showList',
                        apiData: {
                            menuId: data.menuId,
                            shopTypeId: data.shopTypeId,
                        }
                    })

                    let findData = await arrayHandle.findValue(res.data, 'weeks', String(j));

                    newSelectData.push({
                        value: rows[j].id,
                        label: rows[j].name,
                        disabled: findData?.index > -1 ? findData?.data.menuSaleTimes.length === 0 ? false : true : true
                    })
                }

                setWeeksData(newSelectData);
            }
        })
    }

    const initData = async () => {
        // console.log(data.menuId);
        const menuId = data.menuId;
        const shopTypeId = data.shopTypeId;
        const currentData: initDataType = {
            apiId,
            sendPageData: {
                menuId: menuId,
                shopTypeId: shopTypeId
            }
        }
        ref.current.initData(currentData);
    }

    const handleDelete = (record: any) => {
        fetchApi.DELETE(wmService, {
            apiId,
            apiExtend: "remove",
            apiVariable: record.id,
            apiData:{
                branchStoreId: localStorage.getItem("currentBranchStoreId")
            }
        }).then(async res => {
            if (res.success) {
                antMessage('success', '删除成功', 'success');
                await initData();
                if (res.data.state) {
                    closeModal(true);
                }
            }
        })
    };

    //打开时段窗口
    const handleMenuSaleTime = (record: any) => {
        //console.log(record)
        sendData.id = record.id;
        sendData.menuId = record.menuId;
        setMenuSaleTimeData(sendData);
        setMenuSaleTimeVisible(true);
    };

    //关闭时段窗口
    const onCloseMenuSaleTime = async (status: boolean) => {
        setMenuSaleTimeVisible(false);        
        if (status) {
            await initData();
        }
    }

    const openCopyModal = (record: any) => {
        setCopyData(record);
        setCopyVisible(true);
        getWeeksData();
        form.setFieldValue('weeksId', null);
    };

    const closeCopyModal = async () => {
        setCopyVisible(false);
        await initData();
    };

    //复制时段到其他周期
    const handleOk = (e: any) => {
        form.validateFields() //数据校验
            .then(async (values: any) => {
                fetchApi.POST(wmService, {
                    apiId,
                    apiExtend: 'copyMenuSaleTime',
                    apiData: {
                        ...values,
                        branchStoreId: localStorage.getItem("currentBranchStoreId"),
                        copyData
                    }
                }).then(res => {
                    if (res.success) {
                        antMessage('success', '添加成功', 'success');
                        closeCopyModal();
                    }
                }).catch(err => {
                    antMessage('error', '错误，无法保存', err.message + ' | status:' + err.errors.status);
                })
            })
            .catch((err: any) => {
                antMessage('warning', '无法通过数据校验', '请输入所需数据！');
            });
    };

    //一键生成7天数据
    const handleCreateWeek = () => {

        fetchApi.POST(wmService, {
            apiId,
            apiExtend: 'createWeek',
            apiData: {
                menuId: data.menuId,
                shopTypeId: data.shopTypeId
            }
        }).then(async res => {
            if (res.success === true) {
                antMessage("success", '操作成功', "操作成功");
                await initData();
            }
        })
    };

    //一键清除
    const handleClearWeek = (record: any) => {

        fetchApi.POST(wmService, {
            apiId,
            apiExtend: 'clearWeek',
            apiVariable: record.id,
            apiData: {
                menuId: record.menuId,
                shopTypeId: record.shopTypeId,
                branchStoreId: localStorage.getItem("currentBranchStoreId")
            }
        }).then(async res => {
            if (res.success === true) {
                antMessage("success", '操作成功', "操作成功");
                await initData();
            }
        })
    };

    //列出时间段
    const menuSaleTimes = (record: any) => {
        // console.log(record)
        let str: any;
        for (var i = 0; i < record.length; i++) {
            if (str === undefined) {
                str = record[i].startTime + " ~ " + record[i].endTime;
            }
            else {
                str = str + "\n" + record[i].startTime + " ~ " + record[i].endTime
            }

        }
        return str;
    };

    //表头
    const columnsHead = [
        {
            title: '分类',
            dataIndex: 'shopTypeName',
            render: (text: any, record: any) => {
                if (record.shopType) {
                    return (
                        <div>{record.shopType.shopTypeName}</div>
                    )
                }
            }
        },
        {
            title: '菜式',
            dataIndex: 'foodName',
            render: (text: any, record: any) => {
                if (record.menu) {
                    return (
                        <div>{record.menu.foodName}</div>
                    )
                }
            }
        },
        {
            title: '周期',
            dataIndex: 'weekName',
            render: (text: any, record: any) => {
                if (record.menuSaleTimes.length > 0) {
                    return (
                        <Tooltip placement="topLeft" title={
                            <Typography>
                                <Paragraph style={{ whiteSpace: 'pre-wrap' }}>
                                    {menuSaleTimes(record.menuSaleTimes)}
                                </Paragraph>
                            </Typography>
                        } color='red'>
                            <div>{text}</div>
                        </Tooltip>
                    )
                }
                else {
                    return (
                        <div>{text}</div>
                    )
                }
            }
        },
        {
            title: '时段',
            dataIndex: '',
            key: 'menuSaleTime',
            render: (text: any, record: any) => {
                if (record.menuSaleTimes.length > 0) {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" onClick={() => handleMenuSaleTime(record)}>时段</Button>
                            <Tooltip placement="topLeft" title="复制时段到其他周期" color='green'>
                                <div><Button size={"small"} type="primary" onClick={() => openCopyModal(record)}>复制</Button></div>
                            </Tooltip>
                            <Tooltip placement="topLeft" title="一键清除所有时段" color='red'>
                                <div><Button size={"small"} type="primary" onClick={() => handleClearWeek(record)}>清除时段</Button></div>
                            </Tooltip>
                        </Space>
                    )
                }
                else {
                    return (
                        <Space size="small">
                            <Button size={"small"} type="primary" danger onClick={() => handleMenuSaleTime(record)}>时段</Button>
                        </Space>
                    )
                }
            }
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    //传关参数
    let sendData = {
        type: 'EDIT',
        id: '',
        menuId: ''
    }

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '销售周期',
        apiId,
        columns,
        showStates: true,
        showQuery: false,
        pagination: true,
        // showDelete: false,
        showAdd: true,
        EditComponent: MenuSalePeriodEdit,
        sendPageData: {
            menuId: data.menuId,
            shopTypeId: data.shopTypeId,
            foodName: data.foodName
        },
        coverHandleDelete: handleDelete
    };

    return (
        <>
            <AntDraggableModal
                title={'销售周期'}
                open={true}
                okText='关闭'
                // cancelText={stringHandle.isUndefined()}            
                cancelButtonProps={{ style: { display: "none" } }}
                onOk={() => closeModal(true)}
                closable={false}
                maskClosable={false}
                width={1200}>

                <Button type="primary" onClick={handleCreateWeek}>一键生成7天数据</Button>
                <span>&nbsp;&nbsp;</span>
                <Modal
                    title="选择需要复制到指定的周期里"
                    open={copyVisible}
                    onOk={handleOk}
                    onCancel={closeCopyModal}
                    width={800}
                >
                    <Form
                        form={form}
                        name="Edit"
                        style={{ height: 450 }}
                        onFinish={handleOk}
                    >
                        <Form.Item
                            label="选择周期"
                            name="weeksId"
                            rules={[{ required: copyVisible ? true : false, message: '选择周期!' }]}>
                            <Select
                                mode="multiple"
                                showSearch
                                placeholder="选择周期"
                                optionFilterProp="children"
                                filterOption={(input, option: any) => {
                                    return true
                                }}
                                options={weeksData}
                            >
                            </Select>
                        </Form.Item>
                    </Form>
                </Modal>

                <WebUniversalNewEx props={extendProps} ref={ref} ></WebUniversalNewEx>

                {menuSaleTimeVisible
                    ? (<MenuSaleTime
                        closeModal={onCloseMenuSaleTime} data={menuSaleTimeData} />)
                    : null}

            </AntDraggableModal>
        </>
    )
}