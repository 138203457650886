import { columnsheadId } from '../../universal/columnshead';
import WebUniversalNewEx, { WUExtendPropsType } from '../../system/WebUniversalNewEx';
import DeptPrintDetailEdit from './DeptPrintDetailEdit';

export default function DeptPrintDetail(props: any) {

    //表头
    const columnsHead = [
        // {
        //     title: '门店',
        //     dataIndex: 'branchStoreName',
        //     render: (text: any, record: any) => {
        //         return (
        //             <div>{record.shopCategory.branchStore.brand.brandName + " " + record.shopCategory.branchStore.branchStoreName}</div>
        //         )
        //     }
        // },
        {
            title: '部门',
            dataIndex: 'deptName',
            render: (text: any, record: any) => {
                return (
                    <div>{record.dept.deptName}</div>
                )
            }
        },
        {
            title: '打印内容',
            dataIndex: 'detailName',
        },
        {
            title: '排序',
            dataIndex: 'sortBy',
        },
    ];

    const columns = columnsheadId
        .concat(columnsHead as []);

    let extendProps: WUExtendPropsType = {
        ...props,
        titleName: '部门打印内容',
        apiId: 'deptPrintDetail',
        showStates: true,
        columns,
        pagination: true,
        EditComponent: DeptPrintDetailEdit,
        sendPageData: { branchStoreId: localStorage.getItem("currentBranchStoreId") },
    };

    return (
        <WebUniversalNewEx props={extendProps}></WebUniversalNewEx>
    )
}