import { Form, Input, Select, Switch } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal'
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx'
import getList from '../../universal/getList';

//布局
const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const SmsConfigEdit = (props: any) => {
  const [form] = Form.useForm(); //form 
  const { data } = props;
  /** 后端数据wmService */
  let wmService: any = "";
  const [branchStoreData, setbranchStoreData] = useState<any>();
  const [smsServiceData, setSmsServiceData] = useState<any>();
  const [roomRules, setRoomRules] = useState<any>();
  const ref = useRef<any>();
  const handleOk = (e: any) => {
    ref.current.handleOk();
  };
  const handleCancel = (e: any) => {
    ref.current.handleCancel();
  };
  useEffect(() => {
    if (ref.current) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      wmService = ref.current.wmService;
    }
    const fetchData = async () => {

      httpGet(wmService, {
        apiId: "branchStore",
        apiExtend: 'showList'
      }).then(res => {
        if (res.success) {

          setbranchStoreData(res.data)
        }
      })

      httpGet(wmService, {
        apiId: "smsService",
        apiExtend: 'showList'
      }).then(res => {
        if (res.success) {

          setSmsServiceData(res.data)
        }
      })
      httpGet(wmService,
        {
          apiId: 'generalData',
          apiVariable: 'roomRules',
          apiExtend: 'showList'
        }).then(res => {
          if (res.success) {
 
            setRoomRules(res.data)
          }
        })


    };
    fetchData();
  }, []);
  let extendProps: WEUExtendPropsType = {
    ...props,
    form,
    apiId: 'smsConfig',
  };

  return (
    <><WebEditUniversal ref={ref} props={extendProps} />
      <AntDraggableModal
        title={(data.type === 'NEW' ? '新增' : '编辑') + '短信配置'}
        open={true}
        okText='确定'
        cancelText='取消'
        onOk={handleOk}
        onCancel={handleCancel}
        closable={false}
        maskClosable={false}
      >
        <Form
          {...layout}
          form={form}
          name="Edit"
          onFinish={handleOk}
          onKeyDown={(e: any) => {
            if (e.key === 'Enter') {
              handleOk(e)
            }
          }}>

          <Form.Item
            label="id"
            name="id">
            <Input disabled={true} />
          </Form.Item>


          <Form.Item
            label="配置名称"
            name="configName"
            rules={[{ required: true, message: '请输入配置名称!' }]}>
            <Input />
          </Form.Item>

          <Form.Item
            label="短信服务"
            name="smsServiceId"
            rules={[{ message: '请选择短信服务!', required: true }]}>
            <Select
              showSearch
              placeholder="选择短信服务"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(smsServiceData, 'serviceName')}
            </Select>
          </Form.Item>



          {/* <Col span={12}> */}
          <Form.Item
            label="分店"
            name="branchStoreId">
            <Select
              disabled={true}
              showSearch
              placeholder="选择分店"
              optionFilterProp="children"
              filterOption={(input, option: any) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
              {getList.general(branchStoreData, 'branchStoreName')}
            </Select>
          </Form.Item>
          {/* </Col> */}
          <Form.Item
            label="默认"
            name="isDefault"
            valuePropName="checked"
            initialValue={true}>
            < Switch checked={true}
              checkedChildren="启用"
              unCheckedChildren="关闭" />
          </Form.Item>

          <Form.Item
            label="状态"
            name="isUse"
            valuePropName="checked"
            initialValue={true}>
            < Switch checked={true}
              checkedChildren="启用"
              unCheckedChildren="关闭" />
          </Form.Item>

       


        </Form>
      </AntDraggableModal>
    </>
  )
}

export default SmsConfigEdit;