import { Col, Form, Input, Radio, RadioChangeEvent, Row, Select, Switch } from 'antd';
import { useEffect, useRef, useState, } from 'react'
import { httpGet } from '../../../utils/api/fetchApi';
import AntDraggableModal from '../../../utils/extend/AntDraggableModal';
import WebEditUniversal, { WEUExtendPropsType } from '../../system/WebEditUniversalNewEx';
import getList from '../../universal/getList';


const { TextArea } = Input;

export default function EmployeesEdit(props: any) {
    const [form] = Form.useForm(); //form 
    const { data } = props;
    const ref = useRef<any>();
    /**部门数据 */
    const [deptData, setDeptData] = useState<any>()
    const handleOk = (e: any) => {
        ref.current.handleOk();
    };
    const [value, setValue] = useState<number>(1);
    const handleCancel = (e: any) => {


        ref.current.handleCancel();
    };
    /** 后端数据wmService */
    let wmService: any = "";
    let extendProps: WEUExtendPropsType = {
        ...props,
        form,
        apiId: 'invitationTemplate'
    };
    const onChange = (e: RadioChangeEvent) => {
        setValue(e.target.value);
    };
    useEffect(() => {
        if (ref.current) {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            wmService = ref.current.wmService;
        }
        const fetchData = async () => {

            httpGet(wmService, {
                apiId: "dept",
                apiExtend: 'showList',
                apiData: {

                }
            }).then(res => {

                setDeptData(res.data)
            })

        }
        fetchData()
    }, [])

    return (
        <>
            <WebEditUniversal ref={ref} props={extendProps}></WebEditUniversal>
            <AntDraggableModal
                title={(data.type === 'NEW' ? '新增' : '编辑') + '邀请函模板'}
                open={true}
                okText='确定'
                cancelText='取消'
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                maskClosable={false}
                width={"60%"}
            >
                <Form
                    form={form}
                    name="Edit"
                    onFinish={handleOk}
                    //onKeyDown={(e: any) => {
                    //     if (e.key === 'Enter') {
                    //         handleOk(e)
                    //     }
                    // }}
                >
                    <Row >
                        <Col span={12}>
                            <Form.Item
                                label="id"
                                name="id">
                                <Input disabled/>
                            </Form.Item>

                        </Col>

                        <Col span={12}>
                            <Form.Item
                                label="模板名称"
                                name="invitationTemplateName"
                                rules={[{ required: true, message: '请输入姓名!' }]}>
                                <Input />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="模板内容"
                                name="content">
                                <TextArea
                                    placeholder="模板内容"
                                    autoSize={{ minRows: 10, maxRows: 14 }}
                                />
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                label="模板结构"
                                name="structureContent">
                                <TextArea
                                    placeholder="模板结构"
                                    autoSize={{ minRows: 10, maxRows: 14 }}
                                />
                            </Form.Item>
                        </Col>
{/* 
                        <Col span={24}>
                            <Form.Item
                                label="固定数据"
                                name="fixedData">
                                <TextArea
                                    placeholder="固定数据"
                                    autoSize={{ minRows: 10, maxRows: 14 }}
                                />
                            </Form.Item>
                        </Col> */}

                        <Col span={8}>
                            <Form.Item
                                label="默认菜式推广"
                                name="isPromoMenu"
                                valuePropName="checked"
                                initialValue={false}>
                                < Switch checked={false}
                                    checkedChildren="是"
                                    unCheckedChildren="否" />
                            </Form.Item>
                        </Col>


                    </Row>
                </Form>
            </AntDraggableModal>

        </>
    )
}


